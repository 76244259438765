import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthActionType } from './auth.model';
import { AuthState, AuthStore } from './auth.store';
import { User } from '@therappy-inc/models';
import { AuthError } from 'firebase/auth';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  
  constructor(protected override store: AuthStore) {
    super(store);
  }

  selectIsSignedIn(): Observable<boolean> {
    return this.selectCurrentUser().pipe(map(p => !!p));
  }

  selectCurrentUser(): Observable<User> {
    return this.select('profile');
  }

  selectAuthError(): Observable<AuthError> {
    return this.selectError<AuthError>();
  }

  selectAuthAction(): Observable<AuthActionType> {
    return this.store.authAction$.asObservable();
  }

  selectSignin(): Observable<void> {
    return this.selectAuthAction().pipe(
      filter(type => type === AuthActionType.Signin),
      map(() => {}),
    );
  }

  selectSignout(): Observable<void> {
    return this.selectAuthAction().pipe(
      filter(type => type === AuthActionType.Signout),
      map(() => {}),
    );
  }

  selectSignup(): Observable<void> {
    return this.selectAuthAction().pipe(
      filter(type => type === AuthActionType.Signup),
      map(() => {}),
    );
  }

  getIsSignedIn(): boolean {
    const { profile } = this.getValue();
    return !!profile;
  }

  getCurrentUser(): User | undefined {
    return this.getValue().profile;
  }
}
