<div class="relative flex flex-col gap-[24px] max-w-[630px] p-6 md:p-8">
  <ng-container *ngIf="(isSubmitted$ | async) === false; else successMessage">
    <ng-container *ngIf="userType === 'user'; else therapistTitle">
      <app-typography variant="p" class="text-grayscale-900">
        {{ 'LAUNCHLIST.TITLE_HOME' | translate }}
      </app-typography>
    </ng-container>

    <ng-template #therapistTitle>
      <app-typography variant="p" class="text-grayscale-900">
        {{ 'LAUNCHLIST.TITLE_THERAPIST' | translate }}
      </app-typography>
    </ng-template>
    <form [formGroup]="form" class="launchlist-form flex flex-col sm:flex-row items-stretch gap-2" [attr.action]="formAction" method="POST" (ngSubmit)="onSubmit()"  >
      <input name="user_type" type="hidden" [value]="userType" />
      <input name="location" type="hidden" />
      <input
        type="email"
        formControlName="email"
        [placeholder]="'LAUNCHLIST.PLACEHOLDER' | translate"
        class="flex-grow border rounded-lg bg-grayscale-200 focus:outline-none focus:border focus:border-grayscale-400 font-montserrat leading-5 text-xs font-normal text-grayscale-900 px-4 py-2"
        required />
      <app-button type="submit" [disabled]="!form.valid" class="mt-2 sm:mt-0 whitespace-nowrap">{{ 'LAUNCHLIST.SIGNUP' | translate }}</app-button>
    </form>
  </ng-container>

  <ng-template #successMessage>
    <div class="success-message text-center p-4 bg-[#5C9659] text-white rounded-lg">
      {{ 'LAUNCHLIST.SUCCESS' | translate }}
    </div>
  </ng-template>

</div>
