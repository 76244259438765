import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Patient } from '@therappy-inc/models';
import { PatientStore } from './patient.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PatientQuery extends Query<Patient> {
  constructor(protected override store: PatientStore) {
    super(store);
  }

  get<K extends keyof Patient>(key: K): Patient[K] {
    return this.getValue()[key];
  }

  selectPatientProperty<K extends keyof Patient>(key: K): Observable<Patient[K]> {
    return this.select(key);
  }

  selectPatient(): Observable<Patient | null> {
    return this.select();
  }
}
