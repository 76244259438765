import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from 'src/app/shared/shared.module';
import { TherapistProfileData } from '../interfaces/therapist-profile-data.interface';
import { UserAgentQuery } from 'src/app/state/user-agent';
import { omit } from 'lodash';
import { LinkType } from '@therappy-inc/models';

@Component({
  selector: 'app-therapist-profile',
  templateUrl: './therapist-profile.component.html',
  styleUrls: ['./therapist-profile.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class TherapistProfileComponent {
  private readonly data = inject<TherapistProfileData>(MAT_DIALOG_DATA);
  private readonly dialogRef = inject(MatDialogRef<TherapistProfileComponent>);
  userAgentQuery = inject(UserAgentQuery);

  profile = this.data.profile;
  isVerified = true;
  isAddedToFavourites = false;

  emailLink = this.profile.links.EMAIL;
  bookingLink = this.profile.links.BOOKING;
  socialLinks = omit(this.profile.links, [LinkType.BOOKING, LinkType.EMAIL]);

  doEmail(): void {
    //
  }

  addToFavourites(): void {
    //
  }

  close(): void {
    this.dialogRef.close();
  }

  get showEmailBtn(): boolean {
    return !!this.profile.links.EMAIL;
  }

  get showDirectBookingBtn(): boolean {
    return !!this.profile.links.BOOKING;
  }
}
