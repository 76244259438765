import { NgModule, inject } from '@angular/core';
import { LanguageService } from './language.service';
import { TitleService } from './title.service';
import { SvgIconsService } from './svg-icons.service';

@NgModule()
export class SingletonsModule {
  private readonly languageService = inject(LanguageService);
  private readonly titleService = inject(TitleService);
  private readonly svgIconsService = inject(SvgIconsService);
}
