import { ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, from, switchMap } from 'rxjs';
import { ShowMoreAttributesData } from 'src/app/dialogs/interfaces/show-more-attributes.interface';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  private readonly dialog = inject(MatDialog);

  open<T, D = unknown, R = unknown>(component: ComponentType<T>, config?: MatDialogConfig<D>): Observable<R> {
    return this.dialog.open(component, createDialogConfig(config)).afterClosed();
  }

  showMoreAttributes(data: ShowMoreAttributesData): Observable<unknown> {
    return from(import('../../dialogs/show-more-attributes/show-more-attributes.component')).pipe(
      switchMap(({ ShowMoreAttributesComponent }) => this.open(ShowMoreAttributesComponent, { data, backdropClass: 'transparent', width: '50%' })),
    );
  }

  get hasDialogsOpen(): boolean {
    const openDialogs = this.dialog.openDialogs;
    return openDialogs.length > 0;
  }
}

export function createDialogConfig<D = void>(config?: MatDialogConfig<D>): MatDialogConfig<D> {
  return {
    width: '400px',
    autoFocus: false,
    ...config,
  };
}
