<a
  matRipple
  *ngIf="url && url.trim().length"
  class="text-center"
  [ngClass]="getButtonClasses()"
  [routerLink]="url | localizedLink"
  [queryParams]="queryParams">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
</a>

<a
  matRipple
  *ngIf="extUrl"
  [href]="extUrl"
  class="text-center"
  target="_blank"
  [ngClass]="getButtonClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
</a>

<button
  matRipple
  *ngIf="!url && !extUrl"
  [ngClass]="getButtonClasses()"
  [disabled]="disabled">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
</button>

<ng-template #ngTemplate>
  <app-inline-spinner
    *ngIf="spinner"
    [value]="progress"
    class="absolute">
  </app-inline-spinner>
  <ng-content></ng-content>
</ng-template>
