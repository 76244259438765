import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorComponent } from './form-error/form-error.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormLabelComponent } from './form-label/form-label.component';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forChild({ extend: true }),
    NgSelectModule
  ],
  declarations: [
    FormErrorComponent,
    FormInputComponent,
    FormSelectComponent,
    FormLabelComponent,
    FormCheckboxComponent
  ],
  exports: [
    FormInputComponent,
    FormSelectComponent,
    FormCheckboxComponent
  ]
})
export class FormsComponentsModule {}
