import { Component, inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Splide from '@splidejs/splide';
import { UserAgentQuery } from 'src/app/state/user-agent';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss', '../../../../node_modules/@splidejs/splide/dist/css/splide-core.min.css'],
})
export class AboutUsComponent {
  private readonly translate = inject(TranslateService);
  userAgentQuery = inject(UserAgentQuery);
  profileImg = 'https://picsum.photos/300/300';
  slidesData = [
    {
      imageUrl: 'assets/img/carousel-image.png',
      title: this.translate.instant('ABOUT_US.SLIDE1.TITLE'),
      subTitle: this.translate.instant('ABOUT_US.SLIDE1.SUBTITLE'),
      description: this.translate.instant('ABOUT_US.SLIDE1.DESCRIPTION'),
    },
    {
      imageUrl: 'assets/img/dating.png',
      title: this.translate.instant('ABOUT_US.SLIDE2.TITLE'),
      subTitle: this.translate.instant('ABOUT_US.SLIDE2.SUBTITLE'),
      description: this.translate.instant('ABOUT_US.SLIDE2.DESCRIPTION'),
    },
    {
      imageUrl: 'assets/img/aging.png',
      title: this.translate.instant('ABOUT_US.SLIDE3.TITLE'),
      subTitle: this.translate.instant('ABOUT_US.SLIDE3.SUBTITLE'),
      description: this.translate.instant('ABOUT_US.SLIDE3.DESCRIPTION'),
    },
    {
      imageUrl: 'assets/img/carousel-image.png',
      title: this.translate.instant('ABOUT_US.SLIDE4.TITLE'),
      subTitle: this.translate.instant('ABOUT_US.SLIDE4.SUBTITLE'),
      description: this.translate.instant('ABOUT_US.SLIDE4.DESCRIPTION'),
    },
    {
      imageUrl: 'assets/img/dating.png',
      title: this.translate.instant('ABOUT_US.SLIDE5.TITLE'),
      subTitle: this.translate.instant('ABOUT_US.SLIDE5.SUBTITLE'),
      description: this.translate.instant('ABOUT_US.SLIDE5.DESCRIPTION'),
    },
    {
      imageUrl: 'assets/img/aging.png',
      title: this.translate.instant('ABOUT_US.SLIDE6.TITLE'),
      subTitle: this.translate.instant('ABOUT_US.SLIDE6.SUBTITLE'),
      description: this.translate.instant('ABOUT_US.SLIDE6.DESCRIPTION'),
    },
  ];
  ngAfterViewInit() {
    const splide = new Splide('.splide', {
      type: 'loop',
      perPage: 3.3,
      perMove: 1,
      gap: '25px',
      height: '412px',
      breakpoints: {
        1024: {
          perPage: 2.5,
        },
        768: {
          perPage: 2,
        },
        640: {
          perPage: 1.5,
        },
      },
    });
    splide.mount();
  }
  contactUs(f: NgForm) {}
}
