<app-form-label
  [label]="label"
/>
<ng-select
  class="mt-1"
  [placeholder]="placeholder | translate"
  [searchable]="true"
  [items]="items"
  [formControl]="control">
    <ng-template ng-label-tmp let-item="item">
      <div>
        {{ translationPrefix + '.' + item | translate }}
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div>
        {{ translationPrefix + '.' + item | translate }}
      </div>
    </ng-template>
</ng-select>
<app-form-error />
