<ng-container *ngIf="static$ | async as static">
  <app-header>
    <app-button rightBottom variant="solid" size="sm" class="mt-3" url="home">Text1</app-button>
  </app-header>
  <h1 class="text-h3 font-regular leading-10 md:font-semibold lg:font-medium lg:leading-[64px] lg:text-h1 xl:font-semibold">
    {{ static.content.header }}
  </h1>

  <app-typography variant="h1">Hello h1</app-typography>
  <app-typography variant="h1-dark">Hello h1</app-typography>
  <app-typography variant="h2">Hello h2</app-typography>
  <app-typography variant="h2-dark">Hello h2</app-typography>
  <app-typography variant="h3">Hello h3</app-typography>
  <app-typography variant="h3-dark">Hello h3</app-typography>
  <app-typography variant="h4">Hello h4</app-typography>
  <app-typography variant="h4-dark">Hello h4</app-typography>
  <app-typography variant="h5">Hello h5</app-typography>
  <app-typography variant="h5-dark">Hello h5</app-typography>
  <app-typography variant="p-lg">Hello p Lg</app-typography>
  <app-typography variant="p-lg-dark">Hello p Lg</app-typography>
  <app-typography variant="p">Hello p</app-typography>
  <app-typography variant="p-dark">Hello p</app-typography>
  <app-typography variant="p-sm">Hello p sm</app-typography>
  <app-typography variant="p-sm-dark">Hello p sm</app-typography>
  <app-typography variant="cap-13">Hello cap 13</app-typography>
  <app-typography variant="cap-13-dark">Hello cap 13</app-typography>
  <app-typography variant="cap-12">Hello cap 12</app-typography>
  <app-typography variant="cap-12-dark">Hello cap 13</app-typography>

  <app-button size="lg" variant="solid" (onClick)="functionCall($event)">
    <app-typography variant="cap-12-dark">Hello cap 13</app-typography>
  </app-button>

  <app-pills variant="pressed"></app-pills>
  <app-pills variant="default"></app-pills>
  <app-pills variant="selected"></app-pills>

  <app-pills></app-pills>
  <app-pills></app-pills>
  <p>home works!</p>
</ng-container>
