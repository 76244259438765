import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { createInitialState, UserAgent } from './user-agent.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'userAgent' })
export class UserAgentStore extends Store<UserAgent> {
  constructor() {
    super(createInitialState());
  }
}
