import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-more-panel',
  templateUrl: './view-more-panel.component.html',
  styleUrls: ['./view-more-panel.component.scss'],
})
export class ViewMorePanelComponent {
  data = inject<{ title: string; items: any[] | any; translateKey: string }>(MAT_DIALOG_DATA);
}
