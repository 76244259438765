import { Component, inject } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {

  private readonly ngControl = inject(NgControl);

  get showError() {
    return this.ngControl.dirty;
  }

  get errors() {
    return this.ngControl.errors;
  }
}
