import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { PlaygroundComponent } from './playground.component';
import { LayoutModule } from 'src/app/core/layout/layout.module';

@NgModule({
  declarations: [PlaygroundComponent],
  imports: [SharedModule, LayoutModule],
  exports: [PlaygroundComponent],
})
export class PlaygroundModule {}
