<div class="flex justify-center items-center flex-wrap gap-3">
  <app-pill
    *ngFor="let item of state | keyvalue:compareFn; trackBy: trackBy"
    [selected]="item.value"
    [label]="translationPrefix + '.' + item.key | translate"
    (click)="emitItemClick(item.key)">
  </app-pill>
  <app-pill
    *ngIf="showClearPill"
    [selected]="noItemsSelected"
    [label]="clearPillLabel | translate"
    (click)="emitClearClick()">
  </app-pill>
</div>
