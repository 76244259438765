import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs';
import { AuthQuery } from 'src/app/state/auth';
import { LocalizedRouterService } from '../services/localized-router.service';
import { PatientOnboardingStatus, TherapistOnboardingStatus, UserType } from '@therappy-inc/models';
import { PatientQuery } from 'src/app/state/patient';
import { TherapistQuery } from 'src/app/state/therapist';

export const authGuard: CanActivateFn = (route, state) => {
  const localizedRouter = inject(LocalizedRouterService);
  const authQuery = inject(AuthQuery);
  const patientQuery = inject(PatientQuery);
  const user$ = authQuery.selectCurrentUser();

  return user$.pipe(
    map(user => {
      if (user) {
        const roles = route.data['roles'];
        if (!roles) {
          return true;
        }
        if (roles.includes(UserType.Therapist) && user.therapistId) {
          return true;
        }
        if (roles.includes(UserType.Patient) && user.patientId) {
          const patientOnboardingStatus = patientQuery.get('onboardingStatus');
          if (patientOnboardingStatus >= PatientOnboardingStatus.SEXUAL_ORIENTATION) {
            if (route.routeConfig?.path === 'patient-onboarding') {
              localizedRouter.navigate(['/']);
              return false;
            }
            return true;
          } else {
            localizedRouter.navigate(['patient-onboarding']);
            return false;
          }
        }
        if (roles.includes(UserType.Patient)) {
          localizedRouter.navigate(['patient-onboarding']);
        } else {
          localizedRouter.navigate(['therapist-onboarding']);
        }
        return false;
      } else {
        // not logged in so redirect to login page with the return url
        localizedRouter.navigate(['auth', 'login'], {
          queryParams: {
            isTherapistSignup: true,
            returnUrl: state.url,
          },
        });
        return false;
      }
    }),
  );
};

export const dashboardGuard: CanActivateFn = (route, state) => {
  const localizedRouter = inject(LocalizedRouterService);
  const therapistQuery = inject(TherapistQuery);
  const therapist$ = therapistQuery.selectTherapist();

  return therapist$.pipe(
    map(therapist => {
      if (therapist) {
        if (therapist.onboardingStatus < TherapistOnboardingStatus.PAYMENT) {
          localizedRouter.navigate(['therapist-dashboard', 'profile']);
          return false;
        }
        return true;
      } else {
        // not logged in so redirect to login page with the return url
        localizedRouter.navigate(['auth', 'login'], {
          queryParams: {
            isTherapistSignup: true,
            returnUrl: state.url,
          },
        });
        return false;
      }
    }),
  );
};
