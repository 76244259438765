import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStateQuery } from 'src/app/state/local';
import { LOCALES, LocalesMap } from '../di/locale.token';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private readonly localStateQuery = inject(LocalStateQuery);
  private readonly translate = inject(TranslateService);
  locales = inject<LocalesMap>(LOCALES);

  constructor() {
    const asArray = Array.from(this.locales.keys());
    this.translate.addLangs(asArray);
    this.translate.setDefaultLang(asArray[0]);
    this.trackLocale();

    const lang = this.localStateQuery.get('locale');
    this.setLocale(lang);
  }

  setLocale(language: string): void {
    this.translate.use(language);
  }

  private trackLocale(): void {
    this.localStateQuery.select('locale').subscribe(lang => this.setLocale(lang));
  }
}
