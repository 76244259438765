import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pills',
  templateUrl: './pills.component.html',
  styleUrls: ['./pills.component.scss'],
})
export class PillsComponent {
  @Input() variant: 'pressed' | 'selected' | 'default' = 'default';
  @Input() label = 'Hello';
  @Input() class?: string;

  @Input() set selected(isSelected: boolean) {
    this.variant = isSelected ? 'selected' : 'pressed';
  }

  getPillsClasses() {
    let classes = 'py-1.5	px-2.5 rounded-[20px] border leading-4 font-montserrat font-xxxs text-center h-auto w-auto ';
    if (this.variant === 'pressed') {
      classes += 'border-lime-regular text-black font-normal hover:border-lime-regular hover:bg-lime-light hover:text-lime-regular hover:font-normal';
    } else if (this.variant === 'selected') {
      classes += 'bg-lime-regular text-grayscale-200';
    } else {
      classes += 'border-lime-regular bg-lime-light text-lime-regular font-normal';
    }
    return classes + ' ' + this.class;
  }
}
