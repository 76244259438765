import { CommonModule, UpperCasePipe } from '@angular/common';
import { NgModule, PipeTransform, Type } from '@angular/core';
import { LocalizedLinkPipe } from './localized-link.pipe';
import { MapToStringsPipe } from './map-to-strings.pipe';
import { FormErrorPipe } from './form-error.pipe';
import { InitialPipe } from './initial.pipe';
import { SimplePercentPipe } from './simple-percent.pipe';
import { PluralPipe } from './plural.pipe';
import { TranslateArrayPipe } from './translate-array.pipe';
import { ArrayToStringPipe } from './array-to-string.pipe';
import { BooleanPipe } from './boolean.pipe';
import { ValuesPipe } from './values.pipe';
import { FirebaseErrorPipe } from './firebase-error.pipe';

const pipes: Type<PipeTransform>[] = [
  LocalizedLinkPipe,
  MapToStringsPipe,
  FormErrorPipe,
  InitialPipe,
  SimplePercentPipe,
  PluralPipe,
  TranslateArrayPipe,
  ArrayToStringPipe,
  BooleanPipe,
  ValuesPipe,
  FirebaseErrorPipe
];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
  providers: [...pipes, UpperCasePipe],
})
export class PipesModule {}
