import { createSvgManifest } from 'src/app/core/services/svg-icons.service';

export const SVG_MANIFEST = createSvgManifest({
  social: {
    whatsapp: 'assets/svg/socials/whatsapp.svg',
    copy: 'assets/svg/socials/copy.svg',
    telegram: 'assets/svg/socials/telegram.svg',
    facebook: 'assets/svg/socials/facebook.svg',
    email: 'assets/svg/socials/email.svg',
  },
  providers: {
    facebook: 'assets/svg/providers/facebook.svg',
    google: 'assets/svg/providers/google.svg',
  }
});
