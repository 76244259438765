<div class="flex flex-wrap justify-start md:justify-between gap-2">
  <div class="flex items-center gap-2 min-w-[30%] md:min-w-min" *ngFor="let option of options">
    <input
      type="checkbox"
      class="cursor-pointer appearance-none bg-check-mark bg-no-repeat bg-center bg-[length:0.55em_0.55em] w-4 h-4 text-green-600 bg-transparant border border-grayscale-900 checked:border-none checked:bg-green-regular checked:ring-green-regular checked:ring-2 dark:focus:ring-green-regular dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
      [checked]="selectedOptions.includes(option)"
      (change)="onchange($event, option)" />
    <app-typography variant="p-sm">{{ 'ENUMS.' + translateKey + '.' + option | translate }}</app-typography>
  </div>
</div>
