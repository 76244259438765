<h1>Registration Backend Demo</h1>

<form [formGroup]="form" (submit)="onSubmit()">
  <div>
    <strong>Your Name</strong>
    <input matInput type="text" formControlName="name" required />
    <label>{{ form.get('name').errors | formError }}</label>
  </div>

  <div>
    <strong>E-Mail</strong>
    <input matInput type="email" formControlName="email" required />
    <label>{{ form.get('email').errors | formError }}</label>
  </div>

  <div>
    <strong>Choose a Password</strong>
    <input matInput type="password" formControlName="password" required />
    <label>{{ form.get('password').errors | formError }}</label>
  </div>

  <div>
    <strong>Enter Password Again</strong>
    <input matInput type="password" formControlName="passwordAgain" required />
    <label>{{ form.get('passwordAgain').errors | formError }}</label>
  </div>

  <div>
    <strong>Accept Privacy Policy</strong>
    <input type="checkbox" formControlName="acceptPrivacyPolicy" />
  </div>

  <div *ngIf="error$ | async as err">
    <span>{{ err.message }}</span>
    <button (click)="clearError()">Clear Error</button>
  </div>

  <button [disabled]="form.invalid" type="submit">Register</button>
</form>
