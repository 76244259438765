<div class="relative flex flex-col gap-[24px] max-w-[630px] bg-grayscale-800 text-grayscale-200 p-6 md:p-8">
  <i class="fa-solid fa-xmark absolute right-8 text-xl cursor-pointer" mat-dialog-close></i>
  <div class="flex items-center gap-3">
    <i class="fa-solid fa-lock text-[20px]"></i>
    <app-typography variant="h4" *ngIf="!isSubmitted">{{ 'FORGOT_PASSWORD.TITLE' | translate }}</app-typography>
    <app-typography variant="h4" *ngIf="isSubmitted">{{ 'FORGOT_PASSWORD.PLEASE_CHECK' | translate }}</app-typography>
  </div>
  <ng-container *ngIf="!isSubmitted">
    <app-typography variant="p-sm">{{ 'FORGOT_PASSWORD.PLEASE_ENTER' | translate }}</app-typography>
    <form class="flex flex-col gap-6" [formGroup]="form">
      <div class="flex flex-col justify-center">
        <app-typography variant="p-sm">{{ 'LABEL.EMAIL_ADDRESS' | translate }}</app-typography>
        <input
          type="email"
          formControlName="email"
          [placeholder]="'PLACEHOLDER.ENTER_YOUR_EMAIL' | translate"
          class="border rounded-lg bg-grayscale-200 focus:outline-none focus:border focus:border-grayscale-400 font-montserrat leading-5 text-xs font-normal text-grayscale-900 px-4 py-2 mt-1"
          required />
        <small class="error">{{ form.controls['email'].errors | formError }}</small>
      </div>
    </form>
    <div class="flex gap-4">
      <app-button variant="outlined" [size]="userAgentQuery.selectIsMobile() ? 'sm' : 'lg'" class="!text-grayscale-200" mat-dialog-close>{{ 'LABEL.CANCEL' | translate }}</app-button>
      <app-button variant="solid" [size]="userAgentQuery.selectIsMobile() ? 'sm' : 'lg'" [disabled]="!form.valid" (onClick)="sendResetMail()">{{ 'FORGOT_PASSWORD.SEND_RESET_MAIL' | translate }}</app-button>
    </div>
  </ng-container>
  <ng-container *ngIf="isSubmitted">
    <div class="text-center my-[30px]">
      <app-typography variant="p-sm">{{ 'FORGOT_PASSWORD.SENT_CONFIRM' | translate }}</app-typography>
    </div>
    <div class="flex justify-center">
      <app-button variant="solid" [size]="userAgentQuery.selectIsMobile() ? 'sm' : 'lg'" [mat-dialog-close]="true">{{ 'LABEL.CLOSE' | translate }}</app-button>
    </div>
  </ng-container>
</div>
