import { HttpClient } from '@angular/common/http';
import { Component, AfterViewInit, Input, inject, NgZone } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormGroup } from '@ngneat/reactive-forms';
import { AlertService } from 'src/app/core/services/alert.service';
import { BehaviorSubject, EMPTY, catchError, firstValueFrom, switchMap, tap } from 'rxjs';

interface IpapiResponse {
  country_name: string;
  region: string;
}

@Component({
  selector: 'app-launchlist-widget',
  templateUrl: `./get-launch-list.component.html`
})
export class GetLaunchlistComponent {
  @Input() keyId: string = ''; // This should be provided when using the component
  @Input() userType: 'therapist' | 'user' = 'user'; // default to 'user'
  @Input() location= ''

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  private readonly alerts = inject(AlertService);
  private isSubmittedSubject = new BehaviorSubject<boolean>(false);
  isSubmitted$ = this.isSubmittedSubject.asObservable();


  ngAfterViewInit() {
    this.loadLaunchListScript();
    this.getUserLocation();
  }

  constructor(private http: HttpClient,private ngZone: NgZone) {}

  private loadLaunchListScript(): void {
    if ((window as any).LaunchList) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://getlaunchlist.com/js/widget-diy.js';
    script.defer = true;
    document.head.appendChild(script);
  }

  private getUserLocation(): void {
    this.http.get<IpapiResponse>('https://ipapi.co/json/').pipe(
      catchError((error) => {
        console.error('Error fetching location:', error);
        return EMPTY;
      })
    ).subscribe((response: IpapiResponse) => {
      const ip_location = `${response.country_name}, ${response.region}`;
      this.location=ip_location
    });
  }

  get formAction(): string {
    return `https://getlaunchlist.com/s/${this.keyId}`;
  }
  onSubmit() {
    if (this.form.valid) {
      const formData = new FormData();
      formData.append('email', this.form.get('email')!.value ?? '');
      formData.append('user_type', this.userType);
      formData.append('location', this.location);

      this.http.post(`https://getlaunchlist.com/s/${this.keyId}`, formData)
        .pipe(
          tap(() => {
            this.isSubmittedSubject.next(true);
          }),
          catchError((error) => {
            console.error('Submission error:', error);
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

}
