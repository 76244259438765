import { Component, Inject, inject } from '@angular/core';
import { STATIC_CONTENT, StaticContentChanges } from 'src/app/core/di/static-content.token';
import { PlaygroundContent } from './playground-content.interface';
import { Gender } from '@therappy-inc/models';

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss'],
})
export class PlaygroundComponent {
  static$ = inject<StaticContentChanges<PlaygroundContent>>(STATIC_CONTENT);

  Gender = Gender;
  filters = ['Hindi', 'Punjabi', 'Gujarati', 'Hindi', 'Punjabi', 'Gujarati', 'Hindi', 'Punjabi', 'Gujarati', 'Hindi', 'Punjabi', 'Gujarati', 'Hindi', 'Punjabi', 'Gujarati'];

  functionCall(event: any) {
    console.log('functionCall', event);
    console.log('Button Is clicked');
    alert('Called');
  }
}
