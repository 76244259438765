<label
  class="pb-2 pt-2 text-sm"
  *ngIf="label">
  {{ label | translate }}
</label>
<label
  *ngIf="showRequired"
  class="text-accent-red">
  *
</label>
