import { OrArray } from '@datorama/akita';
import { pickBy } from 'lodash';

export type FilterPropType = OrArray<string> | OrArray<number> | boolean | null | undefined;
export type FilterProps = Record<string, FilterPropType>;

export function createFilterString<T extends FilterProps>(props: T, prefix = ''): string {
  const fullPrefix = prefix ? `${prefix}.` : '';
  const attributes = Object.entries(filterActiveKeys(props)).map(([key, value]) => {
    return Array.isArray(value) ? value.map(v => `${fullPrefix}${key}:${encodeURIComponent(v)}`).join(' AND ') : `${fullPrefix}${key}:${encodeURIComponent(`${value}`)}`;
  });
  return attributes.join(' AND ');
}

export function filterActiveKeys<T extends FilterProps>(input: T): Partial<T> {
  return pickBy<T>(input, value => isFilterActive(value));
}

function isFilterActive(value: unknown): boolean {
  if (typeof value === 'undefined' || value === null || value === '') {
    return false;
  }
  if (typeof value === 'boolean') {
    return false;
  }
  if (typeof value === 'number') {
    return value > 0 && value < Infinity;
  }
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return true;
}

