<div class="flex items-center gap-2 px-3">
  <div
    *ngFor="let item of enabledSocials"
    [shareButton]="item"
    [title]="'LABEL.SHARE_TITLE' | translate"
    [description]="'LABEL.SHARE_DESCRIPTION' | translate"
    [url]="url">
      <app-button
        variant="rounded"
        size="sm"
        (onClick)="onClick(item)">
          <mat-icon [svgIcon]="socialToIconMap[item]"></mat-icon>
      </app-button>
  </div>
</div>
