import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormCheckboxComponent
    }
  ]
})
export class FormCheckboxComponent extends ControlValueAccessor<boolean> {

  control = new FormControl(false);

  @Input() label = '';

  override writeValue(value: boolean): void {
    this.control.setValue(value, { emitEvent: false });
  }

  override registerOnChange(fn: ControlValueAccessor['onChange']): void {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.control.setDisable(isDisabled);
  }

}
