import { Environment } from './model';

export const environment: Environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA0swn4lXZNBOzHssCGbZVmi8rphnNqwx8',
    authDomain: 'therappyinc-dev.firebaseapp.com',
    projectId: 'therappyinc-dev',
    storageBucket: 'therappyinc-dev.appspot.com',
    messagingSenderId: '998725800630',
    appId: '1:998725800630:web:a36a3ce9f2fd87064ecc17',
  },
  googleMaps: {
    key: 'AIzaSyDE_vRL7yu6DndxEIRsrw3DMW9vmENguTM',
  },
  algolia: {
    key: 'a30e2f6525c0e3411d9f90821aac91c1',
    appId: 'B1VR5L77H1',
  },
};
