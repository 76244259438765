import { Component, inject } from '@angular/core';
import { FilterQuery, FilterService, TherapistFilterProps } from 'src/app/state/search';

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
})
export class FilterChipsComponent {
  private readonly filterQuery = inject(FilterQuery);
  private readonly filterService = inject(FilterService);

  activeFilters$ = this.filterQuery.selectActiveFilterKeys();

  removeFilter(key: string): void {
    this.filterService.resetFilter(key as keyof TherapistFilterProps);
  }

  trackBy(index: number, item: string): string {
    return item;
  }
}
