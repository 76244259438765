<body class="flex flex-col min-h-screen">
  <header>
    <app-header
      [title]="'ABOUT_US.TITLE' | translate"
      [subTitle]="'ABOUT_US.SUBTITLE' | translate"
    ></app-header>
  </header>

  <main class="flex-grow flex justify-center">
    <div class="editor-content w-full max-w-4xl px-4 md:px-0">
      <div class="flex flex-col md:py-20 gap-10">
        <div class="w-full">
          <app-typography variant="h3-dark">{{ 'ABOUT_US.SECTION1.TITLE' | translate }}</app-typography>
        </div>

        <div class="w-full">
          <div class="bg-grayscale-300 border border-grayscale-400 rounded-lg p-4">
            <div class="flex flex-col md:flex-row items-center gap-6">
              <div class="w-full md:w-[30%] flex justify-center items-center">
                <img src="/assets/svg/about_us.svg" alt="About Us" class="w-auto h-auto max-h-[50%] rounded-lg">
              </div>
              <div class="w-full md:w-[70%] flex items-center">
                <div class="max-h-[400px] overflow-y-auto">
                  <app-typography variant="p-sm">{{ 'ABOUT_US.SECTION1.DESCRIPTION1' | translate }}</app-typography>
                  <div class="mb-4"></div>
                  <app-typography variant="p-sm">{{ 'ABOUT_US.SECTION1.DESCRIPTION2' | translate }}</app-typography>
                  <div class="mb-4"></div>
                  <app-typography variant="p-sm">{{ 'ABOUT_US.SECTION1.DESCRIPTION3' | translate }}</app-typography>
                  <div class="mb-4"></div>
                  <app-typography variant="p-sm">{{ 'ABOUT_US.SECTION1.DESCRIPTION4' | translate }}</app-typography>
                  <div class="mb-4"></div>
                  <app-typography variant="p-sm">{{ 'ABOUT_US.SECTION1.DESCRIPTION5' | translate }}</app-typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <footer>
    <app-footer></app-footer>
  </footer>
</body>
