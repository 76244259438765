import { Component, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { AuthQuery, AuthService } from 'src/app/state/auth';
import { AlertService } from 'src/app/core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { UserAgentQuery } from 'src/app/state/user-agent';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss'],
})
export class ForgotPasswordModalComponent {
  private readonly alertService = inject(AlertService);
  private readonly translate = inject(TranslateService);
  private readonly authQuery = inject(AuthQuery);
  private readonly authService = inject(AuthService);
  userAgentQuery = inject(UserAgentQuery);
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  isSubmitted = false;

  sendResetMail() {

  }
}
