import { collection, CollectionReference, doc, Firestore, getDoc, runTransaction, UpdateData, writeBatch, WriteBatch } from '@angular/fire/firestore';
import { FireAuthState, initialAuthState } from './fire-auth.model';
import {
  Auth,
  AuthErrorCodes,
  AuthProvider,
  authState,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  reauthenticateWithCredential,
  User,
  UserCredential,
  EmailAuthProvider,
  updatePassword,
  sendPasswordResetEmail,
} from '@angular/fire/auth';
import { UpdateCallback, WriteOptions } from '../utils/types';
import { Store } from '@datorama/akita';
import { inject } from '@angular/core';
import { combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { docValueChanges } from '../utils/firestore';
import { FireProvider, getAuthProvider, isFireAuthProvider } from './fire-auth.utils';
import { isFirebaseError } from '../utils/errors';

export class FireAuthService<S extends FireAuthState> {
  private readonly collection: CollectionReference<S['profile']>;
  protected collectionPath = 'users';
  protected includeMetadataChanges = false;
  protected db: Firestore;
  public auth: Auth;
  /** Triggered when the profile has been created */
  protected onCreate?(profile: S['profile'], options: WriteOptions): any;
  /** Triggered when the profile has been updated */
  protected onUpdate?(profile: S['profile'], options: WriteOptions): any;
  /** Triggered when the profile has been deleted */
  protected onDelete?(options: WriteOptions): any;
  /** Triggered when user signin for the first time or signup with email & password */
  protected onSignup?(user: UserCredential, options: WriteOptions): any;
  /** Triggered when a user signin, except for the first time @see onSignup */
  protected onSignin?(user: UserCredential, profile: S['profile']): any;
  /** Triggered when a user reauthenticate */
  protected onReauthenticate?(user: UserCredential): any;
  /** Triggered when a user signout */
  protected onSignout?(): any;

  constructor(
    protected store: Store<S>,
    db?: Firestore,
    auth?: Auth,
  ) {
    this.db = db || inject(Firestore);
    this.auth = auth || inject(Auth);
    this.collection = collection(this.db, this.path);
  }

  /**
   * Select the profile in the Firestore
   * @note can be override to point to a different place
   */
  protected selectProfile(user: User): Observable<S['profile']> {
    const ref = doc(this.collection, user.uid);
    return docValueChanges<S['profile']>(ref, {
      includeMetadataChanges: this.includeMetadataChanges,
    });
  }

  /**
   * Select the roles for this user. Can be in custom claims or in a Firestore collection
   * @param user The user given by FireAuth
   * @see getCustomClaims to get the custom claims out of the user
   * @note Can be overwritten
   */
  protected selectRoles(user: User): Promise<S['roles']> | Observable<S['roles']> {
    return of();
  }

  /**
   * Function triggered when getting data from firestore
   * @note should be overwritten
   */
  protected formatFromFirestore(user: any): S['profile'] {
    return user;
  }

  /**
   * Function triggered when adding/updating data to firestore
   * @note should be overwritten
   */
  protected formatToFirestore(user: S['profile']): UpdateData<S['profile']> {
    return user;
  }

  /**
   * Function triggered when transforming a user into a profile
   * @param user The user object from FireAuth
   * @param ctx The context given on signup
   * @note Should be override
   */
  protected createProfile(user: User, ctx?: any): Promise<Partial<S['profile']>> | Partial<S['profile']> {
    return {
      photoURL: user.photoURL,
      displayName: user.displayName,
    } as any;
  }

  /**
   * The current sign-in user (or null)
   * @returns a Promise in v6.*.* & a snapshot in v5.*.*
   */
  get user() {
    return this.auth.currentUser;
  }

  get idKey() {
    return (this.constructor as any)['idKey'] || 'id';
  }

  /** The path to the profile in firestore */
  get path() {
    return (this.constructor as any)['path'] || this.collectionPath;
  }

  /** Start listening on User */
  sync() {
    return authState(this.auth as any).pipe(
      switchMap(user => (user ? combineLatest([of(user), this.selectProfile(user), this.selectRoles(user)]) : of([undefined, undefined, undefined]))),
      tap(([user = {}, userProfile, roles]) => {
        const profile = this.formatFromFirestore(userProfile);
        const { uid, emailVerified } = user;
        this.store.update({
          uid,
          emailVerified,
          profile,
          roles,
        } as any);
      }),
      map(([user, userProfile, roles]) => (user ? [user, this.formatFromFirestore(userProfile), roles] : null)),
    );
  }

  async get(id: string): Promise<S['profile'] | void> {
    const ref = doc(this.collection, id);
    const document = await getDoc(ref);
    if (document.exists()) {
      return this.formatFromFirestore(document.data());
    }
    return;
  }

  /**
   * @description Delete user from authentication service and database
   * WARNING This is security sensitive operation
   */
  async delete(options: WriteOptions = {}) {
    const user = this.user;
    if (!user) {
      throw new Error('No user connected');
    }
    const { write = writeBatch(this.db), ctx } = options;
    const ref = doc(this.collection, user.uid);
    write.delete(ref);
    if (!options.write) {
      await (write as WriteBatch).commit();
    }
    const del = user.delete();
    if (this.onDelete) {
      await this.onDelete({ write, ctx });
    }
    return del;
  }

  /** Update password */
  async updatePassword(password: string): Promise<void> {
    const user = this.user;
    if (!user) {
      throw new Error('No user connected');
    }
    this.store.setLoading(true);
    try {
      return await updatePassword(user, password);
    } catch (err) {
      this.store.setLoading(false);
      if (isFirebaseError(err)) {
        if (err.code === AuthErrorCodes.OPERATION_NOT_ALLOWED) {
          console.warn('You tried to connect with a disabled auth provider. Enable it in Firebase console');
        }
      }

      throw err;
    }
  }

  /** Update the current profile of the authenticated user */
  async update(profile: Partial<S['profile']> | UpdateCallback<S['profile']>, options: WriteOptions = {}) {
    const user = this.user;
    if (!user?.uid) {
      throw new Error('No user connected.');
    }
    const ref = doc(this.collection, user.uid);
    if (typeof profile === 'function') {
      return runTransaction(this.db, async tx => {
        const snapshot = await tx.get(ref);
        const userDoc = Object.freeze({
          ...snapshot.data(),
          [this.idKey]: snapshot.id,
        });
        const data = (profile as UpdateCallback<S['profile']>)(this.formatToFirestore(userDoc), tx) as UpdateData<S['profile']>;
        tx.update(ref, data);
        if (this.onUpdate) {
          await this.onUpdate(data, { write: tx, ctx: options.ctx });
        }
        this.store.update({
          profile: this.formatFromFirestore(data),
        } as any);
        return tx;
      });
    } else if (typeof profile === 'object') {
      const { write = writeBatch(this.db), ctx } = options;
      (write as WriteBatch).update(ref, this.formatToFirestore(profile));
      if (this.onUpdate) {
        await this.onUpdate(profile, { write, ctx });
      }
      const document = await getDoc(ref);
      if (document.exists()) {
        const curProfile = this.formatFromFirestore(document.data());
        const newProfile = {
          ...curProfile,
          ...profile,
        };
        this.store.update({ profile: newProfile } as any);
      }
      // If there is no atomic write provided
      if (!options.write) {
        return (write as WriteBatch).commit();
      }
    }
  }

  /** Create a user based on email and password */
  async signup(email: string, password: string, options: WriteOptions = {}): Promise<UserCredential> {
    const cred = await createUserWithEmailAndPassword(this.auth, email, password);
    const { write = writeBatch(this.db), ctx } = options;
    const profile = await this.createProfile(cred.user, ctx);
    const ref = doc(this.collection, cred.user.uid);
    (write as WriteBatch).set(ref, this.formatToFirestore(profile));
    if (this.onCreate) {
      await this.onCreate(profile, { write, ctx });
    }
    if (!options.write) {
      await (write as WriteBatch).commit();
    }
    this.store.update({ profile } as S['profile']);
    if (this.onSignup) {
      await this.onSignup(cred, { write, ctx });
    }
    return cred;
  }

  /** Signin with email & password, provider name, provider objet or custom token */
  // tslint:disable-next-line: unified-signatures
  signin(email: string, password: string, options?: WriteOptions): Promise<UserCredential>;
  signin(authProvider: AuthProvider, options?: WriteOptions): Promise<UserCredential>;
  signin(provider?: FireProvider, options?: WriteOptions): Promise<UserCredential>;
  // tslint:disable-next-line: unified-signatures
  signin(token: string, options?: WriteOptions): Promise<UserCredential>;
  async signin(provider?: FireProvider | AuthProvider | string, passwordOrOptions?: string | WriteOptions): Promise<UserCredential> {
    this.store.setLoading(true);
    let profile;
    try {
      let cred: UserCredential;
      const write = writeBatch(this.db);
      if (!provider) {
        cred = await signInAnonymously(this.auth);
      } else if (passwordOrOptions && typeof provider === 'string' && typeof passwordOrOptions === 'string') {
        cred = await signInWithEmailAndPassword(this.auth, provider, passwordOrOptions);
      } else if (typeof provider === 'object') {
        cred = await signInWithPopup(this.auth, provider);
      } else if (isFireAuthProvider(provider)) {
        const authProvider = getAuthProvider(provider);
        cred = await signInWithPopup(this.auth, authProvider);
      } else {
        cred = await signInWithCustomToken(this.auth, provider);
      }
      if (getAdditionalUserInfo(cred)?.isNewUser) {
        if (this.onSignup) {
          await this.onSignup(cred, {});
        }
        profile = await this.createProfile(cred.user);
        this.store.update({ profile } as S['profile']);
        const ref = doc(this.collection, cred.user.uid);
        write.set(ref, this.formatToFirestore(profile));
        if (this.onCreate) {
          if (typeof passwordOrOptions === 'object') {
            await this.onCreate(profile, {
              write,
              ctx: passwordOrOptions.ctx,
            });
          } else {
            await this.onCreate(profile, { write, ctx: {} });
          }
        }
        await write.commit();
      } else {
        try {
          const userRef = doc(this.collection, cred.user.uid);
          const document = await getDoc(userRef);
          const { uid, emailVerified } = cred.user;
          if (document.exists()) {
            profile = this.formatFromFirestore(document.data());
          } else {
            profile = await this.createProfile(cred.user);
            write.set(userRef, this.formatToFirestore(profile));
            write.commit();
          }
          this.store.update({ profile, uid, emailVerified } as any);
        } catch (error) {
          console.error(error);
        }
      }
      if (this.onSignin) {
        await this.onSignin(cred, profile);
      }
      this.store.setLoading(false);
      return cred;
    } catch (err) {
      this.store.setLoading(false);
      if (isFirebaseError(err)) {
        if (err.code === AuthErrorCodes.OPERATION_NOT_ALLOWED) {
          console.warn('You tried to connect with a disabled auth provider. Enable it in Firebase console');
        }
      }

      throw err;
    }
  }

  /** Signs out the current user and clear the store */
  async signOut() {
    await this.auth.signOut();
    this.store.update(initialAuthState as Partial<S>);
    if (this.onSignout) {
      await this.onSignout();
    }
  }

  /** Reauthenticate with email & password */
  async reauthenticateWithCredential(email: string, password: string): Promise<UserCredential> {
    if (!this.user?.uid) {
      throw new Error('No user connected.');
    }
    this.store.setLoading(true);
    try {
      const authCredential = EmailAuthProvider.credential(email, password);
      const cred: UserCredential = await reauthenticateWithCredential(this.user, authCredential);
      if (this.onReauthenticate) {
        await this.onReauthenticate(cred);
      }
      this.store.setLoading(false);
      return cred;
    } catch (err) {
      this.store.setLoading(false);
      if (isFirebaseError(err)) {
        if (err.code === AuthErrorCodes.OPERATION_NOT_ALLOWED) {
          console.warn('You tried to connect with a disabled auth provider. Enable it in Firebase console');
        }
      }

      throw err;
    }
  }
}
