<form [formGroup]="form">
  <div class="flex justify-between mb-[30px]">
    <div class="flex gap-2">
      <app-button variant="solid" [size]="(userAgentQuery.selectIsMobile() | async) ? 'sm' : 'md'" class="w-[87px] md:w-auto" (click)="openPopup()">
        {{ 'LABEL.FILTERS' | translate }}
        <i class="fa-solid fa-sliders ml-1 md:ml-2"></i>
      </app-button>
      <div>
        <div>
          <div class="w-[calc(100vw-120px)] md:w-auto overflow-x-auto md:overflow-hidden flex gap-2 mb-2">
            <div class="min-w-[160px] w-[160px]">
              <ng-select
                placeholder="Concerns"
                [multiple]="true"
                [closeOnSelect]="false"
                [searchable]="true"
                appendTo="body"
                [items]="sharedService.specialityList"
                formControlName="specialities">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
                    <span class="ng-value-label">{{ 'ENUMS.SPECIALITIES.' + item | translate }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)">×</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label">{{ items.length - 1 }} {{ 'LABEL.MORE' | translate }}...</span>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div>
                    {{ 'ENUMS.SPECIALITIES.' + item | translate }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="min-w-[160px] w-[160px]">
              <ng-select placeholder="Designation" [searchable]="true" appendTo="body" [items]="sharedService.designationList" formControlName="designation">
                <ng-template ng-label-tmp let-item="item">
                  <div>
                    {{ 'ENUMS.DESIGNATION.' + item | translate }}
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div>
                    {{ 'ENUMS.DESIGNATION.' + item | translate }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="min-w-[160px] w-[160px]">
              <ng-select
                placeholder="Type of Therapy"
                [multiple]="true"
                [closeOnSelect]="false"
                [searchable]="true"
                appendTo="body"
                [items]="sharedService.typeOfTherapyList"
                formControlName="typeOfTherapy">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
                    <span class="ng-value-label">{{ 'ENUMS.TYPES_OF_THERAPY.' + item | translate }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)">×</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label">{{ items.length - 1 }} {{ 'LABEL.MORE' | translate }}...</span>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div>
                    {{ 'ENUMS.TYPES_OF_THERAPY.' + item | translate }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="min-w-[194px] w-[194px]">
              <div
                [matMenuTriggerFor]="menu"
                (menuOpened)="priceRangeMenuOpened()"
                (menuClosed)="priceRangeMenuClosed()"
                class="flex items-center justify-between bg-grayscale-200 rounded-lg border border-grayscale-400 p-[7px]">
                <app-typography variant="p-sm">{{ 'FIND_THERAPIST.FILTERS.PRICE_RANGE' | translate }}</app-typography>
                <i
                  class="fa-solid fa-angle-up"
                  [ngClass]="{
                    'fa-angle-up': isPriceRangeMenuOpened,
                    'fa-angle-down': !isPriceRangeMenuOpened
                  }"></i>
              </div>
              <mat-menu #menu="matMenu" xPosition="before">
                <div class="w-[194px] bg-grayscale-200 rounded-lg px-[16px] py-[8px]" (click)="$event.stopPropagation()">
                  <div class="flex">
                    <app-typography variant="p-sm-dark" class="!text-[12px]" [class.text-green-regular]="!form.controls.isPriceRangeForCouple.value">{{ 'FIND_THERAPIST.FILTERS.INDIVIDUAL' | translate }}</app-typography>
                    <label class="relative inline-flex items-center cursor-pointer mx-2">
                      <input type="checkbox" value="" class="sr-only peer" formControlName="isPriceRangeForCouple" />
                      <div
                        class="w-5 h-3.5 bg-green-regular peer-focus:outline-none peer-focus:ring-2 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[7px] after:left-[3.5px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-1.5 after:w-1.5 after:transition-all dark:border-gray-600 peer-checked:bg-green-regular"></div>
                    </label>
                    <app-typography variant="p-sm-dark" class="!text-[12px]" [class.text-green-regular]="form.controls.isPriceRangeForCouple.value">{{ 'FIND_THERAPIST.FILTERS.COUPLE' | translate }}</app-typography>
                  </div>
                  <mat-slider min="0" max="100" discrete [displayWith]="formatLabel" class="!w-[95%] !mt-[10px] !mx-[0px]">
                    <input formControlName="minPrice" matSliderStartThumb />
                    <input formControlName="maxPrice" matSliderEndThumb />
                  </mat-slider>
                </div>
              </mat-menu>
            </div>
            <app-button
              *ngIf="true"
              variant="solid"
              [size]="(userAgentQuery.selectIsMobile() | async) ? 'sm' : 'md'"
              class="min-w-[100px] rounded-lg !bg-grayscale-300 !text-green-regular">
              {{ 'FIND_THERAPIST.FILTERS.CLEAR_ALL' | translate }}
              <i class="fa-solid fa-xmark ml-1 md:ml-2"></i>
            </app-button>
          </div>
          <div class="flex flex-wrap gap-2">
            <app-filter-pill *ngFor="let filter of activeFilters$ | async" [label]="filter" (emitClose)="removeFilter(filter)"></app-filter-pill>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-sidepopup-backdrop" [ngClass]="{ show: sharedService.showFilterPopup$ | async }" (click)="closePopup()"></div>
  <div class="custom-sidepopup" [ngClass]="{ show: sharedService.showFilterPopup$ | async }">
    <div class="popup-content">
      <div class="w-screen md:w-[640px] h-full flex flex-col justify-between p-[24px]">
        <div class="flex flex-col gap-[24px]">
          <div class="flex justify-between">
            <div class="flex items-center gap-2">
              <i class="fa-solid fa-sliders text-green-regular"></i>
              <app-typography variant="p-dark" >{{ 'FIND_THERAPIST.FILTERS.EXPANDED_FILTERS' | translate }}</app-typography>
            </div>
            <i class="fa-solid fa-xmark cursor-pointer" (click)="closePopup()"></i>
          </div>
          <div class="flex flex-wrap gap-2">
            <app-filter-pill *ngFor="let filter of activeFilters$ | async" [label]="filter" (emitClose)="removeFilter(filter)"></app-filter-pill>
          </div>
          <div class="h-[calc(100vh-190px)] md:h-auto overflow-x-hidden overflow-y-auto md:overflow-y-auto">
            <div class="flex flex-col gap-4">
              <app-typography variant="p-sm-dark">{{ 'LABEL.PERSONAL_DETAILS' | translate }}</app-typography>
              <div class="flex flex-col md:flex-row gap-4">
                <div class="flex-1 flex flex-col justify-center">
                  <app-typography variant="p-sm">{{ 'LABEL.AGE_RANGE' | translate }}</app-typography>
                  <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.ageRangeList" formControlName="ageRange">
                    <ng-template ng-label-tmp let-item="item">
                      <div>
                        {{ 'ENUMS.AGE_RANGE.' + item | translate }}
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div>
                        {{ 'ENUMS.AGE_RANGE.' + item | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="flex-1 flex flex-col justify-center">
                  <app-typography variant="p-sm">{{ 'LABEL.ETHNICITY' | translate }}</app-typography>
                  <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.ethnicityList" formControlName="ethnicity">
                    <ng-template ng-label-tmp let-item="item">
                      <div>
                        {{ 'ENUMS.ETHNICITY.' + item | translate }}
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div>
                        {{ 'ENUMS.ETHNICITY.' + item | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="flex flex-col md:flex-row gap-4">
                <div class="flex-1 flex flex-col justify-center">
                  <app-typography variant="p-sm">{{ 'LABEL.GENDER' | translate }}</app-typography>
                  <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.genderList" formControlName="gender">
                    <ng-template ng-label-tmp let-item="item">
                      <div>
                        {{ 'ENUMS.GENDER.' + item | translate }}
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div>
                        {{ 'ENUMS.GENDER.' + item | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="flex-1 flex flex-col justify-center">
                  <app-typography variant="p-sm">{{ 'LABEL.LANGUAGE_SPOKEN' | translate }}</app-typography>
                  <ng-select
                    class="mt-1"
                    [placeholder]="'PLACEHOLDER.SELECT_ALL_THAT_APPLY' | translate"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [searchable]="true"
                    [items]="sharedService.languageList"
                    formControlName="language">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
                        <span class="ng-value-label">{{ 'ENUMS.LANGUAGE.' + item | translate }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)">×</span>
                      </div>
                      <div class="ng-value" *ngIf="items.length > 1">
                        <span class="ng-value-label">{{ items.length - 1 }} {{ 'LABEL.MORE' | translate }}...</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div>
                        {{ 'ENUMS.LANGUAGE.' + item | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="flex flex-col md:flex-row gap-4">
                <div class="flex-1 flex flex-col justify-center">
                  <app-typography variant="p-sm">{{ 'LABEL.RELIGION' | translate }}</app-typography>
                  <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.religionList" formControlName="religion">
                    <ng-template ng-label-tmp let-item="item">
                      <div>
                        {{ 'ENUMS.RELIGION.' + item | translate }}
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div>
                        {{ 'ENUMS.RELIGION.' + item | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="flex-1 flex flex-col justify-center">
                  <app-typography variant="p-sm">{{ 'LABEL.SEXUAL_ORIENTATION' | translate }}</app-typography>
                  <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.sexualOrientationList" formControlName="sexualOrientation">
                    <ng-template ng-label-tmp let-item="item">
                      <div>
                        {{ 'ENUMS.SEXUAL_ORIENTATION.' + item | translate }}
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div>
                        {{ 'ENUMS.SEXUAL_ORIENTATION.' + item | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-4">
              <app-typography variant="p-sm-dark">{{ 'LABEL.PRACTICE_DETAILS' | translate }}</app-typography>
              <div class="flex flex-col md:flex-row gap-4">
                <div class="flex-1 flex flex-col justify-center">
                  <app-typography variant="p-sm">{{ 'LABEL.CLIENT_DEMOGRAPHIC' | translate }}</app-typography>
                  <ng-select
                    class="mt-1"
                    [placeholder]="'PLACEHOLDER.SELECT_ALL_THAT_APPLY' | translate"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [searchable]="true"
                    [items]="sharedService.clientDemographicList"
                    formControlName="clientDemographic">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
                        <span class="ng-value-label">{{ 'ENUMS.CLIENT_DEMOGRAPHIC.' + item | translate }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)">×</span>
                      </div>
                      <div class="ng-value" *ngIf="items.length > 1">
                        <span class="ng-value-label">{{ items.length - 1 }} {{ 'LABEL.MORE' | translate }}...</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div>
                        {{ 'ENUMS.CLIENT_DEMOGRAPHIC.' + item | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="flex-1 flex flex-col justify-center">
                  <app-typography variant="p-sm">{{ 'LABEL.INSURANCE' | translate }}</app-typography>
                  <ng-select
                    class="mt-1"
                    [placeholder]="'PLACEHOLDER.SELECT_ALL_THAT_APPLY' | translate"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [searchable]="true"
                    [items]="sharedService.insuranceBillingList"
                    formControlName="insurance">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
                        <span class="ng-value-label">{{ 'ENUMS.INSURANCE_BILLING.' + item | translate }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)">×</span>
                      </div>
                      <div class="ng-value" *ngIf="items.length > 1">
                        <span class="ng-value-label">{{ items.length - 1 }} {{ 'LABEL.MORE' | translate }}...</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div>
                        {{ 'ENUMS.INSURANCE_BILLING.' + item | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="flex flex-col md:flex-row gap-4">
                <div class="flex-1 flex flex-col justify-center">
                  <app-typography variant="p-sm">{{ 'LABEL.DISTANCE' | translate }}</app-typography>
                  <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.distanceList" formControlName="distance">
                    <ng-template ng-label-tmp let-item="item">
                      <div>
                        {{ 'ENUMS.DISTANCE.' + item | translate }}
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div>
                        {{ 'ENUMS.DISTANCE.' + item | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="flex-1 flex flex-col justify-center"></div>
              </div>
              <div>
                <app-typography variant="p-sm" class="mb-1">{{ 'LABEL.APPOINTMENT_TYPE' | translate }}</app-typography>
                <app-multi-checkbox translateKey="AVAILABILITY_TYPE" [options]="sharedService.availabilityTypeList" formControlName="availabilityType"></app-multi-checkbox>
              </div>
              <div>
                <app-typography variant="p-sm" class="mb-1">{{ 'LABEL.AVAILABILITY_TIMINGS' | translate }}</app-typography>
                <app-multi-checkbox translateKey="TIMINGS" [options]="sharedService.timingList" formControlName="timings"></app-multi-checkbox>
              </div>
              <div>
                <app-typography variant="p-sm" class="mb-1">{{ 'LABEL.APPOINTMENT_DAYS' | translate }}</app-typography>
                <app-multi-checkbox translateKey="DAYS_OF_WEEK" [options]="sharedService.dayAvailableList" formControlName="daysOfWeek"></app-multi-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between gap-4 mt-[20px] md:mt-[64px]">
          <app-button variant="outlined" [size]="userAgentQuery.selectIsMobile() ? 'md' : 'lg'" (click)="closePopup()">{{ 'LABEL.CANCEL' | translate }}</app-button>
          <app-button variant="solid" [size]="userAgentQuery.selectIsMobile() ? 'md' : 'lg'" class="flex-1 w-full" (click)="closePopup()">{{ 'LABEL.SAVE' | translate }}</app-button>
        </div>
      </div>
    </div>
  </div>
</form>
