import { EventEmitter, Injectable } from '@angular/core';
import { Result } from 'src/app/state/search';

@Injectable({ providedIn: 'root' })
export class TherapistSearchEventsService {
  private emitters: Emitters<keyof EventTypes> = {
    itemClick: new EventEmitter(),
    itemDblClick: new EventEmitter(),
  };

  getEmitter<T extends EventTypes, K extends keyof T>(name: K): EventEmitter<T[K]> {
    return (this.emitters as any)[name];
  }

  emit<T extends EventTypes, K extends keyof T>(name: K, payload: T[K]): void {
    const emitter = this.emitters[name as keyof EventTypes];
    emitter.emit(payload as any);
  }
}

interface EventTypes {
  itemClick: Result;
  itemDblClick: Result;
}

type Emitters<K extends keyof EventTypes> = Record<K, EventEmitter<EventTypes[K]>>;
