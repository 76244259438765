import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLEMAPS_LOADER } from 'src/app/core/di/google-maps-loader.token';

@Directive({
  selector: 'input[appGooglePlaces], app-input[appGooglePlaces]'
})
export class GooglePlacesDirective implements AfterViewInit, OnDestroy {

  @Input('appGooglePlaces') options?: google.maps.places.AutocompleteOptions | '';
  @Output('placeChanged') placeChanged = new EventEmitter<google.maps.places.PlaceResult>();

  private autocomplete?: google.maps.places.Autocomplete;
  private listeners = new Set<google.maps.MapsEventListener>();

  constructor(
    @Inject(GOOGLEMAPS_LOADER) private readonly loader: Loader,
    private elementRef: ElementRef<HTMLInputElement>) {}
  
  ngAfterViewInit(): void {
    this.initWidget();
  }

  ngOnDestroy(): void {
    for (const listener of this.listeners) {
      listener.remove();
    }
  }

  private async initWidget(): Promise<void> {
    const { Autocomplete } = await this.loader.importLibrary('places');
    this.autocomplete = new Autocomplete(this.elementRef.nativeElement, {
      fields: ['address_component', 'formatted_address'],
      types: ['geocode'],
      ...this.options
    });
    this.listeners.add(
      this.autocomplete.addListener('place_changed', () => this.handlePlaceChanged())
    );
  }

  private handlePlaceChanged(): void {
    if (!this.autocomplete) {
      return;
    }
    const result = this.autocomplete.getPlace();
    this.placeChanged.emit(result);
  }
}
