import { InjectionToken } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

export const GOOGLEMAPS_LOADER = new InjectionToken<Loader>('GOOGLEMAPS_LOADER', {
  providedIn: 'root',
  factory: () => new Loader({
    apiKey: environment.googleMaps.key,
    libraries: ['places']
  })
});
