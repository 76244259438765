<img id="popoverButton" class="cursor-pointer w-[{{ width }}px] h-[{{ width }}px]" src="/assets/svg/information-line.svg" />
<div
  data-popover
  id="popoverContent"
  role="tooltip"
  class="absolute z-10 invisible inline-block w-[341px] transition-opacity duration-300 bg-grayscale-300 border border-grayscale-500 rounded-2xl shadow-sm backdrop-blur-2xl opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 p-[16px]">
  <div class="flex gap-[8px] border-b border-grayscale-500 pb-[8px]">
    <img class="w-[24px] h-[24px]" src="/assets/svg/information-line.svg" />
    <app-typography variant="p-sm-dark">{{ title }}</app-typography>
  </div>
  <div class="pt-[8px]">
    <app-typography variant="p-sm">{{ content }}</app-typography>
  </div>
  <div data-popper-arrow></div>
</div>
