export interface UserAgent {
  isMobile: boolean;
  navigator: Navigator | null;
}

export function createInitialState(): UserAgent {
  return {
    isMobile: false,
    navigator: null,
  };
}
