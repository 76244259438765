import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Therapist } from '@therappy-inc/models';
import { TherapistStore } from './therapist.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TherapistQuery extends Query<Therapist> {
  constructor(protected override store: TherapistStore) {
    super(store);
  }

  get<K extends keyof Therapist>(key: K): Therapist[K] {
    return this.getValue()[key];
  }

  getTherapistProperty<K extends keyof Therapist>(key: K): Therapist[K] {
    return this.getValue()[key];
  }

  getTherapist(): Therapist {
    return this.getValue();
  }

  selectTherapistProperty<K extends keyof Therapist>(key: K): Observable<Therapist[K]> {
    return this.select(key);
  }

  selectTherapist(): Observable<Therapist> {
    return this.select();
  }
}
