<div class="relative max-w-[800px] bg-grayscale-800 text-grayscale-200 p-4 md:p-8">
  <i class="fa-solid fa-xmark absolute right-8 text-xl cursor-pointer" mat-dialog-close></i>
  <div class="flex gap-3 mb-2">
    <img src="/assets/svg/privacy-policy.svg" />
    <app-typography variant="h4">{{ 'PRIVACY_POLICY_MODAL.TITLE' | translate }}</app-typography>
  </div>
  <app-typography variant="p" class="mb-6">{{ 'PRIVACY_POLICY_MODAL.UPDATED_ON' | translate }}</app-typography>
  <div class="max-h-[400px] md:max-h-[280px] overflow-y-auto">
    <app-typography variant="p-dark" class="mb-2">1. {{ 'PRIVACY_POLICY_MODAL.OVERVIEW' | translate }}</app-typography>
    <app-typography variant="p" class="mb-6"
      >{{ 'PRIVACY_POLICY_MODAL.OVERVIEW_DESCRIPTION' | translate }}</app-typography
    >
    <app-typography variant="p-dark" class="mb-2">2. {{ 'PRIVACY_POLICY_MODAL.CONSENT' | translate }}</app-typography>
    <app-typography variant="p" class="mb-6"
      >{{ 'PRIVACY_POLICY_MODAL.CONSENT_DESCRIPTION' | translate }}</app-typography
    >
    <app-typography variant="p-dark" class="mb-2">3. {{ 'PRIVACY_POLICY_MODAL.OVERVIEW' | translate }}</app-typography>
    <app-typography variant="p" class="mb-6"
      >{{ 'PRIVACY_POLICY_MODAL.OVERVIEW_DESCRIPTION' | translate }}</app-typography
    >
    <app-typography variant="p-dark" class="mb-2">4. {{ 'PRIVACY_POLICY_MODAL.CONSENT' | translate }}</app-typography>
    <app-typography variant="p" class="mb-6"
      >{{ 'PRIVACY_POLICY_MODAL.CONSENT_DESCRIPTION' | translate }}</app-typography
    >
  </div>
  <div class="flex gap-4 mt-6">
    <app-button variant="outlined" [size]="userAgentQuery.selectIsMobile() ? 'md' : 'lg'" class="!text-grayscale-200" mat-dialog-close>{{ 'LABEL.CANCEL' | translate }}</app-button>
    <app-button variant="solid" [size]="userAgentQuery.selectIsMobile() ? 'md' : 'lg'" [mat-dialog-close]="true">{{ 'LABEL.ACCEPT' | translate }}</app-button>
  </div>
</div>
