import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-inline-spinner',
  templateUrl: './inline-spinner.component.html',
  styleUrls: ['./inline-spinner.component.scss'],
})
export class InlineSpinnerComponent {
  @Input() value: number | undefined;

  get mode(): ProgressSpinnerMode {
    return this.value === undefined ? 'indeterminate' : 'determinate';
  }
}
