import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { AuthQuery } from 'src/app/state/auth';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
})
export class MobileNavigationComponent {

  @Input() links: Array<{ name: string; url: string[]; style?: string }> = [];
  @Output() logoutClick = new EventEmitter();


  private readonly authQuery = inject(AuthQuery);
  user$ = this.authQuery.selectCurrentUser();

  showMobileNav = false;

  get year(): number {
    return new Date().getFullYear();
  }
  toggleMobileNav() {
    this.showMobileNav = !this.showMobileNav;
  }
}
