<div class="h-full border rounded-2xl border-green-regular backdrop-blur-md bg-[rgba(31, 49, 25, 0.25)] p-[32px]">
  <img src="/assets/img/icons/flower.png" class="mb-[32px]" />
  <div class="max-w-[400px]">
    <app-typography variant="h4-dark" class="text-grayscale-200">
      <span [innerHTML]="title"></span>
    </app-typography>
    <app-typography variant="p" class="text-grayscale-400">
      <span [innerHTML]="subTitle"></span>
    </app-typography>
  </div>
</div>
