import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALES } from './core/di/locale.token';
import { therAppyLocales } from 'src/config/locales.config';
import { PAGE_TITLE } from './core/di/page-title.token';
import { APP_TITLE } from 'src/config/constants';
import { CoreModule } from './core/core.module';
import { StaticPagesModule } from './static-pages/static-pages.module';
import { StateModule } from './state/state.module';
import { PERSIST_STATE } from './core/di/persist-state.token';
import { persistenceConfig, sessionPersistenceConfig } from 'src/config/persist-state.config';
import { ExperimentationModule } from './experimentation/experimentation.module';
import { SVG_ICONS } from './core/di/svg-icons.token';
import { SVG_MANIFEST } from 'src/config/svg-icons.manifest';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    StateModule,
    StaticPagesModule,
    ExperimentationModule
  ],
  providers: [
    { provide: LOCALES, useValue: therAppyLocales },
    { provide: PAGE_TITLE, useValue: APP_TITLE },
    { provide: PERSIST_STATE, useValue: persistenceConfig, multi: true },
    { provide: PERSIST_STATE, useValue: sessionPersistenceConfig, multi: true },
    { provide: SVG_ICONS, useValue: SVG_MANIFEST },
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
