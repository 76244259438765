import { Keys } from '@therappy-inc/models';
import JSONCrush from 'jsoncrush';
import { pick } from 'lodash';

export function serializeObject<T>(state: T): string {
  const strValue = JSON.stringify(state);
  const encoded = encodeURIComponent(strValue);
  return JSONCrush.crush(encoded);
}

export function deserializeObject<T>(value: string, whitelistKeys?: Keys<T>): T {
  const uncrushed = JSONCrush.uncrush(value);
  const decoded = decodeURIComponent(uncrushed);
  const data = JSON.parse(decoded);
  return whitelistKeys ? pick(data, whitelistKeys) : data;
}
