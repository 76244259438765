import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { filterFalsey } from 'src/app/core/utils/filter-falsey.operator';
import { LocalState } from './local-state.model';
import { LocalStateQuery } from './local-state.query';
import { LocalStateStore } from './local-state.store';

@Injectable({ providedIn: 'root' })
export class LocalStateService {
  private readonly routerQuery = inject(RouterQuery);
  private readonly router = inject(Router);
  private readonly query = inject(LocalStateQuery);
  private readonly store = inject(LocalStateStore);

  constructor() {
    this.trackLocaleURL().subscribe();
  }

  switchLocale(locale: string): void {
    const url = this.routerQuery.getValue().state?.url;
    if (url) {
      const currentLang = this.query.get('locale');
      if (url.match(currentLang)) {
        const newUrl = url.replace(currentLang, locale);
        this.router.navigateByUrl(newUrl, { replaceUrl: true });
      } else {
        this.update({ locale });
      }
    }
  }

  update(partial: Partial<LocalState>): void {
    this.store.update(state => ({ ...state, ...partial }));
  }

  reset(): void {
    this.store.reset();
  }

  private trackLocaleURL(): Observable<string> {
    return this.routerQuery.selectParams<string | undefined>('locale').pipe(
      filterFalsey(),
      filter(locale => this.query.getLocaleExists(locale)),
      tap(locale => this.update({ locale })),
    );
  }
}
