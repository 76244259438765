import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Subject } from 'rxjs';
import { AuthActionType, CustomClaims } from './auth.model';
import { User } from '@therappy-inc/models';
import { FireAuthState, RoleState, initialAuthState } from '../lib/auth/fire-auth.model';
import { AuthError } from '@angular/fire/auth';

export interface AuthState extends FireAuthState<User>, RoleState<Partial<CustomClaims>> {
  error: AuthError;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  
  authAction$ = new Subject<AuthActionType>();

  constructor() {
    super(initialAuthState);
  }
}
