import { Injectable } from '@angular/core';
import { AuthState, AuthStore } from './auth.store';
import { User as FirebaseUser, UserCredential } from 'firebase/auth';
import { AuthActionType, AuthForm, createUser, SignupForm } from './auth.model';
import { EMPTY, Observable, catchError, from } from 'rxjs';
import { FireAuthService } from '../lib/auth/fire-auth.service';
import { CollectionConfig } from '../lib/collection/fire-collection.config';
import { FireProvider } from '../lib/auth/fire-auth.utils';
import { User } from '@therappy-inc/models';
import { sendPasswordResetEmail } from '@angular/fire/auth';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'users' })
export class AuthService extends FireAuthService<AuthState> {

  constructor(protected override store: AuthStore) {
    super(store);
  }

  override createProfile(user: FirebaseUser, ctx?: Partial<SignupForm>): AuthState['profile'] {
    return createUser(user, ctx);
  }

  signinEmail({ email, password }: AuthForm): void {
    this.clearError();
    this.signin(email || '', password || '').catch(error => this.store.setError(error));
  }

  reauthenticateWithEmail(email: string, password: string): void {
    this.clearError();
    this.reauthenticateWithCredential(email || '', password || '').catch(error => this.store.setError(error));
  }

  signinProvider(provider: FireProvider): void {
    this.signin(provider).catch(error => this.store.setError(error));
  }

  signupEmail({ email, password, name }: SignupForm): void {
    this.clearError();
    this.signup(email, password, { ctx: { name } }).catch(error => this.store.setError(error));
  }

  sendPasswordResetEmail(email: string): Observable<void> {
    this.clearError();
    return from(sendPasswordResetEmail(this.auth, email)).pipe(
      catchError(err => this.setError(err))
    );
  }

  override onSignup(cred: UserCredential): void {
    this.store.authAction$.next(AuthActionType.Signup);
    this.clearError();
  }

  override onSignin(user: UserCredential, profile: User): void {
    this.store.authAction$.next(AuthActionType.Signin);
    this.clearError();
  }

  override onReauthenticate(user: UserCredential): void {
    this.store.authAction$.next(AuthActionType.Reauthenticate);
    this.clearError();
  }

  override onSignout(): void {
    this.store.authAction$.next(AuthActionType.Signout);
    this.clearError();
  }

  override onDelete(): void {
    this.signOut();
  }

  clearError(): void {
    this.store.setError(null);
  }

  setError(error: any): Observable<void> {
    this.store.setError(error);
    return EMPTY;
  }

}
