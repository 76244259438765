<ng-container [ngSwitch]="background">
  <ng-container *ngSwitchCase="'onboarding'">
    <div class="min-h-screen md:min-h-[calc(100vh-108px)] bg-[url('/assets/img/leaf-top-left.png'),url('/assets/img/leaf-bottom-right.png')] bg-no-repeat [background-position:top_left,bottom_right]">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'startup'">
    <div class="relative flex pt-[80px] pb-[24px] md:pt-[126px] md:pb-[32px] justify-center items-center min-h-screen md:min-h-[calc(100vh-108px)] bg-[url('/assets/img/leaf-top-left-bg.png')] bg-grayscale-400 bg-no-repeat bg-cover [background-position:top_left_-120px,bottom_right] md:bg-auto md:[background-position:top_left,bottom_right] mac:bg-full">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
      <div class="absolute bottom-0 right-0 z-10 w-[267px] h-[242px] bg-[url('/assets/img/leaf-bottom-right.png')]"></div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
