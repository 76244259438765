import { Store, guid } from '@datorama/akita';
import { FilterState, createInitialFilterState } from './filters.model';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'any' })
export class FilterStore extends Store<FilterState> {
  constructor() {
    super(createInitialFilterState(), { name: `filter-${guid()}`, resettable: true });
  }
}
