import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-video-uploader',
  templateUrl: './video-uploader.component.html',
  styleUrls: ['./video-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VideoUploaderComponent),
      multi: true,
    },
  ],
})
export class VideoUploaderComponent implements ControlValueAccessor {
  @Input() videoURL = '';

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(val: string) {
    if (val) {
      this.videoURL = val;
    }
  }

  onVideoSelected(pFileList: File[]) {
    if (pFileList && pFileList.length) {
      const files: File[] = Object.keys(pFileList).map((key: any) => pFileList[key]);
      const file: File = files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = event => {
          // called once readAsDataURL is completed
          this.videoURL = event.target?.result as string;
          this.onChange(this.videoURL);
        };
      }
    }
  }
}
