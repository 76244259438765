<header
  class="relative h-auto md:min-h-[120px] flex flex-col justify-center"
  [class.relative]="!onlyNav"
  [class.absolute]="onlyNav"
  [class.left-0]="onlyNav"
  [class.right-0]="onlyNav"
  [class.top-0]="onlyNav"
  [class.z-50]="onlyNav"
>
  <div
    class="relative z-[50] mt-2 mb-2 md:mt-5 px-[16px] md:px-[80px]"
    [class.2xl:h-[580px]]="isHome"
    [class.3xl:h-[906px]]="isHome"
  >
    <div class="flex justify-between items-center">
      <a [routerLink]="['/'] | localizedLink">
        <img *ngIf="isHome || onlyNav" src="/assets/svg/logo/black-outline.svg" class="h-12 w-11 md:h-16 md:w-14" />
        <img *ngIf="!isHome && !onlyNav" src="/assets/svg/logo/white-outline.svg" class="h-12 w-11 md:h-16 md:w-14" />
      </a>
      <nav class="sm hidden md:flex lg:flex">
        <ul class="w-full flex justify-end items-center gap-10">
          <li *ngFor="let link of links">
            <a
              *ngIf="!onlyNav && link['style'] !== 'button'"
              class="text-grayscale-200 uppercase text-base font-montserrat font-normal text-nowrap relative"
              [routerLink]="link['url'] | localizedLink"
              routerLinkActive="after:content[''] after:h-0.5 after:w-6 after:bg-grayscale-200 after:absolute after:top-6 after:left-1/2 after:-translate-x-1/2"
              style="white-space: nowrap;"
            >
              {{ link['name'] }}
            </a>
            <a
              *ngIf="onlyNav && link['style'] !== 'button'"
              class="text-grayscale-900 uppercase text-base font-montserrat font-normal text-nowrap relative"
              [routerLink]="link['url'] | localizedLink"
              routerLinkActive="after:content[''] after:h-0.5 after:w-6 after:bg-grayscale-900 after:absolute after:top-6 after:left-1/2 after:-translate-x-1/2"
              style="white-space: nowrap;"
            >
              {{ link['name'] }}
            </a>
            <app-button
              *ngIf="link['style'] === 'button'"
              size="lg"
              variant="solid"
              [url]="link['url'][0]"
              class="capitalize font-normal text-nowrap"
              style="white-space: nowrap;"
            >
              {{ link['name'] }}
            </app-button>
          </li>
          <li *ngIf="user$ | async as user">
            <app-button [matMenuTriggerFor]="menu" size="lg" variant="solid" class="capitalize !px-3 font-normal">
              <div class="flex gap-[8px]">
                <img src="/assets/img/icons/menu-fold-line.png" />
                <span class="flex items-center justify-center w-[32px] h-[32px] bg-[#fefefe3d] rounded-full text-[12px] uppercase">{{ user.name | initial }}</span>
              </div>
            </app-button>
            <mat-menu #menu="matMenu" xPosition="before">
              <div class="w-[228px] bg-grayscale-200 rounded-lg px-[16px] py-[8px]">
                <div *ngIf="user.therapistId">
                  <app-typography variant="p-sm-dark" class="!text-[12px] text-grayscale-600">{{ 'HEADER.THERAPIST_PORTAL' | translate }}</app-typography>
                  <button mat-menu-item class="!min-h-[40px] !p-0" [routerLink]="['therapist-dashboard'] | localizedLink">
                    <div class="flex gap-2">
                      <img src="/assets/img/icons/dashboard-line-black.png" />
                      <app-typography variant="p-sm">{{ 'HEADER.MY_DASHBOARD' | translate }}</app-typography>
                    </div>
                  </button>
                  <div class="w-full h-px bg-grayscale-400 mt-[8px] mb-[16px]"></div>
                </div>
                <div *ngIf="user.patientId">
                  <app-typography variant="p-sm-dark" class="!text-[12px] text-grayscale-600">{{ 'LABEL.THERAPPY_SEEKER' | translate }}</app-typography>
                  <button mat-menu-item class="!min-h-[40px] !p-0" [routerLink]="['find-therapist'] | localizedLink" [queryParams]="{ isFavorite: true }">
                    <div class="flex gap-2">
                      <img src="/assets/img/icons/star-line.png" />
                      <app-typography variant="p-sm">{{ 'HEADER.MY_FAVORITES' | translate }}</app-typography>
                    </div>
                  </button>
                  <button mat-menu-item class="!min-h-[40px] !p-0" [routerLink]="['patient-profile'] | localizedLink">
                    <div class="flex gap-2">
                      <img src="/assets/img/icons/user-4-line.png" />
                      <app-typography variant="p-sm">{{ 'HEADER.MY_PROFILE' | translate }}</app-typography>
                    </div>
                  </button>
                  <div class="w-full h-px bg-grayscale-400 my-[8px]"></div>
                </div>
                <button mat-menu-item class="!min-h-[40px] !p-0" (click)="logout()">
                  <div class="flex gap-2">
                    <img src="/assets/img/icons/logout-circle-r-line.png" />
                    <app-typography variant="p-sm">{{ 'HEADER.LOGOUT' | translate }}</app-typography>
                  </div>
                </button>
              </div>
            </mat-menu>
          </li>
        </ul>
      </nav>
      <app-mobile-navigation class="lg:hidden md:hidden" [links]="links" (logoutClick)="logout()"></app-mobile-navigation>

    </div>
    <div
      *ngIf="!onlyNav && (title || subTitle)"
      class="flex justify-between"
      [class.mt-2]="!isHome"
      [class.md:mt-5]="!isHome"
      [class.mt-[264px]]="isHome"
      [class.md:mt-[124px]]="isHome"
      [class.3xl:mt-[224px]]="isHome"
    >
      <div class="flex-col">
        <ng-container *ngIf="isHome; else default">
          <app-typography variant="h1-dark" class="text-grayscale-900 max-w-[800px]">
            <p [innerHTML]="titleHeadlineOne"></p>
            <p [innerHTML]="titleHeadlineTwo"></p>
          </app-typography>
          <app-typography variant="p" class="text-grayscale-900 mt-[24px]">
            <span [innerHTML]="subTitle"></span>
          </app-typography>
          <app-launchlist-widget keyId="zXITMC" height="200px"></app-launchlist-widget>
        </ng-container>
        <ng-template #default>
          <app-typography variant="h3-dark" class="text-grayscale-200">
            <span [innerHTML]="title"></span>
          </app-typography>
          <app-typography variant="p" class="text-grayscale-200">
            <span [innerHTML]="subTitle"></span>
          </app-typography>
        </ng-template>
        <ng-content select="[leftBottom]"></ng-content>
      </div>
      <ng-content select="[rightBottom]"></ng-content>
    </div>
  </div>

  <div
    *ngIf="!onlyNav && isHome"
    class="bg-[url('/assets/img/home-header-leaf.png')] bg-cover bg-no-repeat bg-right absolute top-0 h-[300px] md:h-full w-full bg-[rgba(0,0,0,0.6)] z-[1]"
  >
</div>
  <ng-container *ngIf="!onlyNav && !isHome">
    <div class="bg-[url('/assets/img/header-leaf.png')] bg-cover bg-no-repeat bg-top absolute top-0 left-0 h-full w-full bg-[rgba(0,0,0,0.6)] z-[1]"></div>
    <div class="absolute top-0 left-0 h-full w-full bg-[rgba(0,0,0,0.6)] z-[2]"></div>
  </ng-container>
</header>
