import { Component, inject } from '@angular/core';
import { ResultsQuery } from 'src/app/state/search';

@Component({
  selector: 'app-results-count',
  templateUrl: './results-count.component.html',
  styleUrls: ['./results-count.component.scss'],
})
export class ResultsCountComponent {
  private readonly resultsQuery = inject(ResultsQuery);
  resultsCount$ = this.resultsQuery.selectResultsCount();
  isLoading$ = this.resultsQuery.selectLoading();
}
