import { UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'translateArray',
})
export class TranslateArrayPipe implements PipeTransform {
  constructor(
    private upperCasePipe: UpperCasePipe,
    private translate: TranslateService,
  ) {}

  transform(value: string[] | undefined, prefix: string): Observable<string[]> {
    if (!value) {
      return of([]);
    }
    if (value.length === 0) {
      return of([]);
    }
    if (!Array.isArray(value)) {
      value = [value];
    }

    const prefixed = value.map(v => (prefix ? `${prefix}.${v}` : v));
    const upper = prefixed.map(key => this.upperCasePipe.transform(key));
    return this.translate.stream(upper).pipe(map<object, string[]>(obj => Object.values(obj)));
  }
}
