<div class="flex justify-between mb-[30px]" [formGroup]="form">
  <div class="flex gap-2">
    <app-button
      variant="solid"
      [size]="(userAgentQuery.selectIsMobile() | async) ? 'sm' : 'md'"
      class="w-[87px] md:w-auto"
      (click)="togglePanel()"
      >
        {{ 'LABEL.FILTERS' | translate }}
        <i class="fa-solid fa-sliders ml-1 md:ml-2"></i>
    </app-button>
    
    <div>
      <div>
        <div class="w-[calc(100vw-120px)] md:w-auto overflow-x-auto md:overflow-hidden flex gap-2 mb-2">
          <div class="min-w-[160px] w-[160px]">
            <ng-select
              placeholder="Concerns"
              [multiple]="true"
              [closeOnSelect]="false"
              [searchable]="true"
              appendTo="body"
              [items]="sharedService.specialityList"
              formControlName="specialities">
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
                  <span class="ng-value-label">{{ 'ENUMS.SPECIALITIES.' + item | translate }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)">×</span>
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label">{{ items.length - 1 }} {{ 'LABEL.MORE' | translate }}...</span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div>
                  {{ 'ENUMS.SPECIALITIES.' + item | translate }}
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="min-w-[160px] w-[160px]">
            <ng-select placeholder="Designation" [searchable]="true" appendTo="body" [items]="sharedService.designationList" formControlName="designation">
              <ng-template ng-label-tmp let-item="item">
                <div>
                  {{ 'ENUMS.DESIGNATION.' + item | translate }}
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div>
                  {{ 'ENUMS.DESIGNATION.' + item | translate }}
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="min-w-[160px] w-[160px]">
            <ng-select
              placeholder="Type of Therapy"
              [multiple]="true"
              [closeOnSelect]="false"
              [searchable]="true"
              appendTo="body"
              [items]="sharedService.typeOfTherapyList"
              formControlName="typeOfTherapy">
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
                  <span class="ng-value-label">{{ 'ENUMS.TYPES_OF_THERAPY.' + item | translate }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)">×</span>
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label">{{ items.length - 1 }} {{ 'LABEL.MORE' | translate }}...</span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div>
                  {{ 'ENUMS.TYPES_OF_THERAPY.' + item | translate }}
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="min-w-[194px] w-[194px]">
            <div
              [matMenuTriggerFor]="menu"
              (menuOpened)="togglePriceRangeMenu()"
              (menuClosed)="togglePriceRangeMenu()"
              class="flex items-center justify-between bg-grayscale-200 rounded-lg border border-grayscale-400 p-[7px]">
              <app-typography variant="p-sm">{{ 'FIND_THERAPIST.FILTERS.PRICE_RANGE' | translate }}</app-typography>
              <i
                *appLet="ui$ | async as ui"
                class="fa-solid fa-angle-up"
                [ngClass]="{
                  'fa-angle-up': ui?.priceRangeOpen,
                  'fa-angle-down': !ui?.priceRangeOpen
                }"></i>
            </div>
            <mat-menu #menu="matMenu" xPosition="before">
              <div class="w-[194px] bg-grayscale-200 rounded-lg px-[16px] py-[8px]" (click)="$event.stopPropagation()">
                <div class="flex">
                  <app-typography variant="p-sm-dark" class="!text-[12px]" [class.text-green-regular]="!form.controls.isPriceRangeForCouple.value">{{ 'FIND_THERAPIST.FILTERS.INDIVIDUAL' | translate }}</app-typography>
                  <label class="relative inline-flex items-center cursor-pointer mx-2">
                    <input type="checkbox" value="" class="sr-only peer" />
                    <div
                      class="w-5 h-3.5 bg-green-regular peer-focus:outline-none peer-focus:ring-2 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[7px] after:left-[3.5px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-1.5 after:w-1.5 after:transition-all dark:border-gray-600 peer-checked:bg-green-regular"></div>
                  </label>
                  <app-typography variant="p-sm-dark" class="!text-[12px]" [class.text-green-regular]="form.controls.isPriceRangeForCouple.value">{{ 'FIND_THERAPIST.FILTERS.COUPLE' | translate }}</app-typography>
                </div>
                <mat-slider min="0" max="100" discrete [displayWith]="formatLabel" class="!w-[95%] !mt-[10px] !mx-[0px]">
                  <input matSliderStartThumb />
                  <input matSliderEndThumb />
                </mat-slider>
              </div>
            </mat-menu>
          </div>
          <app-button
            *ngIf="true"
            variant="solid"
            [size]="(userAgentQuery.selectIsMobile() | async) ? 'sm' : 'md'"
            [disabled]="disableClear$ | async"
            class="min-w-[100px] rounded-lg !bg-grayscale-300 !text-green-regular"
            (click)="resetFilters()">
            {{ 'FIND_THERAPIST.FILTERS.CLEAR_ALL' | translate }}
            <i class="fa-solid fa-xmark ml-1 md:ml-2"></i>
          </app-button>
        </div>
        <app-filter-chips></app-filter-chips>
      </div>
    </div>
  </div>
</div>
