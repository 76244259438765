import { Observable } from 'rxjs';
import { StaticContent } from '../services/static-content.services';
import { inject, InjectionToken } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

export const STATIC_CONTENT = new InjectionToken<StaticContentChanges>('STATIC_CONTENT', {
  factory: () => {
    const routerQuery = inject(RouterQuery);
    return routerQuery.selectData<StaticContent>('static');
  },
});

export type StaticContentChanges<T = Record<string, never>> = Observable<StaticContent<T>>;
