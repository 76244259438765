import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, ResolveFn } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StaticContent, StaticContentService } from '../services/static-content.services';

/**
 * This resolver is used to inject static content
 * (JSON loaded by the `StaticContentService`) into
 * the navigation context for use in the components.
 */
export const staticContentResolver: ResolveFn<StaticContent> = (route: ActivatedRouteSnapshot) => {
  const staticContentService = inject(StaticContentService);
  const router = inject(Router);
  const path = route.data['staticContentPath'];
  const locale = route.paramMap.get('locale');
  return staticContentService.select(path, locale).pipe(catchError(err => handleError(err, router)));
};

const handleError = (err: unknown, router: Router) => {
  router.navigate(['/']);
  return EMPTY;
};
