import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { IBreadCrumb } from './breadcrumb.interface';
import { filter, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  public breadcrumbs: IBreadCrumb[];

  constructor() {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event: Event) => {
          return event instanceof NavigationEnd;
        }),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
      });
    // this.routerQuery.selectData().subscribe(route => {
    // });
  }

  /**
   * Recursively build breadcrumb according to activated route.
   * @param route
   * @param url
   * @param breadcrumbs
   */
  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data['breadcrumb'] : '';
    let urls = route.routeConfig && route.routeConfig.data && route.routeConfig.data['urls'];
    let isClickable = route.routeConfig && route.routeConfig.data && route.routeConfig.data['isClickable'];
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path?.split('/').pop();
    if (lastRoutePart) {
      const isDynamicRoute = lastRoutePart.startsWith(':');
      if (isDynamicRoute && !!route.snapshot) {
        const paramName = lastRoutePart.split(':')[1];
        path = path?.replace(lastRoutePart, route.snapshot.params[paramName]);
        label = route.snapshot.params[paramName];
      }
    }

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;
    // const rootUrlSegments = this.router.url.split('/');
    // rootUrlSegments.pop();
    // let rootUrl;
    // if (rootUrlSegments?.length) {
    //   rootUrl = rootUrlSegments.join('/');
    // }
    // const breadcrumb: IBreadCrumb = {
    //     label: label,
    //     url: nextUrl ? rootUrl + nextUrl : nextUrl,
    // };
    if (!urls) {
      urls = nextUrl.split('/');
      urls.shift();
    }
    const breadcrumb: IBreadCrumb = {
      label,
      url: nextUrl,
      urls,
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}
