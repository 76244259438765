import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserAgentStore } from './user-agent.store';
import { NAVIGATOR } from '@ng-web-apis/common';

@Injectable({ providedIn: 'root' })
export class UserAgentService {
  constructor(
    @Inject(NAVIGATOR) private navigator: Navigator,
    private store: UserAgentStore,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.setNavigator();
    this.trackBreakpoints().subscribe();
  }

  private setNavigator(): void {
    this.store.update({ navigator: this.navigator });
  }

  private trackBreakpoints(): Observable<boolean> {
    return this.breakpointObserver.observe([Breakpoints.Handset]).pipe(
      map(state => state.matches),
      tap(isMobile => this.store.update({ isMobile })),
    );
  }
}
