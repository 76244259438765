import { Component, Input } from '@angular/core';
import { Popover } from 'flowbite';
import type { PopoverOptions, PopoverInterface } from 'flowbite';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
})
export class InfoPopoverComponent {
  @Input() title = '';
  @Input() content = '';
  @Input() width = '24';

  // set the popover content element
  $targetEl: HTMLElement | null = null;
  // set the element that trigger the popover using hover or click
  $triggerEl: HTMLElement | null = null;

  // options with default values
  options: PopoverOptions = {
    placement: 'left',
    triggerType: 'hover',
    offset: 10,
    onHide: () => {
      console.log('popover is shown');
    },
    onShow: () => {
      console.log('popover is hidden');
    },
    onToggle: () => {
      console.log('popover is toggled');
    },
  };
  popover!: PopoverInterface;

  ngAfterViewInit() {
    this.$targetEl = document.getElementById('popoverContent');
    this.$triggerEl = document.getElementById('popoverButton');

    if (this.$targetEl) {
      /*
       * targetEl: required
       * triggerEl: required
       * options: optional
       */
      new Popover(this.$targetEl, this.$triggerEl, this.options);
    }
  }
}
