import {
  AgeRange,
  AvailabilityType,
  ClientDemographic,
  DaysOfWeek,
  Designation,
  Distance,
  Ethnicity,
  Gender,
  InsuranceBilling,
  Language,
  Religion,
  SexualOrientation,
  Specialities,
  Timings,
  TypesOfTherapy,
} from '@therappy-inc/models';
import { FilterPropType, filterActiveKeys } from 'src/app/shared/utils/algolia.utils';

export interface TherapistFilterProps extends Record<string, FilterPropType> {
  specialities: Specialities[];
  designation: Designation | null;
  typeOfTherapy: TypesOfTherapy[];
  isPriceRangeForCouple: boolean;
  minPrice: number;
  maxPrice: number;
  ageRange: AgeRange | null;
  ethnicity: Ethnicity[];
  gender: Gender[];
  language: Language[];
  religion: Religion[];
  sexualOrientation: SexualOrientation[];
  clientDemographic: ClientDemographic[];
  insurance: InsuranceBilling[];
  distance: Distance | null;
  availabilityType: AvailabilityType[];
  daysOfWeek: DaysOfWeek[];
  timings: Timings[];
  freeConsultation: boolean;
  lgbtqAdvocate: boolean;
}

export enum SortDirection {
  ASC,
  DSC,
}

export interface FilterUi {
  panelOpen: boolean;
  priceRangeOpen: boolean;
}

export interface FilterState {
  filters: TherapistFilterProps;
  sortBy: 'price' | 'relevance';
  sortOrder: SortDirection.ASC;
  ui: FilterUi;
}

export function createInitialFilterState(): FilterState {
  return {
    filters: {
      specialities: [],
      designation: null,
      typeOfTherapy: [],
      isPriceRangeForCouple: false,
      minPrice: 0,
      maxPrice: Infinity,
      ageRange: null,
      ethnicity: [],
      gender: [],
      language: [],
      religion: [],
      sexualOrientation: [],
      clientDemographic: [],
      insurance: [],
      distance: null,
      availabilityType: [],
      daysOfWeek: [],
      timings: [],
      freeConsultation: false,
      lgbtqAdvocate: false,
    },
    sortBy: 'relevance',
    sortOrder: SortDirection.ASC,
    ui: {
      panelOpen: false,
      priceRangeOpen: false,
    },
  };
}


export function getMinimalState({ filters, sortBy, sortOrder }: FilterState) {
  return {
    filters: filterActiveKeys(filters),
    sortBy,
    sortOrder
  };
}


