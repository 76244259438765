import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeModule } from './home/home.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { LayoutModule } from '../core/layout/layout.module';
import { PlaygroundModule } from './playground/playground.module';

@NgModule({
  declarations: [PageNotFoundComponent, AboutUsComponent, PrivacyPolicyComponent, TermsConditionsComponent],
  imports: [PlaygroundModule, HomeModule, CommonModule, SharedModule, LayoutModule],
})
export class StaticPagesModule {}
