import { Pipe, PipeTransform } from '@angular/core';
import { OrNil } from '../types/utility.types';

@Pipe({
  name: 'simplePercent',
})
export class SimplePercentPipe implements PipeTransform {
  transform(value: OrNil<number>): string {
    if (value === null || typeof value === 'undefined') {
      return '';
    }

    return `${value} %`;
  }
}
