import { persistState } from '@datorama/akita';
import { STORAGE_KEY } from './constants';

export const persistenceConfig = persistState({
  key: STORAGE_KEY,
  include: ['auth', 'localState'],
});

export const sessionPersistenceConfig = persistState({
  key: `${STORAGE_KEY}-session`,
  storage: sessionStorage,
  include: ['patient-startup']
});
