import { Component, OnInit, Output, inject } from '@angular/core';
import { FilterQuery, FilterService, FilterStore, ResultsQuery, ResultsService, ResultsStore } from 'src/app/state/search';
import { TherapistSearchActionsService } from './therapist-search-actions.service';
import { TherapistSearchEventsService } from './therapist-search-events.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-therapist-search',
  templateUrl: './therapist-search.component.html',
  styleUrls: ['./therapist-search.component.scss'],
  providers: [FilterStore, FilterService, FilterQuery, ResultsStore, ResultsService, ResultsQuery, TherapistSearchActionsService, TherapistSearchEventsService],
})
export class TherapistSearchComponent implements OnInit {
  private readonly eventsService = inject(TherapistSearchEventsService);
  private readonly filterService = inject(FilterService);
  private readonly resultsService = inject(ResultsService);
  private readonly resultsQuery = inject(ResultsQuery);

  @Output() itemClick = this.eventsService.getEmitter('itemClick');
  @Output() itemDblClick = this.eventsService.getEmitter('itemDblClick');
  @Output() selectionChange = this.resultsQuery.selectSelectedResults();
  @Output() favoriteChange = this.resultsQuery.selectFavoritedResults();

  ngOnInit(): void {
    this.filterService.trackURL().pipe(untilDestroyed(this)).subscribe();
    this.resultsService.sync().pipe(untilDestroyed(this)).subscribe();
  }
}
