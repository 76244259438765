import { Injectable, inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LocalizedLinkPipe } from 'src/app/shared/pipes/localized-link.pipe';

@Injectable({
  providedIn: 'root',
})
export class LocalizedRouterService {
  private readonly router = inject(Router);
  private readonly localizedLinkPipe = inject(LocalizedLinkPipe);

  navigate(command: string | string[], extras?: NavigationExtras): void {
    const withLocale = this.localizedLinkPipe.transform(command);
    this.router.navigate(withLocale, extras);
  }
}
