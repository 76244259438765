import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from '@angular/fire/auth';

export const authProviders = ['google', 'microsoft', 'facebook', 'email', 'apple'] as const;

export type FireProvider = (typeof authProviders)[number];

/** Verify if provider is part of the list of Authentication provider provided by Firebase Auth */
export function isFireAuthProvider(provider: any): provider is FireProvider {
  return typeof provider === 'string' && authProviders.includes(provider as any);
}

/**
 * Get the Authentication Provider based on its name
 * @param provider string literal representing the name of the provider
 */
export function getAuthProvider(provider: FireProvider) {
  switch (provider) {
    case 'email':
      return new EmailAuthProvider();
    case 'facebook':
      return new FacebookAuthProvider();
    case 'google':
      return new GoogleAuthProvider();
    case 'microsoft':
      return new OAuthProvider('microsoft.com');
    case 'apple':
      return new OAuthProvider('apple.com');
  }
}
