<span class="flex items-center">
  <input
    type="checkbox"
    class="cursor-pointer appearance-none bg-check-mark bg-no-repeat bg-center bg-[length:0.55em_0.55em] w-5 h-5 text-green-600 border border-grayscale-900 checked:border-none checked:bg-green-regular checked:ring-green-regular checked:ring-2"
    [formControl]="control"
  />
  <div class="ml-2">
    <ng-content></ng-content>
  </div>
</span>

