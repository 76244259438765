import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStateQuery } from 'src/app/state/local';

/**
 * This guard ensures the router will always
 * add a locale (default or user preference) at the beginning of
 * the URL tree.
 */
export const defaultLanguageGuard: CanActivateFn = () => {
  const router = inject(Router);
  const localStateQuery = inject(LocalStateQuery);
  const locale = localStateQuery.get('locale');
  return router.createUrlTree([locale]);
};
