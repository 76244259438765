import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-photo-uploader',
  templateUrl: './photo-uploader.component.html',
  styleUrls: ['./photo-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhotoUploaderComponent),
      multi: true,
    },
  ],
})
export class PhotoUploaderComponent implements ControlValueAccessor {
  @Input() photoURL = '';

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(val: string) {
    if (val) {
      this.photoURL = val;
    }
  }

  onPhotoSelected(pFileList: File[]) {
    if (pFileList && pFileList.length) {
      const files: File[] = Object.keys(pFileList).map((key: any) => pFileList[key]);
      const file: File = files[0];
      if (file) {
        // const fileName = file.name;
        // const formData = new FormData();
        // formData.append("thumbnail", file);
        // const upload$ = this.http.post("/api/thumbnail-upload", formData);
        // upload$.subscribe();
        const reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = event => {
          // called once readAsDataURL is completed
          this.photoURL = event.target?.result as string;
          this.onChange(this.photoURL);
        };
      }
    }
  }
}
