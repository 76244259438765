<div class="fixed top-7 md:top-14 right-8 flex lg:hidden">
  <app-button size="sm" (click)="toggleMobileNav()"><i class="fa-solid fa-bars"></i></app-button>
</div>

<div *ngIf="showMobileNav" class="w-4/5 flex flex-col justify-evenly max-w-[320px] bg-darkGreen fixed top-0 right-0 z-[2] h-screen rounded-tl lg:hidden">
  <div class="fixed top-7 md:top-14 right-8">
    <app-button size="sm" (click)="toggleMobileNav()"><i class="fa-solid fa-xmark"></i></app-button>
  </div>
  <div class="h-[10%] mt-7 md:mt-14 px-5">
    <app-typography variant="p" class="uppercase text-green-regular">{{ 'LABEL.MENU' | translate }}</app-typography>
    <div class="h-[1px] w-full bg-[#093C26] mt-6"></div>
  </div>
  <!-- Navigation Links -->
  <nav class="h-3/5 flex flex-col justify-between px-5 pb-5">
    <ul class="flex flex-col">
      <li *ngFor="let link of links" class="mb-4">
        <a *ngIf="link['style'] !== 'button'" class="text-grayscale-200 uppercase text-base font-montserrat font-medium relative" [routerLink]="link['url'] | localizedLink">{{ link['name'] }}</a>
      </li>
    </ul>
  </nav>

  <div class="h-[10%] flex flex-col justify-evenly text-grayscale-200 bg-green-dark">
    <div class="h-4/5 px-5 flex flex-col justify-evenly">
      <div class="h-1/4 flex items-center text-3xl">
        <a href="https://tiktok.com/@therappyinc">
          <i class="fa-brands fa-tiktok mr-6"></i>
        </a>
        <a href="https://www.instagram.com/therappyinc/">
          <i class="fa-brands fa-instagram mr-6"></i>
        </a>
        <a href="https://www.linkedin.com/company/therappy-inc/">
          <i class="fa-brands fa-linkedin mr-6"></i>
        </a>
      </div>
    </div>

    <div class="h-1/5 flex justify-center items-center bg-[#2A3727] w-full">
      <app-typography variant="p-sm" class="text-center text-grayscale-200">
        <span [innerHTML]="'LAYOUT.FOOTER.COPYRIGHT' | translate: { year }"></span>
      </app-typography>
    </div>
  </div>
</div>

<div *ngIf="showMobileNav" (click)="toggleMobileNav()" class="fixed top-0 left-0 h-screen w-screen bg-black opacity-70 z-[1] lg:hidden"></div>
