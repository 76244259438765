import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import aloglia, { SearchIndex } from 'algoliasearch/lite';
import { SearchResponse, SearchOptions } from '@algolia/client-search';
import { Observable, from } from 'rxjs';
import { TherapistIndexRecord } from '@therappy-inc/models';

export interface Indices {
  therapist: TherapistIndexRecord;
}

@Injectable({ providedIn: 'root' })
export class AlgoliaService {
  private readonly client = aloglia(environment.algolia.appId, environment.algolia.key);

  private readonly indices = {
    therapist: this.client.initIndex('therapist'),
  };

  getIndex(key: keyof Indices): SearchIndex {
    return this.indices[key];
  }

  search<K extends keyof Indices>(index: K, params: SearchOptions, query = ''): Observable<SearchResponse<Indices[K]>> {
    const idx = this.getIndex(index);
    return from(idx.search<Indices[K]>(query, params));
  }
}
