<footer class="hidden md:block md:bottom-0 md:h-auto">
  <div class="editor-content bg-green-dark text-grayscale-200 px-4 py-1">
    <div class="flex justify-between items-center">
      <ul class="flex justify-between items-center gap-10">
        <li>
          <a href="mailto: info@therappy.ca">
            <app-typography variant="p" class="capitalize">{{ 'FOOTER.CONTACT_US' | translate }}</app-typography>
          </a>
        </li>
      </ul>
      <div class="flex justify-between items-center gap-10 text-3xl">
        <a href="https://tiktok.com/@therappyinc">
          <i class="fa-brands fa-tiktok"></i>
        </a>
        <a href="https://www.instagram.com/therappyinc/">
          <i class="fa-brands fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com/company/therappy-inc/">
          <i class="fa-brands fa-linkedin"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="bg-[#2A3727] flex items-center justify-center text-grayscale-600 py-1">
    <app-typography variant="p-sm">
      <span [innerHTML]="'LAYOUT.FOOTER.COPYRIGHT' | translate: { year }"></span>
    </app-typography>
  </div>
</footer>
