import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ResolveFn } from '@angular/router';
import { filter } from 'rxjs/operators';
import { createDialogConfig } from '../services/dialogs.service';

export const dialogResolver: ResolveFn<unknown> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const matDialog = inject(MatDialog);

  const component = route.routeConfig?.component;
  const configData = route.data['dialog'] || {};

  if (!component) {
    throw new Error(`dialogResolver expects associated route to have a component specified!`);
  }

  const path = router.getCurrentNavigation()?.finalUrl?.toString() ?? state.url;
  const pathWithoutParams = path.split('?')[0];
  const url = route.url;
  const navigateAfterClose = pathWithoutParams.split('/').filter(p => !url.map(u => u.path).includes(p) && p !== '');
  const dialogRef = matDialog.open(component, createDialogConfig({ closeOnNavigation: true, ...configData }));

  dialogRef
    .afterClosed()
    .pipe(filter(value => !value))
    .subscribe(() => router.navigate(navigateAfterClose));

  return dialogRef.afterOpened();
};
