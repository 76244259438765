import { inject } from '@angular/core';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { FilterQuery, FilterService, TherapistFilterProps } from 'src/app/state/search';
import {
  AgeRange,
  AvailabilityType,
  ClientDemographic,
  DaysOfWeek,
  Designation,
  Distance,
  Ethnicity,
  Gender,
  InsuranceBilling,
  Language,
  Religion,
  SexualOrientation,
  Specialities,
  Timings,
  TypesOfTherapy,
} from '@therappy-inc/models';
import { untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

export abstract class BaseFilterFormComponent {
  protected readonly filterService = inject(FilterService);
  protected readonly filterQuery = inject(FilterQuery);

  activeFilters$ = this.filterQuery.selectActiveFilterKeys();
  hasActiveFilters$ = this.filterQuery.selectHasActiveFilters();

  form = new FormGroup({
    specialities: new FormControl<Specialities[]>([]),
    designation: new FormControl<Designation | null>(null),
    typeOfTherapy: new FormControl<TypesOfTherapy[]>([]),
    isPriceRangeForCouple: new FormControl(false),
    minPrice: new FormControl(0),
    maxPrice: new FormControl(100),
    ageRange: new FormControl<AgeRange | null>(null),
    ethnicity: new FormControl<Ethnicity[]>([]),
    gender: new FormControl<Gender[]>([]),
    language: new FormControl<Language[]>([]),
    religion: new FormControl<Religion[]>([]),
    sexualOrientation: new FormControl<SexualOrientation[]>([]),
    clientDemographic: new FormControl<ClientDemographic[]>([]),
    insurance: new FormControl<InsuranceBilling[]>([]),
    distance: new FormControl<Distance | null>(null),
    availabilityType: new FormControl<AvailabilityType[]>([]),
    daysOfWeek: new FormControl<DaysOfWeek[]>([]),
    timings: new FormControl<Timings[]>([]),
  });

  removeFilter(key: string): void {
    this.filterService.resetFilter(key as keyof TherapistFilterProps);
  }

  resetFilters(): void {
    this.filterService.reset();
  }

  protected setupForm(): void {
    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.form.valid),
      )
      .subscribe(value => this.filterService.setFilters(value));

    this.filterQuery
      .select('filters')
      .pipe(untilDestroyed(this))
      .subscribe(value => this.form.patchValue(value, { emitEvent: false }));
  }
}
