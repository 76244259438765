import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KvCompareFn } from '../../types/keyvalue.types';

export type PillGroupState = Record<string, boolean>;

@Component({
  selector: 'app-pill-group',
  templateUrl: './pill-group.component.html',
  styleUrls: ['./pill-group.component.scss']
})
export class PillGroupComponent {

  @Input() state: PillGroupState | null = {};
  @Input() translationPrefix = '';
  @Input() showClearPill = true;
  @Input() clearPillLabel = ''
  @Input() compareFn: KvCompareFn<boolean> | undefined;

  @Output() itemClick = new EventEmitter<string>();
  @Output() clearClick = new EventEmitter<void>();

  emitItemClick(item: string): void {
    this.itemClick.emit(item);
  }

  emitClearClick(): void {
    this.clearClick.emit();
  }

  trackBy(index: number, { key }: KeyValue<string, boolean>): string {
    return `${key}_${index}`;
  }

  get noItemsSelected(): boolean {
    return Object.values(this.state || {}).every(s => !s);
  }
}
