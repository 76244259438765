import { AfterViewInit, Component, inject, OnDestroy, Renderer2 } from '@angular/core';
import { STATIC_CONTENT, StaticContentChanges } from 'src/app/core/di/static-content.token';
import { HomeContent } from './home-content.interface';
import { AuthQuery } from 'src/app/state/auth';
import { LocalizedRouterService } from 'src/app/core/services/localized-router.service';
import { UserAgentQuery } from 'src/app/state/user-agent';
import { TranslateService } from '@ngx-translate/core';
import Splide from '@splidejs/splide';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../../../node_modules/@splidejs/splide/dist/css/splide-core.min.css','./home.component.scss'],
})
export class HomeComponent implements AfterViewInit, OnDestroy {
  private readonly translate = inject(TranslateService);

  private readonly localizedRouter = inject(LocalizedRouterService);
  private readonly authQuery = inject(AuthQuery);
  private readonly renderer = inject(Renderer2);

  userAgentQuery = inject(UserAgentQuery);

  static$ = inject<StaticContentChanges<HomeContent>>(STATIC_CONTENT);

  slidesData = [
    {
      imageUrl: 'assets/svg/testimonials-1.svg',
    },
    {
      imageUrl: 'assets/svg/testimonials-2.svg',
    },
    {
      imageUrl: 'assets/svg/testimonials-3.svg',
    },
    {
      imageUrl: 'assets/svg/testimonials-4.svg',
    },
    {
      imageUrl: 'assets/svg/testimonials-5.svg',
    },
    {
      imageUrl: 'assets/svg/testimonials-6.svg',
    }
  ];

  private splide: Splide | null = null;

  onFindTherapist() {
    const user = this.authQuery.getCurrentUser();
    if (user && user.patientId) {
      this.localizedRouter.navigate(['find-therapist']);
    } else {
      this.localizedRouter.navigate(['patient-getstarted']);
    }
  }

  private initializeSplide() {
    const splideElement = document.querySelector('.splide');
    if (!splideElement) {
      console.error('Splide element not found');
      return;
    }

    this.splide = new Splide('.splide', {
      type: 'loop',
      perPage: 3,
      perMove: 1,
      pagination: false,
      gap: '25px',
      arrows: false, // Disable default arrows
      omitEnd:false,
      lazyLoad: 'nearby',
      preloadPages: 3,
      breakpoints: {
        1024: { perPage: 3 },
        768: { perPage: 2 },
        640: { perPage: 1 },
      },
    });

    // Custom arrow functionality
    const prevButton = document.querySelector('.custom-splide-arrow-prev');
    const nextButton = document.querySelector('.custom-splide-arrow-next');

    if (prevButton) {
      prevButton.addEventListener('click', () => this.splide?.go('<'));
    }
    if (nextButton) {
      nextButton.addEventListener('click', () => this.splide?.go('>'));
    }

    this.splide.on('mounted', () => {
      console.log('Splide mounted successfully');
      this.handleAriaBusy(splideElement);
    });

    this.splide.on('moved', () => {
      this.handleAriaBusy(splideElement);
    });

    try {
      this.splide.mount();
    } catch (error) {
      console.error('Error mounting Splide:', error);
    }
  }

  private handleAriaBusy(element: Element) {
    // Remove aria-busy attribute after a short delay
    setTimeout(() => {
      this.renderer.removeAttribute(element, 'aria-busy');
    }, 100);
  }

  ngAfterViewInit() {
    this.initializeSplide();
  }

  ngOnDestroy() {
    if (this.splide) {
      this.splide.destroy();
    }
  }
  onLearnMore() {
    this.localizedRouter.navigate(['about']);
  }

  jumpToSection(sectionId: string) {
    const section: HTMLElement | null = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
