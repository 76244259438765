import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStateQuery } from 'src/app/state/local';
import { get } from 'lodash';

@Injectable({ providedIn: 'root' })
export class StaticContentService {
  private readonly http = inject(HttpClient);
  private readonly localStateQuery = inject(LocalStateQuery);

  select<T extends {} = {}>(path: string, locale?: string | null, slice?: SliceParam<T>): Observable<StaticContent> {
    const loc = locale ?? this.localStateQuery.get('locale');
    const request = this.http.get<StaticContent<T>>(`assets/content/${path}/${loc}.json`);
    return !slice ? request : request.pipe(map(data => sliceContent(data, slice)));
  }
}

function sliceContent<T extends {}>(data: StaticContent<T>, slice: SliceParam<T>): StaticContent<T> {
  let sliced = data.content;

  if (typeof slice === 'function') {
    sliced = slice(data.content);
  } else {
    sliced = get(data.content, slice);
  }

  if ('title' in sliced) {
    return {
      ...data,
      title: 'title' in sliced ? (sliced as any).title : data.title,
      content: 'body' in sliced ? (sliced as any).body : sliced,
    };
  } else {
    return {
      ...data,
      content: sliced,
    };
  }
}

export interface StaticContent<T = {}> {
  path: string;
  locale: string;
  title?: string;
  updated?: string | Date;
  content: T;
}

export type SliceParam<T = {}> = keyof T | SlicePredicate<T>;
export type SlicePredicate<T> = (state: T) => any;
