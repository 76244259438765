import { Component, inject } from '@angular/core';
import { LOCALES, LocalesMap } from '../../di/locale.token';
import { LocalStateQuery, LocalStateService } from 'src/app/state/local';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent {
  private readonly localStateQuery = inject(LocalStateQuery);
  private readonly localStateService = inject(LocalStateService);
  locales = inject<LocalesMap>(LOCALES);

  currentLang$ = this.localStateQuery.select('locale');

  switchTo(locale: string): void {
    this.localStateService.switchLocale(locale);
  }
}
