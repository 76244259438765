import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ShareModule } from 'ngx-sharebuttons';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PipesModule } from './pipes/pipes.module';
import { FileDragNDropNUploadDirective } from './directives/file-drag-n-drop-n-upload.directive';
import { TypographyComponent } from './components/typography/typography.component';
import { ButtonComponent } from './components/button/button.component';
import { PillsComponent } from './components/pills/pills.component';
import { FilterPillComponent } from './components/filter-pill/filter-pill.component';
import { PrivacyPolicyModalComponent } from './components/privacy-policy-modal/privacy-policy-modal.component';
import { MultiCheckboxComponent } from './components/multi-checkbox/multi-checkbox.component';
import { ViewMorePanelComponent } from './components/view-more-panel/view-more-panel.component';
import { BookNowModalComponent } from './components/book-now-modal/book-now-modal.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { InfoPopoverComponent } from './components/info-popover/info-popover.component';
import { SubmitRequestModalComponent } from './components/submit-request-modal/submit-request-modal.component';
import { MaterialModule } from './material.module';
import { PreventDefaultDirective } from './directives/prevent-default.directive';
import { InlineSpinnerComponent } from './components/inline-spinner/inline-spinner.component';
import { LetDirective } from './directives/let.directive';
import { PhotoUploaderComponent } from './components/photo-uploader/photo-uploader.component';
import { VideoUploaderComponent } from './components/video-uploader/video-uploader.component';
import { LearnMoreCardComponent } from './components/learn-more-card/learn-more-card.component';
import { ForgotPasswordModalComponent } from './components/forgot-password/forgot-password-modal.component';
import { VerifiedBadgeComponent } from './components/verified-badge/verified-badge.component';
import { AttributeListComponent } from './components/attribute-list/attribute-list.component';
import { SocialShareButtonsComponent } from './components/social-share-buttons/social-share-buttons.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { PillComponent } from './components/pill/pill.component';
import { StepProgressBarComponent } from './components/step-progress-bar/step-progress-bar.component';
import { PillGroupComponent } from './components/pill-group/pill-group.component';
import { GooglePlacesDirective } from './directives/google-places.directive';
import { InputDirective } from './directives/input.directive';
import { FormsComponentsModule } from './forms-components/forms-components.module';
import { GetLaunchlistComponent } from './components/getlaunchlist/get-launch-list.component';

const components = [
  TypographyComponent,
  ButtonComponent,
  PillsComponent,
  FilterPillComponent,
  PrivacyPolicyModalComponent,
  MultiCheckboxComponent,
  ViewMorePanelComponent,
  BookNowModalComponent,
  BreadcrumbComponent,
  InfoPopoverComponent,
  SubmitRequestModalComponent,
  InlineSpinnerComponent,
  PhotoUploaderComponent,
  VideoUploaderComponent,
  LearnMoreCardComponent,
  ForgotPasswordModalComponent,
  VerifiedBadgeComponent,
  AttributeListComponent,
  SocialShareButtonsComponent,
  SnackBarComponent,
  PillComponent,
  PillGroupComponent,
  StepProgressBarComponent,
  GetLaunchlistComponent
];

const directives = [
  PreventDefaultDirective,
  LetDirective,
  FileDragNDropNUploadDirective,
  GooglePlacesDirective,
  InputDirective
];

@NgModule({
  declarations: [
    ...components,
    ...directives
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({ extend: true }),
    NgSelectModule,
    PipesModule,
    MaterialModule,
    ShareModule,
    ClipboardModule,
    FormsComponentsModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    PipesModule,
    MaterialModule,
    ShareModule,
    ClipboardModule,
    FormsComponentsModule,
    ...components,
    ...directives
  ],
})
export class SharedModule {}
