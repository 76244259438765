import { Component, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExtraValidators } from 'src/app/shared/validators/extra.validators';
import { AuthQuery, AuthService } from 'src/app/state/auth';

@UntilDestroy()
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  private readonly authQuery = inject(AuthQuery);
  private readonly authService = inject(AuthService);

  error$ = this.authQuery.selectAuthError();
  loading$ = this.authQuery.selectLoading();

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    passwordAgain: new FormControl('', [Validators.required, Validators.minLength(6), ExtraValidators.sameAsControl('password')]),
    acceptPrivacyPolicy: new FormControl(false, [Validators.requiredTrue]),
  });

  ngOnInit(): void {
    this.clearError();
    this.form
      .get('password')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.form.get('passwordAgain').updateValueAndValidity();
      });
  }

  onSubmit(): void {
    this.authService.signupEmail({ ...this.form.value });
  }

  clearError(): void {
    this.authService.clearError();
  }
}
