import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { defaultLanguageGuard } from './core/guards/default-language.guard';
import { PlaygroundComponent } from './static-pages/playground/playground.component';
import { HomeComponent } from './static-pages/home/home.component';
import { staticContentResolver } from './core/resolvers/static-content.resolver';
import { AboutUsComponent } from './static-pages/about-us/about-us.component';
import { PageNotFoundComponent } from './static-pages/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './static-pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './static-pages/terms-conditions/terms-conditions.component';
import { AppComponent } from './app.component';
import { authGuard } from './core/guards/auth.guard';
import { UserType } from '@therappy-inc/models';

export const routes: Routes = [
  {
    path: '',
    canActivate: [defaultLanguageGuard],
    pathMatch: 'full',
    component: AppComponent,
  },
  {
    path: ':locale',
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'playground',
        component: PlaygroundComponent,
        data: { title: 'NAV.PLAYGROUND', staticContentPath: 'playground' },
        resolve: { static: staticContentResolver },
      },
      {
        path: 'home',
        component: HomeComponent,
        data: { title: 'NAV.HOME', staticContentPath: 'home' },
        resolve: { static: staticContentResolver },
      },
      {
        path: 'about',
        component: AboutUsComponent,
        data: { title: 'NAV.ABOUT', staticContentPath: 'about' },
        resolve: { static: staticContentResolver },
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
          title: 'NAV.PRIVACY_POLICY',
          staticContentPath: 'privacy-policy',
        },
        resolve: { static: staticContentResolver },
      },
      {
        path: 'terms-conditions',
        component: TermsConditionsComponent,
        data: {
          title: 'NAV.TERMS_CONDITIONS',
          staticContentPath: 'terms-conditions',
        },
        resolve: { static: staticContentResolver },
      },
      {
        path: 'find-therapist',
        loadChildren: () => import('./find-therapist/find-therapist.module').then(m => m.FindTherapistModule),
      },
      {
        path: 'for-therapists',
        loadChildren: () => import('./static-pages/for-therapists/for-therapists.module').then(m => m.ForTherapistsModule),
      },
      {
        path: 'therapist-dashboard',
        loadChildren: () => import('./therapist-dashboard/therapist-dashboard.module').then(m => m.TherapistDashboardModule),
        canActivate: [authGuard],
        data: {
          roles: [UserType.Therapist],
        },
      },
      {
        path: 'patient-profile',
        loadChildren: () => import('./patient-profile/patient-profile.module').then(m => m.PatientProfileModule),
        canActivate: [authGuard],
        data: {
          roles: [UserType.Patient],
        },
      },
      {
        path: 'startup',
        children: [
          {
            path: 'patient',
            loadChildren: () => import('./startup/patient/patient-startup.module').then(m => m.PatientStartupModule)
          },
          {
            path: 'therapist',
            loadChildren: () => import('./startup/therapist/therapist-startup.module').then(m => m.TherapistStartupModule)
          },
          {
            path: '',
            component: PageNotFoundComponent
          },
        ]
      },
      {
        path: 'auth', /** DEPRECATED */
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {
        path: 'patient-onboarding', /** DEPRECATED */
        loadChildren: () => import('./patient-onboarding/patient-onboarding.module').then(m => m.PatientOnboardingModule),
        canActivate: [authGuard],
      },
      {
        path: 'therapist-onboarding', /** DEPRECATED */
        loadChildren: () => import('./therapist-onboarding/therapist-onboarding.module').then(m => m.TherapistOnboardingModule),
        canActivate: [authGuard],
      },
      {
        path: 'experimentation', /** DEPRECATED */
        loadChildren: () => import('./experimentation/experimentation.module').then(m => m.ExperimentationModule),
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        data: { title: 'ERRORPAGES.NOT_FOUND.TITLE' },
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: 'ERRORPAGES.NOT_FOUND.TITLE' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
