import { coerceArray } from '@angular/cdk/coercion';
import { Pipe, PipeTransform } from '@angular/core';
import { LocalStateQuery } from 'src/app/state/local';

@Pipe({
  name: 'mapToStrings',
  pure: false,
})
export class MapToStringsPipe implements PipeTransform {
  transform(items: any[] | any): string[] | string {
    if (items && items.length) {
      return items.join(', ');
    }
    return items;
  }
}
