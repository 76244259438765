import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { createInitialState, LocalState } from './local-state.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'localState', resettable: true })
export class LocalStateStore extends Store<LocalState> {
  constructor() {
    super(createInitialState());
  }
}
