import { Component, inject } from '@angular/core';
import { UserAgentQuery } from 'src/app/state/user-agent';

@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.scss'],
})
export class PrivacyPolicyModalComponent {
  userAgentQuery = inject(UserAgentQuery);
}
