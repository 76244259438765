import { EntityStore, guid } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Result, ResultsState, createInitialResultsState } from './results.model';

@Injectable({ providedIn: 'any' })
export class ResultsStore extends EntityStore<ResultsState, Result> {
  constructor() {
    super(createInitialResultsState(), { name: `results-${guid()}`, idKey: 'objectID' });
  }
}
