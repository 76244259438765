import { Component, inject } from '@angular/core';
import { Result, ResultsQuery } from 'src/app/state/search';

@Component({
  selector: 'app-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.scss'],
})
export class ResultListComponent {
  private readonly resultsQuery = inject(ResultsQuery);

  results$ = this.resultsQuery.selectAll();

  trackBy(index: number, item: Result): string {
    return item.objectID;
  }
}
