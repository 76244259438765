import { Component, Input } from '@angular/core';

type Variant =
  | 'h1'
  | 'h1-dark'
  | 'h2'
  | 'h2-dark'
  | 'h3'
  | 'h3-dark'
  | 'h4'
  | 'h4-dark'
  | 'h5'
  | 'h5-dark'
  | 'p-lg'
  | 'p-lg-dark'
  | 'p'
  | 'p-dark'
  | 'p-sm'
  | 'p-sm-dark'
  | 'cap-13'
  | 'cap-13-dark'
  | 'cap-12'
  | 'cap-12-dark';

@Component({
  selector: 'app-typography',
  templateUrl: './typography.component.html',
  styleUrls: ['./typography.component.scss'],
})
export class TypographyComponent {
  @Input()
  variant: Variant = 'h1';
  @Input() class? = '';
  @Input() content?: string;

  sizes: Record<Variant, string> = {
    h1: 'font-poppins text-5xl font-normal leading-10 lg:leading-[64px] lg:text-7xl lg:font-medium',
    'h1-dark': 'font-poppins text-5xl font-medium	leading-10 lg:leading-[64px] lg:text-7xl',

    h2: 'font-poppins text-4xl font-normal leading-9 lg:leading-[56px] lg:text-6xl lg:font-medium',
    'h2-dark': 'font-poppins text-4xl font-medium	leading-9 lg:leading-[56px] lg:text-6xl',

    h3: 'font-poppins text-3xl font-normal leading-8 lg:leading-10 lg:text-5xl lg:font-medium',
    'h3-dark': 'font-poppins text-3xl font-medium	leading-8 lg:leading-10 lg:text-5xl',

    h4: 'font-poppins text-2xl font-normal leading-[26px] lg:leading-8 lg:text-3xl lg:font-medium',
    'h4-dark': 'font-poppins text-2xl font-medium	leading-[26px] lg:leading-8 lg:text-3xl',

    h5: 'font-poppins text-lg font-normal leading-6 lg:leading-6 lg:text-xl lg:font-medium',
    'h5-dark': 'font-poppins text-lg font-medium	leading-6 lg:leading-6 lg:text-xl',

    'p-lg': 'font-montserrat text-base font-normal leading-6 lg:text-xl',
    'p-lg-dark': 'font-montserrat text-base font-medium	leading-6 lg:text-xl',

    p: 'font-montserrat leading-5 text-sm font-normal leading-6 lg:text-base lg:leading-6',
    'p-dark': 'font-montserrat leading-5	text-sm font-medium	leading-6 lg:text-base lg:leading-6',

    'p-sm': 'font-montserrat leading-5 text-xs font-normal',
    'p-sm-dark': 'font-montserrat leading-5	text-xs font-medium',

    'cap-13': 'font-montserrat leading-4 text-xxs font-normal',
    'cap-13-dark': 'font-montserrat leading-4	text-xxs font-medium',

    'cap-12': 'font-montserrat leading-4 text-xxxs font-normal',
    'cap-12-dark': 'font-montserrat leading-4	text-xxxs font-medium',
  };

  getSizeClasses(): string {
    return `${this.sizes[this.variant]} ${this.class}`;
  }
}
