import { coerceArray } from '@angular/cdk/coercion';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { LocalStateQuery } from 'src/app/state/local';

@Pipe({
  name: 'localizedLink',
  pure: false,
})
export class LocalizedLinkPipe implements PipeTransform {
  private readonly localStateQuery = inject(LocalStateQuery);

  transform(value: string | string[], join?: false): string[];
  transform(value: string | string[], join: true): string;
  transform(value: string | string[], join = false): string[] | string {
    const command = coerceArray(value);
    if (command[0] === '/') {
      command.shift();
    }
    const language = this.localStateQuery.get('locale');
    const url = ['/', language, ...command];
    return join ? url.slice(1).join('/') : url;
  }
}
