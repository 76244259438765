import { Query } from '@datorama/akita';
import { FilterState, FilterUi, TherapistFilterProps, getMinimalState } from './filters.model';
import { FilterStore } from './filters.store';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { serializeObject } from 'src/app/shared/utils/url-serializer';
import { createFilterString, filterActiveKeys } from 'src/app/shared/utils/algolia.utils';

@Injectable({ providedIn: 'any' })
export class FilterQuery extends Query<FilterState> {

  constructor(protected override store: FilterStore) {
    super(store);
  }

  selectActiveFilters(): Observable<Partial<TherapistFilterProps>> {
    return this.select('filters').pipe(map(state => filterActiveKeys(state)));
  }

  selectActiveFilterKeys(): Observable<string[]> {
    return this.selectActiveFilters().pipe(map(state => Object.keys(state)));
  }

  selectHasActiveFilters(): Observable<boolean> {
    return this.selectActiveFilterKeys().pipe(map(filters => filters.length > 0));
  }

  selectSearchString(): Observable<string> {
    return this.selectActiveFilters().pipe(map(state => createFilterString(state, 'attributes')));
  }

  selectUi<K extends keyof FilterUi>(key: K): Observable<FilterUi[K]>;
  selectUi(): Observable<FilterUi>;
  selectUi<K extends keyof FilterUi>(key?: K): Observable<FilterUi | FilterUi[K]> {
    const state = this.select('ui');
    if (!key) {
      return state;
    }
    return state.pipe(map(u => u[key]));
  }

  getSerialized(): string {
    return serializeObject(
      getMinimalState(this.getValue())
    );
  }
}
