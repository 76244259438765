import { Component, Input, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthQuery, AuthService } from 'src/app/state/auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title = '';
  @Input() titleHeadlineOne = '';
  @Input() titleHeadlineTwo = '';
  @Input() subTitle = '';
  @Input() isHome = false;
  @Input() onlyNav = false;

  private readonly translate = inject(TranslateService);
  private readonly authQuery = inject(AuthQuery);
  private readonly authService = inject(AuthService);
  user$ = this.authQuery.selectCurrentUser();

  showMobileNav = false;
  defaultLinks: Array<{ name: string; url: string[]; style?: string }> = [
    {
      name: this.translate.instant('NAV.HOME'),
      url: ['home'],
    },
    {
      name: this.translate.instant('NAV.FOR_THERAPISTS'),
      url: ['for-therapists'],
    },
    {
      name: this.translate.instant('NAV.ABOUT'),
      url: ['about'],
    },
  ];
  links: Array<{ name: string; url: string[]; style?: string }> = [];

  constructor() {
    this.user$.subscribe(user => {
      if (user) {
        if (user.patientId) {
          this.defaultLinks[1]['url'] = ['find-therapist'];
        } else {
          this.defaultLinks[1]['url'] = ['patient-getstarted'];
        }
        this.links = [...this.defaultLinks];
      } else {
        this.links = [
          ...this.defaultLinks,
        ];
      }
    });
  }

  logout() {
    this.authService.signOut();
  }
}
