import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-pill',
  templateUrl: './filter-pill.component.html',
  styleUrls: ['./filter-pill.component.scss'],
})
export class FilterPillComponent {
  @Input() label = '';
  @Input() class = '';
  @Output() dismissClicked = new EventEmitter<void>();

  onDismiss(): void {
    this.dismissClicked.emit();
  }
}
