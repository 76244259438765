import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserAgent } from './user-agent.model';
import { UserAgentStore } from './user-agent.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserAgentQuery extends Query<UserAgent> {
  constructor(protected override store: UserAgentStore) {
    super(store);
  }

  selectIsMobile(): Observable<boolean> {
    return this.select('isMobile');
  }
}
