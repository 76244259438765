import { UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formError',
  pure: false,
})
export class FormErrorPipe implements PipeTransform {
  private readonly uppercase = inject(UpperCasePipe);
  private readonly translate = inject(TranslateService);

  transform(value: object | null): string | null {
    if (!value) {
      return null;
    }

    const errors = Object.entries(value);
    const messages = errors.map(([err, data]) => this.translate.instant(this.getTranslationKey(err), data));
    return messages.join(', ');
  }

  private getTranslationKey(errorId: string): string {
    const errorKey = this.uppercase.transform(errorId);
    return `FORMS.ERRORS.${errorKey}`;
  }
}
