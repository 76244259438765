import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from '@datorama/akita';

@Pipe({
  name: 'arrayToString',
})
export class ArrayToStringPipe implements PipeTransform {
  transform<T extends string, K extends keyof T>(value: T[] | null, pluck?: K, maxLength?: number): string;
  transform<T extends Record<string, unknown>, K extends keyof T>(value: T[] | null, pluck?: K, maxLength?: number): string;
  transform<T extends Record<string, unknown> | string, K extends keyof T>(value: T[] | null, pluck?: K, maxLength = 3): string {
    if (!isArray(value)) {
      return '';
    }

    const length = value.length;

    if (length === 1) {
      const item = value[0];
      return pluck ? (item as any)[pluck] : item;
    }

    const items = pluck ? value.map(i => i[pluck]) : value;
    const sliced = items.slice(0, maxLength);
    const concatenated = sliced.join(', ');

    return length > maxLength ? concatenated + ` +${length - maxLength}` : concatenated;
  }
}
