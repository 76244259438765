import { Injectable, inject } from '@angular/core';
import { FilterStore } from './filters.store';
import { FilterState, FilterUi, TherapistFilterProps, createInitialFilterState } from './filters.model';
import { Router } from '@angular/router';
import { FilterQuery } from './filters.query';
import { Observable, map, tap } from 'rxjs';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filterFalsey } from 'src/app/core/utils/filter-falsey.operator';
import { deserializeObject } from 'src/app/shared/utils/url-serializer';

@Injectable({ providedIn: 'any' })
export class FilterService {

  private readonly store = inject(FilterStore);
  private readonly query = inject(FilterQuery);
  private readonly router = inject(Router);
  private readonly routerQuery = inject(RouterQuery);

  setFilters(value: Partial<TherapistFilterProps>): void {
    this.store.update(state => ({
      ...state,
      filters: {
        ...state.filters,
        ...value,
      },
    }));
    this.updateRouter();
  }

  setUi(value: Partial<FilterUi>): void {
    this.store.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        ...value,
      },
    }));
  }

  toggleUi(key: keyof FilterUi): void {
    const value = this.store.getValue().ui[key];
    this.setUi({ [key]: !value });
  }

  resetFilter(key: keyof TherapistFilterProps): void {
    this.setFilters({
      [key]: createInitialFilterState().filters[key],
    });
  }

  reset(): void {
    this.store.reset();
    this.updateRouter();
  }

  trackURL(): Observable<Partial<FilterState>> {
    return this.routerQuery.selectQueryParams<string>('state').pipe(
      filterFalsey(),
      map(serialized => deserializeObject<Partial<FilterState>>(serialized, ['filters', 'sortBy', 'sortOrder'])),
      tap(state => this.store.update(state))
    );
  }

  private updateRouter(): void {
    this.router.navigate([], {
      replaceUrl: true,
      queryParams: { state: this.query.getSerialized() }
    });
  }
}
