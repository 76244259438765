import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent {

  @Input() label = '';
  @Input() selected = false;

  get class(): string {
    return this.selected ? 'bg-lime-regular text-grayscale-200' : 'hover:border-lime-regular hover:bg-lime-light hover:text-lime-regular';
  }
}
