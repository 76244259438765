<ng-container [ngSwitch]="variant">
  <h1 *ngSwitchCase="'h1'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </h1>
  <h1 *ngSwitchCase="'h1-dark'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </h1>
  <h2 *ngSwitchCase="'h2'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </h2>
  <h3 *ngSwitchCase="'h2-dark'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </h3>
  <h3 *ngSwitchCase="'h3'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </h3>
  <h3 *ngSwitchCase="'h3-dark'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </h3>
  <h4 *ngSwitchCase="'h4'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </h4>
  <h4 *ngSwitchCase="'h4-dark'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </h4>

  <h5 *ngSwitchCase="'h5'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </h5>
  <h5 *ngSwitchCase="'h5-dark'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </h5>

  <p *ngSwitchCase="'p-lg'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </p>
  <p *ngSwitchCase="'p-lg-dark'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </p>

  <p *ngSwitchCase="'p'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </p>
  <p *ngSwitchCase="'p-dark'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </p>

  <p *ngSwitchCase="'p-sm'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </p>
  <p *ngSwitchCase="'p-sm-dark'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </p>

  <caption *ngSwitchCase="'cap-13'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </caption>
  <caption *ngSwitchCase="'cap-13-dark'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </caption>

  <caption *ngSwitchCase="'cap-12'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </caption>
  <caption *ngSwitchCase="'cap-12-dark'" [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </caption>

  <p *ngSwitchDefault [ngClass]="getSizeClasses()">
    <ng-container [ngTemplateOutlet]="ngTemplate"></ng-container>
  </p>
</ng-container>

<ng-template #ngTemplate>
  <ng-content></ng-content>
</ng-template>
