import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ExperimentationComponent } from './experimentation.component';
import { SignupComponent } from './signup/signup.component';
import { TherapistSearchModule } from '../shared/components/therapist-search/therapist-search.module';
import { ExperimentationRoutingModule } from './experimentation-routing.module';

@NgModule({
  declarations: [ExperimentationComponent, SignupComponent],
  imports: [CommonModule, SharedModule, ExperimentationRoutingModule, TherapistSearchModule],
})
export class ExperimentationModule {}
