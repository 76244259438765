import { Component, Input, inject } from '@angular/core';
import { Keys } from '@therappy-inc/models';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-social-share-buttons',
  templateUrl: './social-share-buttons.component.html',
  styleUrls: ['./social-share-buttons.component.scss'],
})
export class SocialShareButtonsComponent {
  private readonly alerts = inject(AlertService);
  socialToIconMap = {
    whatsapp: 'social:whatsapp',
    telegram: 'social:telegram',
    facebook: 'social:facebook',
    email: 'social:email',
    copy: 'social:copy',
  };

  @Input() enabledSocials: Keys<typeof this.socialToIconMap> = ['copy', 'email', 'facebook', 'telegram', 'whatsapp'];
  @Input() url = '/';

  onClick(item: keyof typeof this.socialToIconMap): void {
    switch (item) {
      case 'copy':
        this.alerts.success('LABEL.COPIED_TO_CLIPBOARD');
        break;
      default:
        break;
    }
  }
}
