import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * This directive is a stripped-down version of `*ngIf`, without the child view toggling.
 * It is useful to bind a local variable, after subscribing using the `async` pipe,
 * especially if the emitted value is falsy (with `*ngIf`, the template would be
 * removed from the DOM, which is not what we want)
 */
@Directive({
  selector: '[appLet]',
})
export class LetDirective<T> {
  private context = new Context<T>(null);

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<Context<T>>,
  ) {
    this.viewContainer.createEmbeddedView(this.templateRef, this.context);
  }

  @Input()
  set appLet(value: T) {
    this.context.appLet = value;
  }

  static ngTemplateContextGuard<T>(dir: LetDirective<T>, ctx: unknown): ctx is Context<T> {
    return true;
  }
}

class Context<T> {
  $implicit!: T | null;
  appLet!: T | null;

  constructor(value: T | null) {
    this.$implicit = value;
    this.appLet = value;
  }
}
