import { Injectable, inject } from '@angular/core';
import { FilterQuery } from './filters.query';
import { AlgoliaService } from 'src/app/core/services/algolia.service';
import { ResultsStore } from './results.store';
import { Observable, switchMap, tap } from 'rxjs';
import { SearchResponse } from '@algolia/client-search';
import { TherapistIndexRecord } from '@therappy-inc/models';
import { Result } from './results.model';

type TherapistResponse = SearchResponse<TherapistIndexRecord>;

@Injectable({ providedIn: 'any' })
export class ResultsService {
  
  private readonly store = inject(ResultsStore, { host: true });
  private readonly filterQuery = inject(FilterQuery);
  private readonly algolia = inject(AlgoliaService);

  sync(): Observable<TherapistResponse> {
    return this.filterQuery.selectSearchString().pipe(switchMap(filters => this.getResults(filters)));
  }

  update(id: string, partial: Partial<Result>): void {
    this.store.update(id, entity => ({
      ...entity,
      ...partial,
    }));
  }

  toggleKey(id: string, key: keyof Result): void {
    this.store.update(id, entity => ({
      ...entity,
      [key]: !entity[key],
    }));
  }

  private getResults(filters: string): Observable<TherapistResponse> {
    this.store.setLoading(true);
    return this.algolia.search('therapist', { filters }).pipe(
      tap(() => this.store.setLoading(false)),
      tap(res => this.handleResponse(res)),
    );
  }

  private handleResponse(res: TherapistResponse): void {
    const { hits, ...metadata } = res;
    this.store.remove();
    this.store.add(hits);
    this.store.update(s => ({
      ...s,
      ...metadata,
    }));
  }
}
