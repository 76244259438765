import { TherapistIndexRecord, createInitialTherapist } from '@therappy-inc/models';
import { Hit, SearchResponse } from '@algolia/client-search';
import { EntityState } from '@datorama/akita';

export interface Result extends Hit<TherapistIndexRecord> {
  matchPercent?: number;
  isFavorite?: boolean;
  isSelected?: boolean;
  messageSent?: boolean;
  promoted?: boolean;
  matchedFilters?: string[];
}

export type ResultsState = EntityState<Result, string> & Omit<SearchResponse<Result>, 'hits'>;

export function createInitialResultsState(): ResultsState {
  return {
    exhaustiveNbHits: false,
    hitsPerPage: 0,
    nbHits: 0,
    nbPages: 0,
    page: 0,
    processingTimeMS: 0,
    query: '',
    params: '',
  };
}

export function createInitialResult(): Result {
  return {
    ...createInitialTherapist(),
    objectID: '',
  };
}
