import { AbstractControl, ValidatorFn } from '@angular/forms';

export class ExtraValidators {
  static sameAsControl(matchControlName: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.parent) {
        return null;
      }

      const matchingCtrl = control.parent.get(matchControlName);
      const matches = control.value === matchingCtrl?.value;
      return matches ? null : { sameAsControl: true };
    };
  }
}
