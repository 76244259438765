import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormSelectComponent
    }
  ]
})
export class FormSelectComponent<T extends string = string> extends ControlValueAccessor<T | null> {

  control = new FormControl<T | null>();

  @Input() placeholder = 'LABEL.SELECT_OPTION';
  @Input() label = '';
  @Input() translationPrefix = '';
  @Input() items: T[] = [];

  override writeValue(value: T | null): void {
    this.control.setValue(value, { emitEvent: false });
  }

  override registerOnChange(fn: ControlValueAccessor['onChange']): void {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.control.setDisable(isDisabled);
  }
}
