import { Component, Input } from '@angular/core';
import { range } from 'lodash';

@Component({
  selector: 'app-step-progress-bar',
  templateUrl: './step-progress-bar.component.html',
  styleUrls: ['./step-progress-bar.component.scss']
})
export class StepProgressBarComponent {

  @Input() currentStep = 0;
  @Input() totalSteps = 0;

  get steps(): number[] {
    return range(0, this.totalSteps);
  }
}
