import { QueryEntity, SelectOptions } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Result, ResultsState } from './results.model';
import { ResultsStore } from './results.store';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'any' })
export class ResultsQuery extends QueryEntity<ResultsState, Result> {
  constructor(protected override store: ResultsStore) {
    super(store);
  }

  selectResultsCount(): Observable<number> {
    return this.select('nbHits');
  }

  selectSelectedResults(): Observable<string[]> {
    return this.selectIdByPredicate(e => !!e.isSelected);
  }

  selectFavoritedResults(): Observable<string[]> {
    return this.selectIdByPredicate(e => !!e.isFavorite);
  }

  selectIdByPredicate(predicate: SelectOptions<Result>['filterBy']): Observable<string[]> {
    return this.selectAll({ filterBy: predicate }).pipe(map(results => results.map(r => r.objectID)));
  }
}
