<ng-container *ngIf="static$ | async as static">
  <app-header [titleHeadlineOne]="static.content.titleHeadlineOne" [titleHeadlineTwo]="static.content.titleHeadlineTwo" [subTitle]="static.content.subHeader" [isHome]="true">
  </app-header>
  <div
    class="relative flex flex-col md:flex-row gap-[36px] md:gap-[16px] bg-[url('/assets/img/header-leaf.png')] bg-cover bg-no-repeat bg-top w-full px-[16px] py-[24px] md:px-[80px] md:py-[64px]">
    <div class="absolute left-0 right-0 top-0 bottom-0 bg-[rgba(0,0,0,0.6)] z-[1]"></div>


    <app-learn-more-card
      class="flex-1 z-[2]"
      [title]="'HOME.SECTION.EXPLORE_AND_FILTER.TITLE' | translate"
      [subTitle]="'HOME.SECTION.EXPLORE_AND_FILTER.SUMMARY' | translate"
      (learnMoreClick)="jumpToSection('export_filter')"></app-learn-more-card>
    <app-learn-more-card
      class="flex-1 z-[2]"
      [title]="'HOME.SECTION.SAVE_PREFERENCES.TITLE' | translate"
      [subTitle]="'HOME.SECTION.SAVE_PREFERENCES.SUMMARY' | translate"
      (learnMoreClick)="jumpToSection('save_preferences')"></app-learn-more-card>
    <app-learn-more-card
      class="flex-1 z-[2]"
      [title]="'HOME.SECTION.FREE_TO_USE.TITLE' | translate"
      [subTitle]="'HOME.SECTION.FREE_TO_USE.SUMMARY' | translate"
      (learnMoreClick)="jumpToSection('free_to_use')"></app-learn-more-card>
  </div>
  <div class="pt-[80px] md:py-[80px] md:p-[80px]">
    <div class="md:mb-20 w-full">
      <app-typography variant="h3-dark" class="w-2/3 text-grayscale-900 mb-[8px] ml-[16px] md:ml-0">{{ 'HOME.SECTION.THERAPPY_COMPETITION.HOME_TITLE' | translate }}</app-typography>
      <app-typography variant="p-sm" class="w-2/3 text-grayscale-900 mb-[8px] ml-[16px] md:ml-0">{{ 'HOME.SECTION.THERAPPY_COMPETITION.DID_YOU_KNOW.TITLE' | translate }}</app-typography>

      <div class="custom-splide-container md:block"> <!-- Added hidden md:block classes -->
        <div class="flex justify-end mb-4">
          <div class="custom-splide-arrows flex">
            <app-button
              variant="solid"
              size="sm"
              class="custom-splide-arrow custom-splide-arrow-prev mr-1"
            >
              <i class="fa-solid fa-chevron-left"></i>
            </app-button>
            <app-button
              variant="solid"
              size="sm"
              class="custom-splide-arrow custom-splide-arrow-next"
            >
              <i class="fa-solid fa-chevron-right"></i>
            </app-button>
          </div>
        </div>

        <div class="splide" role="group" aria-label="Splide Basic HTML Example">
          <div class="splide__track">
            <ul class="splide__list">
              <li class="splide__slide" *ngFor="let slide of slidesData">
                <div class="flex flex-col">
                  <img
                    [src]="slide.imageUrl"
                    class="rounded-2xl object-cover"
                    [alt]="'Slide image'"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>


</ng-container>
<app-footer></app-footer>
