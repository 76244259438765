import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeComponent } from './home.component';
import { LayoutModule } from 'src/app/core/layout/layout.module';

@NgModule({
  declarations: [HomeComponent],
  imports: [SharedModule, LayoutModule],
  exports: [HomeComponent],
})
export class HomeModule {}
