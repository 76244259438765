<div *ngIf="data" class="bg-grayscale-400">
  <div class="border-b border-grayscale-500 p-4">
    <app-typography variant="p-sm-dark" >{{ data.title }}</app-typography>
  </div>
  <div class="flex flex-col flex-wrap gap-x-6 max-h-[600px] p-4">
    <div *ngFor="let item of data.items">
      <app-typography variant="p-sm" >
        <ng-container *ngIf="data.translateKey">
          {{ 'ENUMS.' + data.translateKey + '.' + item | translate }}
        </ng-container>
        <ng-container *ngIf="!data.translateKey">
          {{ item }}
        </ng-container>
      </app-typography>
    </div>
  </div>
</div>
