import {
  AgeRange,
  AvailabilityType,
  ClientDemographic,
  CredentialType,
  DaysOfWeek,
  DegreeAttendance,
  Designation,
  Ethnicity,
  Gender,
  InsuranceBilling,
  Language,
  LicenseIssuer,
  PaymentMethods,
  Religion,
  SexualOrientation,
  Specialities,
  Therapist,
  TherapistOnboardingStatus,
  Timings,
  TypeofAttitudeToPatient,
  TypesOfTherapy,
} from '@therappy-inc/models';

export const DEMO_THERAPIST: Therapist = {
  id: 'ABCDEF123',
  userId: 'DEF1245',
  aboutFull:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque consequat euismod lacinia. Sed viverra posuere mattis. Nullam ut convallis lorem. Suspendisse nulla eros, lacinia sodales fringilla et, vestibulum at enim. Proin tempus egestas nisl vel pellentesque. Nam at mi sed purus aliquet pulvinar ac non massa. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas eget faucibus ex. Vestibulum luctus pretium nibh a volutpat.',
  aboutShort: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  tagLine: 'Hello',
  alias: 'Jane Cooper',
  onboardingStatus: TherapistOnboardingStatus.INITIAL,
  photoURL: 'https://picsum.photos/400/350',
  videoProfileURL: 'https://www.youtube.com/watch?v=Eb2r-Brnq6s',
  links: {
    BOOKING: 'https://google.com',
    EMAIL: 'gabdusch@gmail.com',
    FACEBOOK: 'https://www.facebook.com/gabdusch',
    WEBSITE: 'https://www.apple.com/',
  },
  attributes: {
    genders: [Gender.FEMALE],
    designation: Designation.COUNSELLOR,
    religions: [Religion.BUDDHIST, Religion.MUSLIM],
    language: [Language.EN, Language.FR, Language.PA],
    age: AgeRange.FROM25TO34,
    ethnicity: [Ethnicity.ORIENTAL, Ethnicity.SOUTHASIAN],
    sexualOrientations: [SexualOrientation.STRAIGHT],
    lgbtqAdvocate: true,
    availabilityType: [AvailabilityType.INPERSON, AvailabilityType.PHONE, AvailabilityType.VIDEO],
    daysOfWeek: [DaysOfWeek.MON, DaysOfWeek.TUE, DaysOfWeek.THU, DaysOfWeek.FRI],
    timings: [Timings.MORNING, Timings.AFT],
    specialities: [Specialities.ADDICTION, Specialities.DEMENTIA, Specialities.ALCOHOL],
    areasOfExpertise: [Specialities.ASPERGERS, Specialities.WORK, Specialities.WOMENISSUES, Specialities.TRAUMA, Specialities.TBI],
    typeOfTherapy: [...Object.values(TypesOfTherapy)],
    academicCredentials: [
      { degreeAttendance: DegreeAttendance.FULLTIME, graduationYear: 2020, institution: 'Harvard University', level: CredentialType.DEGREE },
      { degreeAttendance: DegreeAttendance.FULLTIME, graduationYear: 2006, institution: 'Standford University', level: CredentialType.CERTIFICATE },
    ],
    clientDemographic: [ClientDemographic.ADULTS, ClientDemographic.COUPLES],
    licenseIssuer: LicenseIssuer.OCSWSSW,
    licenseNumber: 'CDF124533',
    yearsOfPratice: 6,
    freeConsultation: true,
    directBilling: true,
    sessionFee: { currency: 'CAD', value: 200 },
    sessionFeeCouple: { currency: 'CAD', value: 300 },
    paymentMethods: [PaymentMethods.APPLEPAY, PaymentMethods.DIRECTBILLING, PaymentMethods.VISA],
    insurance: [InsuranceBilling.MENULIFE, InsuranceBilling.ABDHEALTH],
    acceptingNewClients: true,
    lookingForClinicalSupervisor: true,
    ownClinicalSupervisor: { email: 'a@b.c', licenseNumber: '124vC', name: 'John Sample' },
    qualifiedClinicalSupervisor: false,
    typeofAttitudeToPatient: [TypeofAttitudeToPatient.CHALLENGES, TypeofAttitudeToPatient.SUPPORTS],
  },
};
