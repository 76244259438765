<div class="flex flex-col md:flex-row relative min-w-auto md:min-w-[1248px] min-h-[576px] max-h-[80vh] bg-grayscale-200">
  <div class="w-auto md:w-[30%] bg-[url('/assets/img/leaf-on-modal.png')] bg-no-repeat bg-cover text-grayscale-200 p-6">
    <div class="flex md:flex-col gap-2 md:gap-3">
      <img
        [src]="profile.photoURL"
        [alt]="profile.alias"
        class="w-[40%] md:w-full h-auto md:h-[253px] object-cover rounded-lg mb-4"
      />
      <div>
        <div class="flex items-start gap-2 mb-4">
          <div>
            <app-typography variant="h4-dark" class="mb-1">{{ profile.alias }}</app-typography>
            <div class="flex items-center gap-2">
              <app-typography variant="p-sm">{{ 'ENUMS.DESIGNATION.' + profile.attributes.designation | translate }}</app-typography>
              <i class="fa-solid fa-circle text-[4px]"></i>
              <app-typography variant="p-sm">{{ profile.attributes.genders | translateArray:'ENUMS.GENDER' | async | arrayToString }}</app-typography>
            </div>
          </div>
        <app-verified-badge *ngIf="isVerified"></app-verified-badge>
        </div>
        <div class="flex flex-wrap gap-3 mb-4">
          <a
            *ngFor="let link of socialLinks | keyvalue"
            [href]="link.value"
            target="_blank"
            class="bg-green-dark rounded px-2 py-1">
              <app-typography
                variant="p-sm-dark"
                class="cursor-pointer !text-[12px] !md:text-[13px] text-green-light">
                {{ 'ENUMS.LINK_TYPE.' + link.key | translate }}
              </app-typography>
            </a>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-1 mb-4">
      <div class="flex justify-between gap-3">
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ 'LABEL.RELIGION' | translate }}</app-typography>
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ profile.attributes.religions | translateArray:'ENUMS.RELIGION' | async | arrayToString }}</app-typography>
      </div>
      <div class="flex justify-between gap-3">
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ 'LABEL.AGE' | translate }}</app-typography>
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ 'ENUMS.AGE_RANGE.' + profile.attributes.age | translate }}</app-typography>
      </div>
      <div class="flex justify-between gap-3">
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ 'LABEL.ETHNICITY' | translate }}</app-typography>
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ profile.attributes.ethnicity | translateArray:'ENUMS.ETHNICITY' | async | arrayToString }}</app-typography>
      </div>
      <div class="flex justify-between gap-3">
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ 'LABEL.LANGUAGE' | translate }}</app-typography>
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ profile.attributes.language | translateArray:'ENUMS.LANGUAGE' | async | arrayToString }}</app-typography>
      </div>
      <div class="flex justify-between gap-3">
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ 'LABEL.SEXUAL_ORIENTATION' | translate }}</app-typography>
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ profile.attributes.sexualOrientations | translateArray:'ENUMS.SEXUAL_ORIENTATION' | async | arrayToString }}</app-typography>
      </div>
      <div class="flex justify-between gap-3">
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ 'LABEL.LGBTQ_ADVOCATE' | translate }}</app-typography>
        <app-typography variant="p-sm" class="!text-[12px] md:!text-[14px]">{{ profile.attributes.lgbtqAdvocate | boolean | translate }}</app-typography>
      </div>
    </div>
    <div class="flex justify-between gap-2">
      <app-button
        *ngIf="showEmailBtn"
        variant="outlined"
        [size]="(userAgentQuery.selectIsMobile() | async) ? 'md' : 'lg'"
        class="!text-grayscale-200"
        (click)="doEmail()">
          {{ 'LABEL.MESSAGE' | translate }}
      </app-button>
      <app-button
        *ngIf="showDirectBookingBtn"
        variant="solid"
        [size]="(userAgentQuery.selectIsMobile() | async) ? 'md' : 'lg'"
        class="flex-1 w-full"
        [extUrl]="profile.links.BOOKING">
          {{ 'LABEL.BOOK_NOW' | translate }}
        </app-button>
    </div>
  </div>
  <div class="w-auto md:w-[70%] flex flex-col text-[12px] text-grayscale-800 p-4 md:p-6">
    <div class="flex justify-between mb-3">
      <div class="flex gap-2">
        <app-button
          [variant]="isAddedToFavourites ? 'solid' : 'outlined'"
          [size]="(userAgentQuery.selectIsMobile() | async) ? 'sm' : 'md'"
          (click)="addToFavourites()">
          {{ isAddedToFavourites | boolean:'LABEL.ADDED_TO_FAVOURITES':'LABEL.ADD_TO_FAVOURITES' | translate }}
        </app-button>
        <app-button
          variant="outlined"
          [size]="(userAgentQuery.selectIsMobile() | async) ? 'sm' : 'md'"
          [matMenuTriggerFor]="shareMenu">
            {{ 'LABEL.SHARE' | translate }}
        </app-button>
        <app-button
          *ngIf="profile.videoProfileURL"
          variant="outlined"
          [size]="(userAgentQuery.selectIsMobile() | async) ? 'sm' : 'md'"
          [extUrl]="profile.videoProfileURL">
            {{ 'THERAPIST_PROFILE.VIDEO_PROFILE' | translate }}
        </app-button>
      </div>
      <button class="absolute md:static top-0 right-0 bg-transparant md:bg-[#de424229] rounded-lg text-accent-red text-[24px] px-[12px]" (click)="close()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="flex-1 flex flex-col md:flex-row gap-2 mb-2">
      <div class="w-full md:w-[60%] bg-grayscale-300 border border-grayscale-400 rounded-lg p-4">
        <app-typography variant="p-sm-dark" class="mb-2">{{ 'THERAPIST_PROFILE.ABOUT_ME' | translate }}</app-typography>
        <div class="max-h-[160px] overflow-y-auto">
          <app-typography variant="p-sm" class="!text-[12px]">{{ profile.aboutFull }}</app-typography>
        </div>
      </div>
      <div class="w-full md:w-[40%] bg-grayscale-300 border border-grayscale-400 rounded-lg p-4">
        <app-typography variant="p-sm-dark" class="mb-2">{{ 'LABEL.AVAILABILITY' | translate }}</app-typography>
        <div class="max-h-[160px] overflow-y-auto">
          <div class="flex gap-2 mb-2">
            <div *ngIf="true" class="flex items-center gap-1 bg-green-dark rounded px-2 py-1">
              <i class="fa-regular fa-user text-green-light"></i>
              <app-typography variant="p-sm-dark" class="!text-[13px] text-green-light">{{ 'LABEL.INPERSON' | translate }}</app-typography>
            </div>
            <div *ngIf="true" class="flex items-center gap-1 bg-green-dark rounded px-2 py-1">
              <i class="fa-solid fa-video text-green-light"></i>
              <app-typography variant="p-sm-dark" class="!text-[13px] text-green-light">{{ 'LABEL.VIDEO' | translate }}</app-typography>
            </div>
            <div *ngIf="true" class="flex items-center gap-1 bg-green-dark rounded px-2 py-1">
              <i class="fa-solid fa-phone text-green-light"></i>
              <app-typography variant="p-sm-dark" class="!text-[12px] text-green-light">{{ 'LABEL.PHONE' | translate }}</app-typography>
            </div>
          </div>
          <div class="mb-3">
            <app-typography variant="p-sm-dark" class="!text-[12px] mb-1">{{ 'LABEL.DAYS' | translate }}</app-typography>
            <app-typography variant="p-sm" class="flex flex-wrap !text-[12px] mr-[2px]">{{ profile.attributes.daysOfWeek | translateArray:'ENUMS.DAYS_OF_WEEK' | async | arrayToString:undefined:7 }}</app-typography>
          </div>
          <div class="mb-3">
            <app-typography variant="p-sm-dark" class="!text-[12px] mb-1">{{ 'LABEL.TIMINGS' | translate }}</app-typography>
            <app-typography variant="p-sm" class="flex flex-wrap !text-[12px] mr-[2px]">{{ profile.attributes.timings | translateArray:'ENUMS.TIMINGS' | async | arrayToString:undefined:7 }}</app-typography>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 flex flex-col md:flex-row gap-2">
      <div class="w-full md:w-[60%] bg-grayscale-300 border border-grayscale-400 rounded-lg flex p-4">
        <div class="flex-1 flex flex-col gap-3">
          <app-attribute-list
            title="THERAPIST_PROFILE.TOP_SPECIALITIES"
            prefix="ENUMS.SPECIALITIES"
            [items]="profile.attributes.specialities">
          </app-attribute-list>
          <app-attribute-list
            title="THERAPIST_PROFILE.TYPES_OF_THERAPY"
            prefix="ENUMS.TYPES_OF_THERAPY"
            [items]="profile.attributes.typeOfTherapy">
          </app-attribute-list>
          <div>
            <app-typography variant="p-sm-dark" class="!text-[12px] mb-2">{{ 'THERAPIST_PROFILE.EDUCATION' | translate }}</app-typography>
            <ng-container *ngFor="let item of profile.attributes.academicCredentials; let i = index">
              <app-typography variant="p-sm" class="!text-[12px]" *ngIf="i < 2">
                {{ 'ENUMS.DEGREE_ATTENDANCE.' + item.degreeAttendance | translate }}, {{ item.graduationYear }}, {{ item.institution }},
                {{ 'ENUMS.CREDENTIAL_TYPE.' + item.level | translate }}
              </app-typography>
            </ng-container>
            <app-typography
              variant="p-sm-dark"
              class="cursor-pointer text-[13px] text-green-regular"
              *ngIf="profile.attributes.academicCredentials && profile.attributes.academicCredentials.length > 2"
              >{{ 'LABEL.VIEW_MORE' | translate }}</app-typography
            >
          </div>
        </div>
        <div class="flex-1 flex flex-col gap-3">
          <app-attribute-list
            title="THERAPIST_PROFILE.OTHER_EXPERTISE"
            prefix="ENUMS.SPECIALITIES"
            [items]="profile.attributes.areasOfExpertise">
          </app-attribute-list>
          <div>
            <app-typography variant="p-sm-dark" class="!text-[12px] mb-2">{{ 'THERAPIST_PROFILE.CLIENT_DEMOGRAPHIC' | translate }}</app-typography>
            <div class="flex flex-wrap">
              <app-typography variant="p-sm" class="!text-[12px] mr-[2px]">{{ profile.attributes.clientDemographic | translateArray:'ENUMS.CLIENT_DEMOGRAPHIC' | async | arrayToString }}</app-typography>
            </div>
          </div>
          <div>
            <app-typography variant="p-sm-dark" class="!text-[12px] mb-2">{{ 'THERAPIST_PROFILE.LICENSING' | translate }}</app-typography>
            <app-typography variant="p-sm" class="!text-[12px]">{{ profile.attributes.licenseIssuer }}</app-typography>
            <app-typography variant="p-sm" class="!text-[12px]">{{ profile.attributes.licenseNumber }}</app-typography>
          </div>
          <div>
            <app-typography variant="p-sm-dark" class="!text-[12px] mb-2">{{ 'THERAPIST_PROFILE.YEARS_PRACTICING' | translate }}: {{ profile.attributes.yearsOfPratice }}</app-typography>
          </div>
        </div>
      </div>
      <div class="w-full md:w-[40%] bg-grayscale-300 border border-grayscale-400 rounded-lg p-4">
        <app-typography variant="p-sm-dark" class="mb-2">{{ 'THERAPIST_PROFILE.PRICING_INFORMATION' | translate }}</app-typography>
        <div class="flex flex-col gap-3">
          <div class="flex justify-between">
            <div>
              <app-typography variant="p-sm-dark" class="!text-[12px] mb-1">{{ 'THERAPIST_PROFILE.SESSION_FEE' | translate }}</app-typography>
              <app-typography variant="p-sm" class="!text-[12px]">{{ profile.attributes.sessionFee?.value | currency: profile.attributes.sessionFee?.currency }}</app-typography>
            </div>
            <div>
              <app-typography variant="p-sm-dark" class="!text-[12px] mb-1">{{ 'THERAPIST_PROFILE.FREE_CONSULTATION' | translate }}</app-typography>
              <app-typography variant="p-sm" class="!text-[12px]">{{ profile.attributes.freeConsultation | boolean | translate }}</app-typography>
            </div>
            <div>
              <app-typography variant="p-sm-dark" class="!text-[12px] mb-1">{{ 'THERAPIST_PROFILE.DIRECT_BILLING' | translate }}</app-typography>
              <app-typography variant="p-sm" class="!text-[12px]">{{ profile.attributes.directBilling | boolean | translate }}</app-typography>
            </div>
          </div>
          <div>
            <app-typography variant="p-sm-dark" class="!text-[12px] mb-2">{{ 'THERAPIST_PROFILE.PAYMENT_METHODS' | translate }}</app-typography>
            <div class="flex flex-wrap">
              <app-typography variant="p-sm" class="!text-[12px] mr-[2px]">{{ profile.attributes.paymentMethods | translateArray:'ENUMS.PAYMENT_METHODS' | async | arrayToString:undefined:10 }}</app-typography>
            </div>
          </div>
          <div>
            <app-typography variant="p-sm-dark" class="!text-[12px] mb-2">{{ 'THERAPIST_PROFILE.INSURANCE_BILLING' | translate }}</app-typography>
            <div class="flex flex-wrap">
              <app-typography variant="p-sm" class="!text-[12px] mr-[2px]">{{ profile.attributes.insurance | translateArray:'ENUMS.INSURANCE_BILLING' | async | arrayToString:undefined:10 }}</app-typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


<mat-menu #shareMenu="matMenu" yPosition="above">
  <app-social-share-buttons></app-social-share-buttons>
</mat-menu>
