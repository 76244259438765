import { Component, OnInit, inject } from '@angular/core';
import { BaseFilterFormComponent } from '../base-filter-form';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserAgentQuery } from 'src/app/state/user-agent';
import { SharedService } from 'src/app/core/services/shared.service';
import { map } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent extends BaseFilterFormComponent implements OnInit {
  userAgentQuery = inject(UserAgentQuery);
  sharedService = inject(SharedService);

  ui$ = this.filterQuery.selectUi();
  disableClear$ = this.filterQuery.selectHasActiveFilters().pipe(map(has => !has));

  ngOnInit(): void {
    this.setupForm();
  }

  togglePanel(): void {
    this.filterService.toggleUi('panelOpen');
  }

  togglePriceRangeMenu(): void {
    this.filterService.toggleUi('priceRangeOpen');
  }

  formatLabel(value: number): string {
    return `$${value}`;
  }
}
