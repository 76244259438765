import { Component, Input, inject } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-label',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss']
})
export class FormLabelComponent {

  private readonly ngControl = inject(NgControl);

  @Input() label = '';

  get showRequired() {
    return this.ngControl.control?.hasValidator(Validators.required);
  }
}
