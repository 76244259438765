import { Component, OnInit, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Therapist } from '@therappy-inc/models';
import { AuthService } from 'src/app/state/auth';
import { UserAgentQuery } from 'src/app/state/user-agent';

@Component({
  selector: 'app-submit-request-modal',
  templateUrl: './submit-request-modal.component.html',
  styleUrls: ['./submit-request-modal.component.scss'],
})
export class SubmitRequestModalComponent implements OnInit {
  private readonly authService = inject(AuthService);
  userAgentQuery = inject(UserAgentQuery);
  data = inject<Therapist[]>(MAT_DIALOG_DATA);
  form = new FormGroup({
    subject: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required]),
  });
  isSubmitted = false;

  recipients: string[] = [];
  recipientEmails: string[] = [];

  ngOnInit() {
    this.data.forEach(therapist => {
      this.authService.get(therapist.userId).then(user => {
        if (user) {
          this.recipients = [...this.recipients, user.name];
          this.recipientEmails = [...this.recipientEmails, user.email];
        }
      });
    });
  }

  sendMail() {
    /**
     * TODO: Send an email to the recipients
     * ToEmails: this.recipientEmails
     * Subject: this.form.subject.value
     * Message: this.form.subject.message
     */
    this.isSubmitted = true;
  }
}
