import { collection, CollectionReference, doc, Firestore, getDoc, UpdateData, writeBatch, WriteBatch } from '@angular/fire/firestore';
import { WriteOptions } from '../lib/utils/types';
import { inject, Injectable } from '@angular/core';
import { Patient } from '@therappy-inc/models';
import { PatientStore } from './patient.store';
import { CollectionConfig } from '../lib/collection/fire-collection.config';
import { Observable } from 'rxjs';
import { docValueChanges } from '../lib/utils/firestore';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'patients' })
export class PatientService {
  private readonly collection: CollectionReference<any>;
  protected collectionPath = 'patients';
  protected includeMetadataChanges = false;
  protected db: Firestore;
  /** Triggered when the patient has been created */
  protected onCreate?(patient: Patient, options: WriteOptions): any;
  /** Triggered when the patient has been updated */
  protected onUpdate?(patient: Partial<Patient>, options: WriteOptions): any;
  /** Triggered when the patient has been deleted */
  protected onDelete?(options: WriteOptions): any;

  /** The path to the patient in firestore */
  get path() {
    return (this.constructor as any)['path'] || this.collectionPath;
  }

  constructor(
    private store: PatientStore,
    db?: Firestore,
  ) {
    this.db = db || inject(Firestore);
    this.collection = collection(this.db, this.path);
  }

  /**
   * Select the patient in the Firestore
   * @note can be override to point to a different place
   */
  protected selectPatient(patient: Patient): Observable<Patient> {
    const ref = doc(this.collection, patient.id);
    return docValueChanges<Patient>(ref, {
      includeMetadataChanges: this.includeMetadataChanges,
    });
  }

  /**
   * Function triggered when getting data from firestore
   * @note should be overwritten
   */
  protected formatFromFirestore(patient: Patient): Patient {
    return patient;
  }

  /**
   * Function triggered when adding/updating data to firestore
   * @note should be overwritten
   */
  protected formatToFirestore(patient: Partial<Patient>): UpdateData<Patient> {
    return patient;
  }

  async get(id: string): Promise<Patient | void> {
    const ref = doc(this.collection, id);
    const document = await getDoc(ref);
    if (document.exists()) {
      return this.formatFromFirestore(document.data());
    }
    return;
  }

  /** Create a patient */
  async create(patient: Patient, options: WriteOptions = {}): Promise<string> {
    const { write = writeBatch(this.db), ctx } = options;
    const ref = doc(this.collection);
    const id = ref.id;
    patient = { ...patient, id };
    (write as WriteBatch).set(ref, this.formatToFirestore(patient));
    if (this.onCreate) {
      await this.onCreate(patient, { write, ctx });
    }
    if (!options.write) {
      await (write as WriteBatch).commit();
    }
    this.updateStore(patient);
    return id;
  }

  /** Update the current patient */
  /** this.patientService.update({ id: 'RVg85dAPRrlksvLFMncl',   gender: Gender.MALE }); */
  async update(patient: Partial<Patient>, options: WriteOptions = {}) {
    if (!patient.id) {
      throw new Error('No patient found.');
    }
    const ref = doc(this.collection, patient.id);
    const { write = writeBatch(this.db), ctx } = options;
    (write as WriteBatch).update(ref, this.formatToFirestore(patient));
    if (this.onUpdate) {
      await this.onUpdate(patient, { write, ctx });
    }
    const document = await getDoc(ref);
    if (document.exists()) {
      const curPatient = this.formatFromFirestore(document.data());
      const newPatient = {
        ...curPatient,
        ...patient,
      };
      this.updateStore(newPatient);
    }
    // If there is no atomic write provided
    if (!options.write) {
      return (write as WriteBatch).commit();
    }
  }

  /**
   * @description Delete patient from database
   */
  async delete(id: string, options: WriteOptions = {}) {
    if (!id) {
      throw new Error('No patient found');
    }
    const { write = writeBatch(this.db), ctx } = options;
    const ref = doc(this.collection, id);
    write.delete(ref);
    if (this.onDelete) {
      await this.onDelete({ write, ctx });
    }
    if (!options.write) {
      await (write as WriteBatch).commit();
    }
    this.reset();
  }

  updateStore(partial: Partial<Patient>): void {
    this.store.update(state => ({ ...state, ...partial }));
  }

  reset(): void {
    this.store.reset();
  }
}
