<div class="sticky z-50 top-0 backdrop-blur-[32px] border-b border-grayscale-400 p-4">
  <app-filter-bar></app-filter-bar>
  <div>
    <app-results-count></app-results-count>
    <div><!-- TODO: Sort Control --></div>
  </div>
</div>
<div class="flex items-start md:block bg-[#fefefecc] px-[16px] md:px-[80px] pt-[16px] md:pt-[24px] pb-[16px] md:pb-[24px]">
  <app-result-list class="z-0"></app-result-list>
</div>
<app-filter-panel></app-filter-panel>
