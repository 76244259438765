import { Component, Input, inject } from '@angular/core';
import { DialogsService } from 'src/app/core/services/dialogs.service';

@Component({
  selector: 'app-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrls: ['./attribute-list.component.scss'],
})
export class AttributeListComponent {
  @Input() title = '';
  @Input() prefix = '';
  @Input() showMax = 2;
  @Input() showNumbers = true;
  @Input() items: string[] | undefined = [];

  private readonly dialogs = inject(DialogsService);

  showMore(): void {
    this.dialogs
      .showMoreAttributes({
        items: this.items || [],
        prefix: this.prefix,
        title: this.title,
      })
      .subscribe();
  }

  get showViewMoreLink(): boolean {
    return !!this.items && this.items?.length > 0;
  }
}
