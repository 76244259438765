import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolean',
})
export class BooleanPipe implements PipeTransform {
  transform(value: boolean | undefined, trueVal = 'LABEL.YES', falseVal = 'LABEL.NO'): string {
    return value ? trueVal : falseVal;
  }
}
