import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarComponent, SnackBarData } from 'src/app/shared/components/snack-bar/snack-bar.component';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private readonly _snackBar = inject(MatSnackBar);

  success(message: string): void {
    this.open(
      createSnackbarConfig({
        panelClass: ['snackbar-success'],
        data: {
          message,
          icon: 'check_circle_outline',
          color: 'succcess',
        },
      }),
    );
  }

  error(message: string): void {
    this.open(
      createSnackbarConfig({
        duration: 7500,
        panelClass: ['snackbar-error'],
        data: {
          message,
          icon: 'error_outline',
          color: 'warn',
        },
      }),
    );
  }

  clear(): void {
    this._snackBar.dismiss();
  }

  open(config: MatSnackBarConfig<SnackBarData>): MatSnackBarRef<SnackBarComponent> {
    return this._snackBar.openFromComponent(SnackBarComponent, config);
  }
}

/** Creates the default Snackbar configuration */
export function createSnackbarConfig<D>(config: MatSnackBarConfig<D>): MatSnackBarConfig<D> {
  return {
    horizontalPosition: 'center',
    verticalPosition: 'top',
    duration: 2500,
    ...config,
  };
}
