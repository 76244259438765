<div class="relative max-w-[423px] max-h-[504px] bg-grayscale-800 text-grayscale-200 p-4 md:p-8">
  <i class="fa-solid fa-xmark absolute right-8 text-xl cursor-pointer" mat-dialog-close></i>
  <div class="flex items-center gap-3 mb-2">
    <i class="fa-solid fa-up-right-from-square"></i>
    <app-typography variant="h4">{{ 'LABEL.BOOK_NOW' | translate }}</app-typography>
  </div>
  <div class="max-h-[280px] overflow-y-auto">
    <app-typography variant="p" class="mb-6">{{ data.text }}</app-typography>
  </div>
  <div class="flex gap-4 mt-6">
    <app-button variant="outlined" [size]="userAgentQuery.selectIsMobile() ? 'md' : 'lg'" class="!text-grayscale-200" mat-dialog-close>{{ 'LABEL.CANCEL' | translate }}</app-button>
    <app-button variant="solid" [size]="userAgentQuery.selectIsMobile() ? 'md' : 'lg'" [mat-dialog-close]="true">{{ data.cta }}</app-button>
  </div>
</div>
