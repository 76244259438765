import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { isObject, isString } from '@datorama/akita';
import { SVG_ICONS } from '../di/svg-icons.token';

@Injectable({ providedIn: 'root' })
export class SvgIconsService {
  constructor(
    @Inject(SVG_ICONS) private manifest: SvgIconManifest,
    @Inject(PLATFORM_ID) private plaformId: string,
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.registerIcons();
  }

  private registerIcons(): void {
    const asArray = Object.entries(this.manifest);
    const icons: [string, string, string?][] = asArray.filter(([_, pathOrNamespace]) => isString(pathOrNamespace)) as [string, string][];
    const namespaced = asArray.filter(([_, pathOrNamespace]) => isObject(pathOrNamespace)) as [string, Record<string, string>][];

    for (const [namespace, subcollection] of namespaced) {
      const entries = Object.entries(subcollection);
      for (const [name, path] of entries) {
        icons.push([name, path, namespace]);
      }
    }

    icons.forEach(([name, path, namespace]) => this.addToRegistry(name, path, namespace));
  }

  private addToRegistry(name: string, path: string, namespace?: string | undefined): void {
    // Bypass SSR rendering which causes issues on server
    // See: https://github.com/angular/components/issues/9728#issuecomment-592402707
    if (isPlatformServer(this.plaformId)) {
      const emptySvg = this.domSanitizer.bypassSecurityTrustHtml('<svg></svg>');
      this.addSvgLiteral(name, emptySvg, namespace);
    } else {
      const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(path);
      this.addSvgIcon(name, safeUrl, namespace);
    }
  }

  private addSvgIcon(name: string, safeUrl: SafeResourceUrl, namespace: string | undefined): void {
    if (namespace) {
      this.iconRegistry.addSvgIconInNamespace(namespace, name, safeUrl);
    } else {
      this.iconRegistry.addSvgIcon(name, safeUrl);
    }
  }

  private addSvgLiteral(name: string, literal: SafeHtml, namespace: string | undefined): void {
    if (namespace) {
      this.iconRegistry.addSvgIconLiteralInNamespace(namespace, name, literal);
    } else {
      this.iconRegistry.addSvgIconLiteral(name, literal);
    }
  }
}

export type SvgIconManifest = Record<string, string | Record<string, string>>;

export function createSvgManifest<T extends SvgIconManifest>(manifest: T): T {
  return manifest;
}
