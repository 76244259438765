import { InjectionToken } from '@angular/core';
import { enCA } from 'date-fns/locale';

export const LOCALES = new InjectionToken<LocalesMap>('LOCALES', {
  providedIn: 'root',
  factory: () => new Map([DEFAULT_LOCALE]),
});

export const DEFAULT_LOCALE: [string, LocaleRegistration] = ['en', { title: 'English', dateLocale: enCA }];

export interface LocaleRegistration {
  title: string;
  dateLocale: Locale;
}

export type LocalesMap = Map<string, LocaleRegistration>;
