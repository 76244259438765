<app-form-label
  [label]="label"
/>
<input
  class="w-full border rounded-lg bg-grayscale-200 px-4 py-2"
  [type]="valueType"
  [placeholder]="placeholder || 'PLACEHOLDER.' + valueType | uppercase | translate"
  [formControl]="control"
/>
<app-form-error />
