import { Component, Input, inject } from '@angular/core';
import { ResultsService, createInitialResult } from 'src/app/state/search';
import { TherapistSearchEventsService } from '../../therapist-search-events.service';
import { Attributes } from '@therappy-inc/models';

@Component({
  selector: 'app-result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss'],
})
export class ResultCardComponent {
  isHovering = false;
  showCheckbox = true;
  isSupervisor = false;

  private readonly resultsService = inject(ResultsService);
  private readonly events = inject(TherapistSearchEventsService);

  @Input() item = createInitialResult();

  toggleCheckbox(): void {
    this.resultsService.toggleKey(this.id, 'isSelected');
  }

  toggleFavorite(): void {
    this.resultsService.toggleKey(this.id, 'isFavorite');
  }

  openEmail(): void {
    //
  }

  emitClick(): void {
    this.events.emit('itemClick', this.item);
  }

  emitDblClick(): void {
    this.events.emit('itemDblClick', this.item);
  }

  get id(): string {
    return this.item.objectID;
  }

  get attributes(): Partial<Attributes> {
    return this.item.attributes;
  }
}
