export interface LocalState {
  locale: string;
  currency: string;
}

export function createInitialState(): LocalState {
  return {
    locale: 'en',
    currency: 'CAD',
  };
}
