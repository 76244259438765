import { UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'plural',
  pure: false,
})
export class PluralPipe implements PipeTransform {
  private static getModifier(value: number): string {
    switch (value) {
      case 0:
        return 'ZERO';
      case 1:
        return 'ONE';
      default:
        return 'MANY';
    }
  }

  constructor(
    private upperCase: UpperCasePipe,
    private translate: TranslateService,
  ) {}

  transform(value: number | null, item: string): string | null {
    if (typeof value !== 'number') {
      return null;
    }

    const itemUpper = this.upperCase.transform(item);
    const modifier = PluralPipe.getModifier(value);
    const key = `PLURAL.${itemUpper}.${modifier}`;

    return this.translate.instant(key, { value });
  }
}
