<div class="relative flex flex-col gap-[24px] w-[856px] bg-grayscale-800 text-grayscale-200 p-4 md:p-8">
  <i class="fa-solid fa-xmark absolute right-8 text-xl cursor-pointer" mat-dialog-close></i>
  <div class="flex items-center gap-3">
    <i class="fa-regular fa-paper-plane text-[20px]"></i>
    <app-typography variant="h4">{{ 'SUBMIT_REQUEST_MODAL.TITLE' | translate }}</app-typography>
  </div>
  <app-typography *ngIf="!isSubmitted" variant="p-dark">{{ 'SUBMIT_REQUEST_MODAL.MESSAGE_TO_RECIPIENTS' | translate }}</app-typography>
  <form class="flex flex-col gap-6" [formGroup]="form">
    <div class="flex flex-col justify-center">
      <app-typography variant="p-sm">{{ 'LABEL.RECIPIENTS' | translate }}</app-typography>
      <input
        type="text"
        class="border rounded-lg bg-grayscale-200 focus:outline-none focus:border focus:border-grayscale-400 font-montserrat leading-5 text-xs font-normal text-grayscale-900 px-4 py-2 mt-1"
        [value]="recipients | mapToStrings"
        readonly />
    </div>
    <ng-container *ngIf="!isSubmitted">
      <div class="flex flex-col justify-center">
        <app-typography variant="p-sm">{{ 'SUBMIT_REQUEST_MODAL.EMAIL_SUBJECT' | translate }}</app-typography>
        <input
          type="text"
          formControlName="subject"
          [placeholder]="'PLACEHOLDER.TYPE_HERE' | translate"
          class="border rounded-lg bg-grayscale-200 focus:outline-none focus:border focus:border-grayscale-400 font-montserrat leading-5 text-xs font-normal text-grayscale-900 px-4 py-2 mt-1"
          required />
      </div>
      <div class="flex flex-col justify-center">
        <app-typography variant="p-sm">{{ 'SUBMIT_REQUEST_MODAL.EMAIL_MESSAGE' | translate }}</app-typography>
        <textarea
          type="text"
          rows="10"
          cols="40"
          [placeholder]="'PLACEHOLDER.TYPE_HERE' | translate"
          class="border rounded-lg bg-grayscale-200 focus:outline-none focus:border focus:border-grayscale-400 font-montserrat leading-5 text-xs font-normal text-grayscale-900 px-4 py-2 mt-1"
          formControlName="message">
        </textarea>
      </div>
    </ng-container>
    <div *ngIf="isSubmitted" class="text-center my-[30px]">
      <app-typography variant="h4">{{ 'SUBMIT_REQUEST_MODAL.THANK_YOU' | translate }}</app-typography>
      <app-typography variant="p-sm" class="mb-[30px]">{{ 'SUBMIT_REQUEST_MODAL.YOUR_MESSAGE_SENT_TO' | translate }} {{ recipients | mapToStrings }}.</app-typography>
      <app-typography variant="p-sm"
        >{{ 'SUBMIT_REQUEST_MODAL.YOUR_MESSAGE_SENT_TO_DESCRIPTION' | translate }}</app-typography
      >
    </div>
  </form>
  <div *ngIf="!isSubmitted" class="flex gap-4">
    <app-button variant="outlined" [size]="(userAgentQuery.selectIsMobile() | async) ? 'md' : 'lg'" class="!text-grayscale-200" mat-dialog-close>{{ 'LABEL.CANCEL' | translate }}</app-button>
    <app-button variant="solid" [size]="(userAgentQuery.selectIsMobile() | async) ? 'md' : 'lg'" [disabled]="!form.valid" (onClick)="sendMail()">{{ 'LABEL.SEND' | translate }}</app-button>
  </div>
  <div *ngIf="isSubmitted" class="flex justify-center">
    <app-button variant="solid" [size]="(userAgentQuery.selectIsMobile() | async) ? 'md' : 'lg'" [mat-dialog-close]="true">{{ 'LABEL.CLOSE' | translate }}</app-button>
  </div>
</div>
