import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserAgentQuery } from 'src/app/state/user-agent';

@Component({
  selector: 'app-book-now-modal',
  templateUrl: './book-now-modal.component.html',
  styleUrls: ['./book-now-modal.component.scss'],
})
export class BookNowModalComponent {
  userAgentQuery = inject(UserAgentQuery);
  data = inject<{ text: string; cta: string }>(MAT_DIALOG_DATA);
}
