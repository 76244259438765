import { Injectable, inject } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DEFAULT_LOCALE, LocaleRegistration, LOCALES, LocalesMap } from 'src/app/core/di/locale.token';
import { LocalState } from './local-state.model';
import { LocalStateStore } from './local-state.store';

@Injectable({ providedIn: 'root' })
export class LocalStateQuery extends Query<LocalState> {
  locales = inject<LocalesMap>(LOCALES);

  constructor(protected override store: LocalStateStore) {
    super(store);
  }

  selectLocaleRegistration(): Observable<LocaleRegistration> {
    return this.select('locale').pipe(map(() => this.getLocaleRegistration()));
  }

  get<K extends keyof LocalState>(key: K): LocalState[K] {
    return this.getValue()[key];
  }

  getLocaleRegistration(): LocaleRegistration {
    const key = this.get('locale');
    return this.locales.get(key) || DEFAULT_LOCALE[1];
  }

  getLocaleExists(locale: string): boolean {
    return this.locales.has(locale);
  }
}
