import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-learn-more-card',
  templateUrl: './learn-more-card.component.html',
  styleUrls: ['./learn-more-card.component.scss'],
})
export class LearnMoreCardComponent {
  @Input() title = '';
  @Input() subTitle = '';
  @Output() learnMoreClick = new EventEmitter();
}
