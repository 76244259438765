import { AuthError, User as FirebaseUser } from '@angular/fire/auth';
import { AgeRange, Gender, User } from '@therappy-inc/models';

export function createUser(data: FirebaseUser, { name }: Partial<SignupForm> = {}): User {
  return {
    ...createDefaultUser(),
    name: name || data.displayName || '',
    email: data.email || '',
    phone: data.phoneNumber || '',
    photoURL: data.photoURL || '',
    uid: data.uid,
  };
}

export function createDefaultUser(): User {
  return {
    name: '',
    email: '',
    photoURL: '',
    uid: '',
    phone: '',
  };
}

export interface AuthForm {
  email: string;
  password: string;
}

export interface SignupForm extends AuthForm {
  name: string;
  age?: AgeRange;
  gender?: Gender;
  acceptPrivacyPolicy: boolean;
}

export function getFirebaseErrorTranslation({ code }: AuthError): string {
  const keys = code.toUpperCase().split('/');
  const key = keys.join('.');
  return `ERRORS.${key}`;
}

export enum AuthActionType {
  Signin = 'signin',
  Signout = 'signout',
  Signup = 'signup',
  Reauthenticate = 'reauthenticate',
}

// Should be extended as needed
export interface CustomClaims {
  admin: boolean;
}
