import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() disabled: boolean | null = false;
  @Input() active = false;
  @Input() variant: 'solid' | 'outlined' | 'rounded' | 'text' = 'solid';
  @Input() size: 'sm' | 'md' | 'lg' = 'lg';
  @Input() url?: string;
  @Input() extUrl?: string;
  @Input() queryParams?: any;
  @Input() class?: string;
  @Input() spinner = false;
  @Input() progress: number | undefined;

  getButtonClasses() {
    let classes = `block font-montserrat font-medium rounded-lg w-auto h-auto transition-colors	ease-in-out duration-500 `;
    // checking button size
    if (this.size == 'lg') {
      classes += 'text-base leading-6 py-3 px-8 ';
    } else if (this.size == 'md') {
      classes += 'text-xs leading-5 py-2.5 px-8 ';
    } else {
      classes += 'text-xxxs leading-4 py-2 px-4 ';
    }

    // checking button variant
    if (this.disabled) {
      classes += 'bg-green-regular text-greenscale-600 cursor opacity-50';
    } else if (this.variant == 'solid') {
      classes += 'bg-green-regular text-grayscale-200 hover:bg-green-dark disabled:bg-grayscale-400 disabled:text-grayscale-600 disabled:cursor-not-allowed';
    } else if (this.variant == 'outlined') {
      classes += 'border-solid border-[0.5px] border-green-regular text-green-regular hover:bg-green-regular hover:text-grayscale-200 ';
    } else if (this.variant == 'rounded') {
      classes += 'flex !rounded-full !p-[8px] border-solid border-[0.5px] border-green-regular text-green-regular hover:bg-green-regular hover:text-grayscale-200 ';
    } else {
      if (this.active) {
        classes += '!font-semibold !text-green-regular !bg-grayscale-300';
      } else {
        classes += '!font-normal text-grayscale-900 active:text-green-regular active:bg-grayscale-300';
      }
    }
    return classes + ' ' + this.class;
  }
}
