import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  styleUrls: ['./multi-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiCheckboxComponent),
      multi: true,
    },
  ],
})
export class MultiCheckboxComponent implements ControlValueAccessor {
  @Input() options: string[] = [];
  @Input() translateKey = '';
  selectedOptions: string[] = [];

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(val: string[]) {
    if (val) {
      this.selectedOptions = val;
    } else {
      this.selectedOptions = [];
    }
  }

  onchange(evt: any, option: string) {
    if (evt.target.checked) {
      if (this.selectedOptions.findIndex(op => op === option) < 0) {
        this.selectedOptions = [...this.selectedOptions, option];
      }
    } else {
      this.selectedOptions = this.selectedOptions.filter(op => op !== option);
    }
    this.onChange(this.selectedOptions);
  }
}
