import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Therapist, createInitialTherapist } from '@therappy-inc/models';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'therapist', resettable: true })
export class TherapistStore extends Store<Therapist> {
  constructor() {
    super(createInitialTherapist());
  }
}
