import { collection, CollectionReference, doc, Firestore, getDoc, UpdateData, writeBatch, WriteBatch } from '@angular/fire/firestore';
import { WriteOptions } from '../lib/utils/types';
import { inject, Injectable } from '@angular/core';
import { Therapist } from '@therappy-inc/models';
import { TherapistStore } from './therapist.store';
import { CollectionConfig } from '../lib/collection/fire-collection.config';
import { Observable } from 'rxjs';
import { docValueChanges } from '../lib/utils/firestore';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'therapists' })
export class TherapistService {
  private readonly collection: CollectionReference<any>;
  protected collectionPath = 'therapists';
  protected includeMetadataChanges = false;
  protected db: Firestore;
  /** Triggered when the therapist has been created */
  protected onCreate?(therapist: Therapist, options: WriteOptions): any;
  /** Triggered when the therapist has been updated */
  protected onUpdate?(therapist: Partial<Therapist>, options: WriteOptions): any;
  /** Triggered when the therapist has been deleted */
  protected onDelete?(options: WriteOptions): any;

  /** The path to the therapist in firestore */
  get path() {
    return (this.constructor as any)['path'] || this.collectionPath;
  }

  constructor(
    private store: TherapistStore,
    db?: Firestore,
  ) {
    this.db = db || inject(Firestore);
    this.collection = collection(this.db, this.path);
  }

  /**
   * Select the therapist in the Firestore
   * @note can be override to point to a different place
   */
  protected selectTherapist(therapist: Therapist): Observable<Therapist> {
    const ref = doc(this.collection, therapist.id);
    return docValueChanges<Therapist>(ref, {
      includeMetadataChanges: this.includeMetadataChanges,
    });
  }

  /**
   * Function triggered when getting data from firestore
   * @note should be overwritten
   */
  protected formatFromFirestore(therapist: Therapist): Therapist {
    return therapist;
  }

  /**
   * Function triggered when adding/updating data to firestore
   * @note should be overwritten
   */
  protected formatToFirestore(therapist: Partial<Therapist>): UpdateData<Therapist> {
    return therapist;
  }

  async get(id: string): Promise<Therapist | void> {
    const ref = doc(this.collection, id);
    const document = await getDoc(ref);
    if (document.exists()) {
      return this.formatFromFirestore(document.data());
    }
    return;
  }

  /** Create a therapist */
  async create(therapist: Therapist, options: WriteOptions = {}): Promise<string> {
    const { write = writeBatch(this.db), ctx } = options;
    const ref = doc(this.collection);
    const id = ref.id;
    therapist = { ...therapist, id };
    (write as WriteBatch).set(ref, this.formatToFirestore(therapist));
    if (this.onCreate) {
      await this.onCreate(therapist, { write, ctx });
    }
    if (!options.write) {
      await (write as WriteBatch).commit();
    }
    this.updateStore(therapist);
    return id;
  }

  /** Update the current therapist */
  async update(therapist: Partial<Therapist>, options: WriteOptions = {}) {
    if (!therapist.id) {
      throw new Error('No therapist found.');
    }
    const ref = doc(this.collection, therapist.id);
    const { write = writeBatch(this.db), ctx } = options;
    (write as WriteBatch).update(ref, this.formatToFirestore(therapist));
    if (this.onUpdate) {
      await this.onUpdate(therapist, { write, ctx });
    }
    const document = await getDoc(ref);
    if (document.exists()) {
      const curTherapist = this.formatFromFirestore(document.data());
      const newTherapist = {
        ...curTherapist,
        ...therapist,
      };
      this.updateStore(newTherapist);
    }
    // If there is no atomic write provided
    if (!options.write) {
      return (write as WriteBatch).commit();
    }
  }

  /**
   * @description Delete therapist from database
   */
  async delete(id: string, options: WriteOptions = {}) {
    if (!id) {
      throw new Error('No therapist found');
    }
    const { write = writeBatch(this.db), ctx } = options;
    const ref = doc(this.collection, id);
    write.delete(ref);
    if (this.onDelete) {
      await this.onDelete({ write, ctx });
    }
    if (!options.write) {
      await (write as WriteBatch).commit();
    }
    this.reset();
  }

  updateStore(therapist: Partial<Therapist>): void {
    this.store.update(state => ({ ...state, ...therapist }));
  }

  reset(): void {
    this.store.reset();
  }
}
