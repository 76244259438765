import { Injectable, inject } from '@angular/core';
import { AuthActionType, AuthQuery } from '../auth';
import { FlowRoutingService } from './flow-routing.service';

@Injectable({ providedIn: 'root' })
export class FlowEventsService {

  private readonly authQuery = inject(AuthQuery);
  private readonly flowRouting = inject(FlowRoutingService);

  listen(): void {
    this.authQuery.selectAuthAction().subscribe(action => this.handleAuthAction(action));
  }

  private handleAuthAction(action: AuthActionType): void {
    switch (action) {
      case AuthActionType.Signin:
        return this.flowRouting.onSignIn();
      case AuthActionType.Signout:
        return this.flowRouting.onSignOut();
      case AuthActionType.Signup:
        return this.flowRouting.onSignUp();
    }
  }
}
