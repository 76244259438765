import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Patient, createInitialPatient } from '@therappy-inc/models';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patient', resettable: true })
export class PatientStore extends Store<Patient> {
  constructor() {
    super(createInitialPatient());
  }
}
