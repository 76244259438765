import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExperimentationComponent } from './experimentation.component';
import { dialogResolver } from '../core/resolvers/dialog.resolver';
import { TherapistProfileComponent } from '../dialogs/therapist-profile/therapist-profile.component';
import { DEMO_THERAPIST } from '../state/therapist/therapist.model';

const routes: Routes = [
  {
    path: '',
    component: ExperimentationComponent,
    data: { title: 'Experimentation' },
    children: [
      {
        path: 'profile/:therapistId',
        resolve: { dialog: dialogResolver },
        component: TherapistProfileComponent,
        data: {
          dialog: {
            data: { profile: DEMO_THERAPIST },
            width: '80%',
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExperimentationRoutingModule {}
