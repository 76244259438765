<ng-container *appLet="ui$ | async as ui">
<div class="custom-sidepopup-backdrop" (click)="close()" [class.show]="ui?.panelOpen"></div>
<div class="custom-sidepopup" [class.show]="ui?.panelOpen" [formGroup]="form">
  <div class="popup-content">
    <div class="w-screen md:w-[640px] h-full flex flex-col justify-between p-[24px]">
      <div class="flex flex-col gap-[24px]">
        <div class="flex justify-between">
          <div class="flex items-center gap-2">
            <i class="fa-solid fa-sliders text-green-regular"></i>
            <app-typography variant="p-dark" >{{ 'FIND_THERAPIST.FILTERS.EXPANDED_FILTERS' | translate }}</app-typography>
          </div>
          <i class="fa-solid fa-xmark cursor-pointer" (click)="close()"></i>
        </div>
        <app-filter-chips></app-filter-chips>
        <app-results-count></app-results-count>
        <div class="h-[calc(100vh-190px)] md:h-auto overflow-x-hidden overflow-y-auto md:overflow-y-auto">
          <div class="flex flex-col gap-4">
            <app-typography variant="p-sm-dark">{{ 'LABEL.PERSONAL_DETAILS' | translate }}</app-typography>
            <div class="flex flex-col md:flex-row gap-4">
              <div class="flex-1 flex flex-col justify-center">
                <app-typography variant="p-sm">{{ 'LABEL.AGE_RANGE' | translate }}</app-typography>
                <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.ageRangeList" formControlName="ageRange">
                  <ng-template ng-label-tmp let-item="item">
                    <div>
                      {{ 'ENUMS.AGE_RANGE.' + item | translate }}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>
                      {{ 'ENUMS.AGE_RANGE.' + item | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="flex-1 flex flex-col justify-center">
                <app-typography variant="p-sm">{{ 'LABEL.ETHNICITY' | translate }}</app-typography>
                <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.ethnicityList" formControlName="ethnicity">
                  <ng-template ng-label-tmp let-item="item">
                    <div>
                      {{ 'ENUMS.ETHNICITY.' + item | translate }}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>
                      {{ 'ENUMS.ETHNICITY.' + item | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="flex flex-col md:flex-row gap-4">
              <div class="flex-1 flex flex-col justify-center">
                <app-typography variant="p-sm">{{ 'LABEL.GENDER' | translate }}</app-typography>
                <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.genderList" formControlName="gender">
                  <ng-template ng-label-tmp let-item="item">
                    <div>
                      {{ 'ENUMS.GENDER.' + item | translate }}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>
                      {{ 'ENUMS.GENDER.' + item | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="flex-1 flex flex-col justify-center">
                <app-typography variant="p-sm">{{ 'LABEL.LANGUAGE_SPOKEN' | translate }}</app-typography>
                <ng-select
                  class="mt-1"
                  [placeholder]="'PLACEHOLDER.SELECT_ALL_THAT_APPLY' | translate"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  [searchable]="true"
                  [items]="sharedService.languageList"
                  formControlName="language">
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
                      <span class="ng-value-label">{{ 'ENUMS.LANGUAGE.' + item | translate }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)">×</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 1">
                      <span class="ng-value-label">{{ items.length - 1 }} {{ 'LABEL.MORE' | translate }}...</span>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>
                      {{ 'ENUMS.LANGUAGE.' + item | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="flex flex-col md:flex-row gap-4">
              <div class="flex-1 flex flex-col justify-center">
                <app-typography variant="p-sm">{{ 'LABEL.RELIGION' | translate }}</app-typography>
                <ng-select class="mt-1" [multiple]="true" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.religionList" formControlName="religion">
                  <ng-template ng-label-tmp let-item="item">
                    <div>
                      {{ 'ENUMS.RELIGION.' + item | translate }}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>
                      {{ 'ENUMS.RELIGION.' + item | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="flex-1 flex flex-col justify-center">
                <app-typography variant="p-sm">{{ 'LABEL.SEXUAL_ORIENTATION' | translate }}</app-typography>
                <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.sexualOrientationList" formControlName="sexualOrientation">
                  <ng-template ng-label-tmp let-item="item">
                    <div>
                      {{ 'ENUMS.SEXUAL_ORIENTATION.' + item | translate }}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>
                      {{ 'ENUMS.SEXUAL_ORIENTATION.' + item | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <app-typography variant="p-sm-dark">{{ 'LABEL.PRACTICE_DETAILS' | translate }}</app-typography>
            <div class="flex flex-col md:flex-row gap-4">
              <div class="flex-1 flex flex-col justify-center">
                <app-typography variant="p-sm">{{ 'LABEL.CLIENT_DEMOGRAPHIC' | translate }}</app-typography>
                <ng-select
                  class="mt-1"
                  [placeholder]="'PLACEHOLDER.SELECT_ALL_THAT_APPLY' | translate"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  [searchable]="true"
                  [items]="sharedService.clientDemographicList"
                  formControlName="clientDemographic">
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
                      <span class="ng-value-label">{{ 'ENUMS.CLIENT_DEMOGRAPHIC.' + item | translate }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)">×</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 1">
                      <span class="ng-value-label">{{ items.length - 1 }} {{ 'LABEL.MORE' | translate }}...</span>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>
                      {{ 'ENUMS.CLIENT_DEMOGRAPHIC.' + item | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="flex-1 flex flex-col justify-center">
                <app-typography variant="p-sm">{{ 'LABEL.INSURANCE' | translate }}</app-typography>
                <ng-select
                  class="mt-1"
                  [placeholder]="'PLACEHOLDER.SELECT_ALL_THAT_APPLY' | translate"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  [searchable]="true"
                  [items]="sharedService.insuranceBillingList"
                  formControlName="insurance">
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
                      <span class="ng-value-label">{{ 'ENUMS.INSURANCE_BILLING.' + item | translate }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)">×</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 1">
                      <span class="ng-value-label">{{ items.length - 1 }} {{ 'LABEL.MORE' | translate }}...</span>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>
                      {{ 'ENUMS.INSURANCE_BILLING.' + item | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="flex flex-col md:flex-row gap-4">
              <div class="flex-1 flex flex-col justify-center">
                <app-typography variant="p-sm">{{ 'LABEL.DISTANCE' | translate }}</app-typography>
                <ng-select class="mt-1" [placeholder]="'PLACEHOLDER.SELECT_OPTION' | translate" [searchable]="true" [items]="sharedService.distanceList" formControlName="distance">
                  <ng-template ng-label-tmp let-item="item">
                    <div>
                      {{ 'ENUMS.DISTANCE.' + item | translate }}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>
                      {{ 'ENUMS.DISTANCE.' + item | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="flex-1 flex flex-col justify-center"></div>
            </div>
            <div>
              <app-typography variant="p-sm" class="mb-1">{{ 'LABEL.APPOINTMENT_TYPE' | translate }}</app-typography>
              <app-multi-checkbox translateKey="AVAILABILITY_TYPE" [options]="sharedService.availabilityTypeList" formControlName="availabilityType"></app-multi-checkbox>
            </div>
            <div>
              <app-typography variant="p-sm" class="mb-1">{{ 'LABEL.AVAILABILITY_TIMINGS' | translate }}</app-typography>
              <app-multi-checkbox translateKey="TIMINGS" [options]="sharedService.timingList" formControlName="timings"></app-multi-checkbox>
            </div>
            <div>
              <app-typography variant="p-sm" class="mb-1">{{ 'LABEL.APPOINTMENT_DAYS' | translate }}</app-typography>
              <app-multi-checkbox translateKey="DAYS_OF_WEEK" [options]="sharedService.dayAvailableList" formControlName="daysOfWeek"></app-multi-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between gap-4 mt-[20px] md:mt-[64px]">
        <app-button variant="outlined" [size]="userAgentQuery.selectIsMobile() ? 'md' : 'lg'" (click)="close()">{{ 'LABEL.CANCEL' | translate }}</app-button>
        <app-button variant="solid" [size]="userAgentQuery.selectIsMobile() ? 'md' : 'lg'" class="flex-1 w-full" (click)="close()">{{ 'LABEL.SAVE' | translate }}</app-button>
      </div>
    </div>
  </div>
</div>
</ng-container>
