<div
  class="h-[210px] lg:h-[305px] hover:min-h-[332px] hover:h-auto mt-8 bg-green-light flex flex-col rounded-2xl cursor-pointer hover:bg-darkGreen hover:shadow-md hover:shadow-darkGreen transition-all ease-in-out duration-500 group"
  (mouseenter)="isHovering = true"
  (mouseleave)="isHovering = false">
  <div class="w-full h-[138px] lg:h-44 group-hover:h-auto bg-darkGreen px-2 lg:px-6 py-4 group-hover:pb-2 rounded-t-2xl">
    <div class="flex justify-between items-start">
      <input
        *ngIf="showCheckbox && !item.messageSent"
        type="checkbox"
        class="h-3 w-3 lg:h-[18px] lg:w-[18px]"
        [checked]="item.isSelected"
        (change)="toggleCheckbox()"
      />
      <app-typography *ngIf="item.messageSent" variant="cap-13-dark" [class]="' text-grayscale-200 bg-[#14412e] flex items-center p-0.5 lg:py-0.5 lg:px-1 rounded'">
        <i class="fa-solid fa-check-double mr-1"></i>{{ 'THERAPIST_CARD.SENT' | translate }}
      </app-typography>
      <span *ngIf="!item.messageSent && !showCheckbox"></span>
      <div
        class="-mt-12 relative flex flex-col justify-center items-center group-hover:justify-between group-hover:items-end group-hover:w-auto group-hover:flex-row transition-all ease-in-out duration-500">
        <img
          *ngIf="item.photoURL"
          src="{{ item.photoURL }}"
          alt="Photo of Therapist"
          class="h-[71px] w-16 lg:w-32 lg:h-32 group-hover:w-10 group-hover:h-10 group-hover:lg:h-[72px] group-hover:lg:w-[72px] rounded-lg object-cover transition-all ease-in-out duration-500" />
        <div
          *ngIf="!item.photoURL"
          class="h-[71px] w-16 lg:w-32 lg:h-32 group-hover:w-10 group-hover:h-10 group-hover:lg:h-[72px] group-hover:lg:w-[72px] bg-grayscale-700 rounded-lg transition-all ease-in-out duration-500"></div>

        <p class="w-fit p-1 bg-lime-regular group-hover:bg-transparent group-hover:mt-8 -mt-3.5 text-grayscale-200 font-montserrat text-xs leading-5 font-semibold rounded">
          {{ item.matchPercent | simplePercent }} Match
        </p>
      </div>
      <div (click)="toggleFavorite()">
        <i *ngIf="!item.isFavorite" class="fa-regular fa-star text-base lg:text-xl text-grayscale-200"></i>
        <i *ngIf="item.isFavorite" class="fa-solid fa-star text-base lg:text-xl text-grayscale-200"></i>
      </div>
    </div>
    <div
      class="mt-2 group-hover:mt-6"
      (click)="emitClick()"
      (dblclick)="emitDblClick()">
      <div class="flex justify-between items-center">
        <app-typography
          variant="h5-dark"
          class="text-grayscale-400">
          {{ item.alias }}
        </app-typography>
        <app-typography
          *ngIf="item.promoted"
          variant="cap-12-dark"
          class="text-green-regular flex items-center">
            <i class="fa-solid fa-circle-check mr-1"></i>{{ 'THERAPIST_CARD.PROMOTED' | translate }}
        </app-typography>
      </div>

      <div class="flex items-center">
        <ng-container *ngIf="isSupervisor">
          <a (click)="openEmail()"
            ><app-typography variant="p-sm" class="text-grayscale-400">{{ 'EMAIL' }}</app-typography></a
          >
        </ng-container>
        <ng-container *ngIf="!isSupervisor">
          <app-typography variant="p-sm" class="text-grayscale-400">{{ attributes.genders | translateArray:'ENUMS.GENDER' | async }}</app-typography>
          <app-typography variant="p-sm" class="text-grayscale-400 mx-1 -mt-1">.</app-typography>
          <app-typography variant="p-sm" class="text-grayscale-400">{{ 'ENUMS.DESIGNATION.' + attributes.designation | translate }}</app-typography>
        </ng-container>
      </div>
    </div>
    <div *ngIf="isHovering" class="h-[1px] w-full bg-green-light my-2 opacity-[16%]"></div>
  </div>

  <div
    class="w-full h-auto py-4 px-2 lg:px-6 group-hover:pt-0 rounded-b-2xl flex flex-col justify-evenly"
    (click)="emitClick()"
    (dblclick)="emitDblClick()">
    <div class="flex justify-between items-center group-hover:mb-2">
      <app-typography variant="p-sm" class="group-hover:text-grayscale-200">{{ 'THERAPIST_CARD.PRICE_PER_SESSION' | translate }}</app-typography>
      <app-typography variant="p-sm-dark" class="group-hover:text-grayscale-200">${{ 0 }}</app-typography>
    </div>
    <app-typography *ngIf="item.matchedFilters" variant="p-sm" class="group-hover:text-grayscale-200">{{ 'THERAPIST_CARD.MATCHED_FILTERS' | translate }}</app-typography>
    <div class="h-12 overflow-hidden flex flex-wrap gap-1 group-hover:mt-2 group-hover:h-auto group-hover:overflow-visible">
      <span
        *ngFor="let filter of item.matchedFilters"
        class="w-auto h-auto rounded border px-1 py-0.5 bg-[#CCE2CC] group-hover:bg-[#07452B] group-hover:border-[#125639] text-xxxs font-semibold text-green-regular group-hover:text-grayscale-200 font-montserrat leading-4"
        >{{ filter }}</span
      >
    </div>
    <div *ngIf="isHovering" class="h-[1px] w-full bg-green-light opacity-[16%] my-4"></div>
    <app-typography
      *ngIf="isHovering"
      variant="p-dark"
      class="text-grayscale-200 mt-0.5 text-center">
        {{ 'LABEL.LEARN_MORE' | translate }}
      </app-typography>
  </div>
</div>
