import { Component, OnInit, inject } from '@angular/core';
import { BaseFilterFormComponent } from '../base-filter-form';
import { SharedService } from 'src/app/core/services/shared.service';
import { UserAgentQuery } from 'src/app/state/user-agent';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent extends BaseFilterFormComponent implements OnInit {
  sharedService = inject(SharedService);
  userAgentQuery = inject(UserAgentQuery);

  ui$ = this.filterQuery.selectUi();

  ngOnInit(): void {
    this.setupForm();
  }

  close(): void {
    this.filterService.setUi({ panelOpen: false });
  }
}
