import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { TherapistSearchComponent } from './therapist-search.component';
import { TherapistFilterComponent } from './components/therapist-filter/therapist-filter.component';
import { ResultsCountComponent } from './components/results-count/results-count.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { FilterChipsComponent } from './components/filter-chips/filter-chips.component';
import { ResultCardComponent } from './components/result-card/result-card.component';
import { ResultListComponent } from './components/result-list/result-list.component';

@NgModule({
  imports: [SharedModule],
  declarations: [TherapistSearchComponent, TherapistFilterComponent, ResultsCountComponent, FilterBarComponent, FilterPanelComponent, FilterChipsComponent, ResultCardComponent, ResultListComponent],
  exports: [TherapistSearchComponent],
})
export class TherapistSearchModule {}
