import { Component, inject } from '@angular/core';
import { LocalizedRouterService } from '../core/services/localized-router.service';
import { Result } from '../state/search';

@Component({
  selector: 'app-experimentation',
  templateUrl: './experimentation.component.html',
  styleUrls: ['./experimentation.component.scss'],
})
export class ExperimentationComponent {
  private readonly localizedRouter = inject(LocalizedRouterService);

  selected: string[] = [];
  favorited: string[] = [];

  onClick(data: Result): void {
    this.localizedRouter.navigate(['experimentation', 'profile', data.objectID]);
  }
}
