import { Inject, Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { coerceArray } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap } from 'rxjs/operators';
import { PAGE_TITLE } from '../di/page-title.token';

export const TITLE_SEPARATOR = ' | ';

@Injectable({ providedIn: 'root' })
export class TitleService {
  private readonly ngTitle = inject(Title);
  private readonly routerQuery = inject(RouterQuery);
  private readonly translate = inject(TranslateService);
  pageTitle = inject<string>(PAGE_TITLE);

  private titleChanges$ = this.routerQuery.selectData<string | string[]>('title').pipe(
    map(items => coerceArray(items || '')),
    switchMap(items => this.translate.stream(items)),
    map<object, string[]>(items => Object.values(items)),
    map(items => this.formatTitle(items)),
  );

  constructor() {
    this.trackTitle();
  }

  private formatTitle(elements: string[]): string {
    elements.push(this.pageTitle);
    return elements.filter(e => !!e).join(TITLE_SEPARATOR);
  }

  private trackTitle(): void {
    this.titleChanges$.subscribe(title => this.ngTitle.setTitle(title));
  }
}
