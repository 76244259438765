<app-typography variant="p-sm-dark" class="!text-[12px] mb-2">{{ title | translate }}</app-typography>
<ol
  class="list-inside"
  [class.list-decimal]="showNumbers">
  <ng-container *ngFor="let text of items; let i = index">
    <li *ngIf="i < showMax">
      <app-typography
        variant="p-sm"
        class="!text-[12px] inline">
          {{ prefix + '.' + text | translate }}
      </app-typography>
    </li>
  </ng-container>
</ol>
<app-typography
  variant="p-sm-dark"
  class="cursor-pointer text-[13px] text-green-regular"
  *ngIf="showViewMoreLink"
  (click)="showMore()">
    {{ 'LABEL.VIEW_MORE' | translate }}
</app-typography>
