import { Injectable } from '@angular/core';
import {
  AgeRange,
  AvailabilityType,
  CanadaProvince,
  ClientDemographic,
  CredentialType,
  DaysOfWeek,
  DegreeAttendance,
  Designation,
  Distance,
  Ethnicity,
  Gender,
  InsuranceBilling,
  Language,
  LicenseIssuer,
  PaymentMethods,
  Religion,
  SexualOrientation,
  Specialities,
  Timings,
  TypeofAttitudeToPatient,
  TypesOfTherapy,
} from '@therappy-inc/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SharedService {
  showFilterPopup$ = new BehaviorSubject<boolean>(false);
  isTherapistSignup = false;

  languageList = Object.values(Language);
  ethnicityList = Object.values(Ethnicity);
  genderList = Object.values(Gender);
  ageRangeList = Object.values(AgeRange);
  licenseIssuerList = Object.values(LicenseIssuer);
  religionList = Object.values(Religion);
  sexualOrientationList = Object.values(SexualOrientation);
  specialityList = Object.values(Specialities);
  clientDemographicList = Object.values(ClientDemographic);
  insuranceBillingList = Object.values(InsuranceBilling);
  typeofAttitudeToPatientList = Object.values(TypeofAttitudeToPatient);
  availabilityTypeList = Object.values(AvailabilityType);
  timingList = Object.values(Timings);
  credentialTypeList = Object.values(CredentialType);
  degreeAttendanceList = Object.values(DegreeAttendance);
  typeOfTherapyList = Object.values(TypesOfTherapy);
  designationList = Object.values(Designation);
  paymentMethodList = Object.values(PaymentMethods);
  distanceList = Object.values(Distance);
  dayAvailableList = Object.values(DaysOfWeek);
  provinceList = Object.values(CanadaProvince);

  getEnumTranslationKey(type: string, text: string): string {
    return `ENUMS.${type}.${text}`;
  }
}
