import { NgModule, PLATFORM_ID, inject } from '@angular/core';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { UserAgentService } from './user-agent';
import { FlowEventsService } from './router/flow-events.service';

@NgModule({
  imports: [environment.production ? [] : AkitaNgDevtools.forRoot(), AkitaNgRouterStoreModule],
})
export class StateModule {
  
  readonly plaformId = inject(PLATFORM_ID);
  private readonly userAgentService = inject(UserAgentService);
  private readonly flowEvents = inject(FlowEventsService);

  constructor() {
    if (isPlatformBrowser(this.plaformId)) {
      this.startListeners();
      this.eagerlyGet();
    }
  }

  startListeners(): void {
    this.flowEvents.listen();
  }

  eagerlyGet(): void {
    //
  }
}
