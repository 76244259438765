import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initial',
  pure: false,
})
export class InitialPipe implements PipeTransform {
  transform(text: string): string {
    return text
      .split(' ')
      .map(n => n[0])
      .join('');
  }
}
