import { Injectable, inject } from '@angular/core';
import { LocalizedRouterService } from 'src/app/core/services/localized-router.service';
import { AuthQuery } from '../auth';

@Injectable({ providedIn: 'root' })
export class FlowRoutingService {

  private readonly localizedRouter = inject(LocalizedRouterService);
  private readonly authQuery = inject(AuthQuery);

  onSignIn(): void {

  }

  onSignOut(): void {

  }

  onSignUp(): void {

  }

  onPatientStartupComplete(): void {
    
  }
}
