import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, HostBinding, Output, EventEmitter, Input, ElementRef, Renderer2, Inject, OnInit, OnDestroy } from '@angular/core';
import { OrNil } from '../types/utility.types';

@Directive({
  selector: '[appFileDragDropUpload]',
})
export class FileDragNDropNUploadDirective implements OnInit, OnDestroy {
  private inputField!: HTMLInputElement;

  /** Whether to accept multiple files */
  @Input() multiple = false;
  /** Restrict the files accepted */
  @Input() accept = '';
  /** Whether to enable or disable upload */
  @Input() enableUpload = true;
  /** Emits whenever user completes file selection */
  @Output() private changes: EventEmitter<File[]> = new EventEmitter();
  //@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();

  @HostBinding('style.cursor') private cursor = 'pointer';
  @HostBinding('style.background') private background = '#FEFEFE';
  @HostBinding('style.border') private borderStyle = '2px dashed';
  @HostBinding('style.border-color') private borderColor = '#D1D1D1';
  @HostBinding('style.border-radius') private borderRadius = '8px';
  @HostListener('mouseover', ['$event']) public onMouseOver(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f3f4f6';
    this.borderColor = '#d1d5db';
    this.borderStyle = '2px solid';
  }
  @HostListener('mouseleave', ['$event']) public onMouseLeave(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FEFEFE';
    this.borderColor = '#D1D1D1';
    this.borderStyle = '2px dashed';
  }
  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f3f4f6';
    this.borderColor = '#d1d5db';
    this.borderStyle = '2px solid';
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FEFEFE';
    this.borderColor = '#D1D1D1';
    this.borderStyle = '2px dashed';
  }
  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FEFEFE';
    this.borderColor = '#D1D1D1';
    this.borderStyle = '2px dashed';
    const files = evt.dataTransfer?.files;
    this.emitChanges(files);
  }
  @HostListener('click', ['$event']) public handleClick(e: Event): void {
    if (!this.enableUpload) {
      return;
    }

    this.inputField.value = '';
    this.inputField.click();
    e.stopPropagation();
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.createInputElement();
  }

  ngOnDestroy(): void {
    this.inputField.remove();
  }

  private createInputElement(): void {
    this.inputField = this.document.createElement('input');
    this.inputField.setAttribute('type', 'file');
    this.inputField.toggleAttribute('multiple', this.multiple || false);
    this.inputField.setAttribute('style', 'display:none');
    this.inputField.setAttribute('accept', this.accept);
    this.inputField.addEventListener('change', () => this.emitChanges(this.inputField.files));
    this.renderer.appendChild(this.elementRef.nativeElement, this.inputField);
  }

  private emitChanges(files: OrNil<FileList>): void {
    if (!files) {
      return;
    }
    const data: File[] = Array.from(files);
    this.changes.emit(data);
  }
}
